import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { classHelper } from "../../utils/stringUtils";
import {
	APP_NO_LBL,
	APP_YES_LBL,
	DIALOG_CONFIRM_BODY,
	DIALOG_CONFIRM_TITLE,
} from "../../utils/variables";
import "./Confirm.scss";

const ConfirmBox = ({
	isBlue = false,
	isHideSecondaryButton = false,
	...props
}) => {
	const ENV_NAME = props.selectedEnvironment || "bhs";

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size={props.size}
			dialogClassName={`confirm-box modal-dialog-centered ${
				props.size ? "lg-w80" : ""
			}`}
		>
			<Modal.Body>
				<div className={classHelper("row", props.className)}>
					<div className="col-12 text-center">
						<div className="delete-title">
							{props.headTitle || DIALOG_CONFIRM_TITLE[ENV_NAME]}
						</div>
						{props.body || (
							<div className="delete-subtitle">
								{DIALOG_CONFIRM_BODY[ENV_NAME]}
							</div>
						)}
					</div>
					<div className="col-12 button-wrapper">
						{!isHideSecondaryButton && (
							<button
								className={
									props.isPrimary
										? "btn btn-primary font-weight-semibold px-5 py-3"
										: "btn noDeleteMeeting"
								}
								type="button"
								onClick={() =>
									typeof props.onHide === "function" && props.onHide()
								}
							>
								{props.labelNo || APP_NO_LBL[ENV_NAME]}
							</button>
						)}
						{!props.isHidePrimaryButton && (
							<button
								className="btn yesDeleteMeeting"
								style={{ backgroundColor: isBlue ? "#4F92EA" : "" }}
								disabled={props.isDisabled}
								type="button"
								onClick={() =>
									typeof props.onAction === "function" && props.onAction(true)
								}
							>
								{props.labelYes || APP_YES_LBL[ENV_NAME]}
							</button>
						)}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default connect((state) => ({
	selectedEnvironment: state.auth?.selectedEnvironment,
}))(ConfirmBox);
