import React, { useState, useRef, useContext } from "react";
import cookies from "react-cookies";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
// import ReactGa from "react-ga";

import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions, homeUrl } from "../../../shared/utils/apiEndPoints";
import * as Variable from "../../../shared/utils/variables";
import { HASH_ENUM, SchoolContext } from "../MainPageComponent";
import { useEffect } from "react";
import isValidEmail from "../../../shared/utils/isValidEmail";
import { isValidUsername } from "../../../shared/utils/stringUtils";
import GreenCheck from "../../../assets/svg/green-check.svg";
import RedCross from "../../../assets/svg/red-cross.svg";
import Image from "../../../shared/component/UI/Image";
import useDebounce from "../../../shared/hooks/debounce";

const pwdHasSymbol = (pwd) => {
	// At least one number or symbol
	const regex = /^.*(?=.*[\d\W]).*$/;
	return regex.test(pwd);
};

const SignUpComponent = ({ ENV_NAME, ...props }) => {
	const location = useLocation();
	const [form, setForm] = useState({
		first_name: "",
		last_name: "",
		username: "",
		email: "",
		password: "",
		confirm_password: "",
	});
	const [disableButton, setDisableButton] = useState(false);
	const [isShowPassword, setIsShowPassword] = useState(true);
	const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");
	const [dataSent, setDataSent] = useState(false);
	const [isUsernameTaken, setIsUsernameTaken] = useState(false);
	const [isLoadingUname, setIsLoadingUname] = useState(false);
	const loginRef = useRef();
	const { schoolData, redirectHandler } = useContext(SchoolContext);
	const debounceUsername = useDebounce(form?.username, 750);

	// Debounce - Setting the temp value to the username,
	// and it'll call API to check the availability
	useEffect(() => {
		const checkUsernameAvailability = async (username) => {
			setIsLoadingUname(true);
			const res = await makeRequest({
				...generateRequestOptions("checkUsernameAvailability"),
				body: { username },
				json: true,
			});
			if (res?.code === 200) {
				setIsUsernameTaken(res.data);
			} else {
				setErrorMessage(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME]);
				setTimeout(() => setErrorMessage(null), 5000);
			}
			setIsLoadingUname(false);
		};

		if (form?.username && form?.username?.length >= 3) {
			checkUsernameAvailability(form?.username);
		}
		if (form?.username === "" && isUsernameTaken) {
			setIsUsernameTaken(null);
		}
	}, [debounceUsername]); // eslint-disable-line

	useEffect(() => {
		loginRef && loginRef.current && loginRef.current.focus();
	}, []);

	const inputHandler = (event) => {
		setForm((p) => ({ ...p, [event.target.name]: event.target.value }));
	};

	const signUp = async () => {
		if (!schoolData?.id) {
			setErrorMessage(Variable.CANT_GET_SCHOOL_REFRESH[ENV_NAME]);
			setTimeout(() => setErrorMessage(null), 5000);
			return;
		}
		if (
			Object.values(form)?.filter((i) => !i)?.length > 0 ||
			form.password?.length < 8 ||
			!pwdHasSymbol(form.password) ||
			form.password !== form.confirm_password ||
			!isValidEmail(form.email) ||
			!isValidUsername(form.username)
		) {
			setDisableButton(false);
			if (form.password && (form.password?.length < 8 || !pwdHasSymbol(form.password))) {
				setErrorMessage(Variable.PASSWORD_FOLLOW_PATTERN[ENV_NAME]);
			} else if (form.confirm_password && form.password !== form.confirm_password) {
				setErrorMessage(Variable.PASSWORD_NOT_MATCH[ENV_NAME]);
			} else {
				setErrorMessage(Variable.FILL_USER_DETAILS[ENV_NAME]);
			}
			setTimeout(() => setErrorMessage(null), 5000);
		} else {
			const { confirm_password, ...formData } = form;
			let homeUrlTemp = homeUrl === "localhost" ? "portalsiswa.id" : homeUrl;
			const data = {
				...formData,
				school: schoolData?.id,
				url_initials: "https://ppdb." + homeUrlTemp + location?.pathname + "/",
			};
			setDisableButton(true);
			// console.log(data);
			let res = await makeRequest({
				...generateRequestOptions("signUpApplicant"),
				headers: {
					Accept: "application/json",
					"content-type": "application/json",
					"Accept-Language": `${cookies.load("lang") === "eng" ? "en-us" : "id"}`,
				},
				body: data,
				json: true,
			});

			if (res?.code === 200) {
				setDataSent(true);
			} else {
				setErrorMessage(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME]);
				setDisableButton(false);
				setTimeout(() => setErrorMessage(null), 5000);
			}
			setDisableButton(false);
		}
	};

	const goToLogin = () => {
		redirectHandler(HASH_ENUM.LOGIN);
	};

	return (
		<div className="rightSideBox d-flex justify-content-center align-items-center positionRelative">
			{dataSent ? (
				<div className="formContainer formFlex">
					<Image src={GreenCheck} />
					<div className="text-label-dark-gray font-weight-medium label-message text-center">
						{Variable.ACCOUNT_CREATED[ENV_NAME]}
					</div>
					<button
						onClick={(e) => {
							e.preventDefault();
							goToLogin();
						}}
						className="btn btn-primary btn-primary-login"
					>
						{Variable.BACK_TO_LOGIN[ENV_NAME]}
					</button>
				</div>
			) : (
				<>
					<form
						className="formContainer noMar"
						onSubmit={(e) => {
							e.preventDefault();
							signUp();
						}}
					>
						<div className="mb-4 d-flex align-content-center">
							<h1 className="heading mb-0">{Variable.SIGNUP[ENV_NAME]}</h1>
						</div>
						<div className="form-group form-label-group in-border">
							<input
								id="first_name"
								type="text"
								className={`form-control ${errorMessage ? "border-danger" : ""}`}
								name="first_name"
								value={form?.first_name || ""}
								onChange={inputHandler}
								placeholder={Variable.FIRST_NAME[ENV_NAME]}
								ref={loginRef}
								required
							/>
							<label htmlFor="first_name" className="font-weight-medium">
								{Variable.FIRST_NAME[ENV_NAME]}
							</label>
						</div>
						<div className="form-group form-label-group in-border">
							<input
								id="last_name"
								type="text"
								className={`form-control ${errorMessage ? "border-danger" : ""}`}
								name="last_name"
								value={form?.last_name || ""}
								onChange={inputHandler}
								placeholder={Variable.LAST_NAME[ENV_NAME]}
								required
							/>
							<label htmlFor="last_name" className="font-weight-medium">
								{Variable.LAST_NAME[ENV_NAME]}
							</label>
						</div>
						<div className="form-group form-label-group in-border">
							<div className="positionRelative">
								<input
									id="username"
									type="text"
									className={`form-control ${
										errorMessage ||
										(form.username && !isValidUsername(form.username)) ||
										isUsernameTaken?.is_exist
											? "border-danger"
											: typeof isUsernameTaken?.is_exist === "boolean" &&
											  !isUsernameTaken?.is_exist
											? "border-green"
											: ""
									}`}
									name="username"
									value={form?.username || ""}
									onChange={inputHandler}
									placeholder={Variable.USERNAME[ENV_NAME]}
									required
								/>
								<label htmlFor="username" className="font-weight-medium">
									{Variable.USERNAME[ENV_NAME]}
								</label>
								{((form?.username && form?.username?.length >= 3) || isLoadingUname) && (
									<div className="uname-avail">
										{isLoadingUname || typeof isUsernameTaken?.is_exist !== "boolean" ? (
											<div className="spinner-border" role="status">
												<span className="sr-only"></span>
											</div>
										) : isUsernameTaken?.is_exist ? (
											<Image className="uname-avail-img" src={RedCross} alt="red cross" />
										) : (
											<Image className="uname-avail-img" src={GreenCheck} alt="red cross" />
										)}
									</div>
								)}
							</div>
							{form.username && !isValidUsername(form.username) && (
								<div className="text-notice red">{Variable.INVALID_USERNAME_FORMAT[ENV_NAME]}</div>
							)}
							{form.username && isUsernameTaken?.is_exist && (
								<div className="text-notice red">{Variable.USERNAME_IS_TAKEN[ENV_NAME]}</div>
							)}
						</div>
						<div className="form-group form-label-group in-border">
							<div className="positionRelative">
								<input
									id="email"
									type="email"
									className={`form-control ${
										errorMessage || (form.email && !isValidEmail(form.email)) ? "border-danger" : ""
									}`}
									name="email"
									value={form?.email || ""}
									onChange={inputHandler}
									placeholder={Variable.EMAIL_ID[ENV_NAME]}
									required
								/>
								<label htmlFor="email" className="font-weight-medium">
									{Variable.EMAIL_ID[ENV_NAME]}
								</label>
							</div>
							{form.email && !isValidEmail(form.email) && (
								<div className="text-notice red">{Variable.INVALID_EMAIL_FORMAT[ENV_NAME]}</div>
							)}
						</div>
						<div className="form-group form-label-group in-border smallMargin">
							<div className="positionRelative">
								<input
									id="password"
									type={isShowPassword ? "password" : "text"}
									className={`form-control ${
										errorMessage ||
										(form?.password && form?.password?.length < 8 && !pwdHasSymbol(form?.password))
											? "border-danger"
											: ""
									}`}
									name="password"
									value={form?.password || ""}
									onChange={inputHandler}
									placeholder={Variable.PASSWORD[ENV_NAME]}
									required
								/>
								<label htmlFor="password" className="font-weight-medium">
									{Variable.PASSWORD[ENV_NAME]}
								</label>
								<div
									className={`pwd-eye cursorPointer ${!isShowPassword ? "active" : ""}`}
									onClick={() => {
										setIsShowPassword((p) => !p);
									}}
								>
									<i className={`fa fa-eye${isShowPassword ? "-slash" : ""}`}></i>
								</div>
							</div>
							{form?.password && (
								<>
									<div className={`text-notice ${form?.password?.length >= 8 ? "green" : "red"}`}>
										{`${form?.password?.length >= 8 ? "✓" : "×"} ${
											Variable.PASSWORD_VAL_1[ENV_NAME]
										}`}
									</div>
									<div className={`text-notice ${pwdHasSymbol(form?.password) ? "green" : "red"}`}>
										{`${pwdHasSymbol(form?.password) ? "✓" : "×"} ${
											Variable.PASSWORD_VAL_2[ENV_NAME]
										}`}
									</div>
								</>
							)}
						</div>
						<div className="form-group form-label-group in-border smallMargin">
							<div className="positionRelative">
								<input
									id="confirm_password"
									type={isShowConfirmPassword ? "password" : "text"}
									className={`form-control ${
										errorMessage ||
										(form?.confirm_password && form?.confirm_password !== form?.password)
											? "border-danger"
											: ""
									}`}
									name="confirm_password"
									value={form?.confirm_password || ""}
									onChange={inputHandler}
									placeholder={Variable.CONFIRM_PASSWORD[ENV_NAME]}
									required
								/>
								<label htmlFor="confirm_password" className="font-weight-medium">
									{Variable.CONFIRM_PASSWORD[ENV_NAME]}
								</label>
								<div
									className={`pwd-eye cursorPointer ${!isShowConfirmPassword ? "active" : ""}`}
									onClick={() => {
										setIsShowConfirmPassword((p) => !p);
									}}
								>
									<i className={`fa fa-eye${isShowConfirmPassword ? "-slash" : ""}`}></i>
								</div>
							</div>
							{form?.confirm_password && form?.confirm_password !== form?.password && (
								<div className="text-notice red">{Variable.PASSWORD_NOT_MATCH[ENV_NAME]}</div>
							)}
						</div>
						<div className="col text-right noPad mb-4 mt-1"></div>
						<button type="submit" className="btn btn-block btn-primary-login" disabled={disableButton}>
							{Variable.SIGNUP[ENV_NAME]}
						</button>
						<div className="col text-center nopad  mt-4">
							<div className="font-weight-medium">
								{Variable.DO_YOU_HAVE_AN_ACCOUNT[ENV_NAME]}
								<span
									className="text-label-blue font-weight-bold cursorPointer ml-2"
									onClick={goToLogin}
								>
									{Variable.LOGIN[ENV_NAME]}
								</span>
							</div>
						</div>
					</form>
					<div className={`errorMsgBox ${errorMessage ? "show" : ""}`}>{errorMessage}</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment,
});
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(SignUpComponent));
