import React, { useContext, useEffect, useRef, useState } from "react";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import * as Variable from "../../../shared/utils/variables";
import GreenCheck from "../../../assets/svg/green-check.svg";
import { HASH_ENUM, SchoolContext } from "../MainPageComponent";

const pwdHasSymbol = (pwd) => {
	// At least one number or symbol
	const regex = /^.*(?=.*[\d\W]).*$/;
	return regex.test(pwd);
};

const ResetPasswordComponent = ({ ENV_NAME, ...props }) => {
	const location = useLocation();
	const [form, setForm] = useState({
		password: "",
		confirm_password: "",
	});
	const [userData, setUserData] = useState({
		user_id: "",
		timestamp: "",
		signature: "",
	});
	const [disableButton, setDisableButton] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isShowPassword, setIsShowPassword] = useState(true);
	const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const { schoolData, redirectHandler } = useContext(SchoolContext);
	const fpRef = useRef();

	useEffect(() => {
		fpRef && fpRef.current && fpRef.current.focus();
	}, []);

	useEffect(() => {
		if (location?.hash?.includes(HASH_ENUM.VERIFYRESETPASSWORD)) {
			let ids = location?.hash?.split(HASH_ENUM.VERIFYRESETPASSWORD + "?")[1];
			let idsSplit = ids?.split("&");
			let values = {};
			idsSplit.forEach((value, key) => {
				let a = value.split("=");
				values = {
					...values,
					[a[0]]: a[1],
				};
			});
			setUserData(values);
		}
	}, [location]);

	const inputHandler = (event) => {
		setForm((p) => ({ ...p, [event.target.name]: event.target.value }));
	};

	const resetPassword = async () => {
		if (!schoolData?.id) {
			setErrorMessage(Variable.CANT_GET_SCHOOL_REFRESH[ENV_NAME]);
			setTimeout(() => setErrorMessage(null), 5000);
			return;
		}
		if (Object.values(userData)?.filter((i) => !i)?.length > 0) {
			setErrorMessage(Variable.CANT_GET_DATA_REFRESH[ENV_NAME]);
			setTimeout(() => setErrorMessage(null), 5000);
			return;
		}

		setErrorMessage(null);

		let data = {
			...form,
			...userData,
		};

		if (form.password?.length < 8 || !pwdHasSymbol(form.password) || form.password !== form.confirm_password) {
			setDisableButton(false);
			setErrorMessage(Variable.FIELD_ERROR[ENV_NAME]);
			setTimeout(() => setErrorMessage(null), 5000);
		} else {
			setDisableButton(true);
			setIsSuccess(true);
			setTimeout(() => setSuccessMessage(null), 5000);
			let res = await makeRequest({
				...generateRequestOptions("resetPassword"),
				body: data,
				json: true,
			});
			if (res.code === 200) {
				setIsSuccess(true);
				setTimeout(() => setSuccessMessage(null), 5000);
			} else {
				if (res.message.startsWith("Request")) {
					setErrorMessage(Variable.REQUEST_ALREADY_SENT[ENV_NAME]);
				} else {
					setErrorMessage(res.message);
				}
				setDisableButton(false);
				setTimeout(() => setErrorMessage(null), 5000);
			}
			setDisableButton(false);
		}
	};

	const goToLogin = () => {
		redirectHandler(HASH_ENUM.LOGIN);
	};

	return (
		<div className="rightSideBox d-flex justify-content-center align-items-center positionRelative">
			{!isSuccess && (
				<form
					className="noMar formContainer"
					onSubmit={(e) => {
						e.preventDefault();
						resetPassword();
					}}
				>
					<div className="form-group form-label-group in-border smallMargin">
						<div className="positionRelative">
							<input
								id="password"
								type={isShowPassword ? "password" : "text"}
								className={`form-control ${
									errorMessage ||
									(form?.password && form?.password?.length < 8 && !pwdHasSymbol(form?.password))
										? "border-danger"
										: ""
								}`}
								name="password"
								value={form?.password || ""}
								onChange={inputHandler}
								placeholder={Variable.PASSWORD[ENV_NAME]}
								ref={fpRef}
								required
							/>
							<label htmlFor="password" className="font-weight-medium">
								{Variable.PASSWORD[ENV_NAME]}
							</label>
							<div
								className={`pwd-eye cursorPointer ${!isShowPassword ? "active" : ""}`}
								onClick={() => {
									setIsShowPassword((p) => !p);
								}}
							>
								<i className={`fa fa-eye${isShowPassword ? "-slash" : ""}`}></i>
							</div>
						</div>
						{form?.password && (
							<>
								<div className={`text-notice ${form?.password?.length >= 8 ? "green" : "red"}`}>
									{`${form?.password?.length >= 8 ? "✓" : "×"} ${Variable.PASSWORD_VAL_1[ENV_NAME]}`}
								</div>
								<div className={`text-notice ${pwdHasSymbol(form?.password) ? "green" : "red"}`}>
									{`${pwdHasSymbol(form?.password) ? "✓" : "×"} ${Variable.PASSWORD_VAL_2[ENV_NAME]}`}
								</div>
							</>
						)}
					</div>
					<div className="form-group form-label-group in-border smallMargin">
						<div className="positionRelative">
							<input
								id="confirm_password"
								type={isShowConfirmPassword ? "password" : "text"}
								className={`form-control ${
									errorMessage ||
									(form?.confirm_password && form?.confirm_password !== form?.password)
										? "border-danger"
										: ""
								}`}
								name="confirm_password"
								value={form?.confirm_password || ""}
								onChange={inputHandler}
								placeholder={Variable.CONFIRM_PASSWORD[ENV_NAME]}
								required
							/>
							<label htmlFor="confirm_password" className="font-weight-medium">
								{Variable.CONFIRM_PASSWORD[ENV_NAME]}
							</label>
							<div
								className={`pwd-eye cursorPointer ${!isShowConfirmPassword ? "active" : ""}`}
								onClick={() => {
									setIsShowConfirmPassword((p) => !p);
								}}
							>
								<i className={`fa fa-eye${isShowConfirmPassword ? "-slash" : ""}`}></i>
							</div>
						</div>
						{form?.confirm_password && form?.confirm_password !== form?.password && (
							<div className="text-notice red">{Variable.PASSWORD_NOT_MATCH[ENV_NAME]}</div>
						)}
					</div>
					<div className="col-12 noPad">
						<button type="submit" disabled={disableButton} className="btn btn-primary btn-primary-login">
							{Variable.SEND[ENV_NAME]}
						</button>
					</div>
					<div className="col-12 noPad centerTextToOtherPage text-center mt-3">
						<label>
							{Variable.OR_LABEL[ENV_NAME]} &nbsp;
							<span className="cursorPointer text-label-blue font-weight-semibold" onClick={goToLogin}>
								{Variable.SIGNIN[ENV_NAME]}
							</span>
						</label>
					</div>
				</form>
			)}

			{isSuccess && (
				<div className="noMar formContainer">
					<div className="form-group d-flex flex-column align-items-center text-center">
						<img className="mb-3" src={GreenCheck} alt="email sent icon" />
						<span className="text-label-dark-gray font-weight-medium label-message">
							{Variable.PASSWORD_RESET_SUCCESS_L1[ENV_NAME]}
						</span>
						<span className="text-label-dark-gray font-weight-medium label-message">
							{Variable.PASSWORD_RESET_SUCCESS_L2[ENV_NAME]}
						</span>
					</div>
					<div className="col-12 noPad">
						<button
							type="submit"
							onClick={() => goToLogin()}
							disabled={disableButton}
							className="btn btn-primary btn-primary-login"
						>
							{Variable.PROCEED_TO_LOGIN[ENV_NAME]}
						</button>
					</div>
				</div>
			)}

			<div className={`errorMsgBox ${errorMessage ? "show" : ""}`}>{errorMessage}</div>

			<div className={`errorMsgBox successBox ${successMessage ? "show" : ""}`}>{successMessage}</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		ENV_NAME: state.auth.selectedEnvironment,
	};
};

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ResetPasswordComponent));
