import React, { useContext, useEffect, useRef, useState } from "react";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions, homeUrl } from "../../../shared/utils/apiEndPoints";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import * as Variable from "../../../shared/utils/variables";
import forgotPasswordIcon from "../../../assets/svg/forgot-password.svg";
import forgotPasswordSentIcon from "../../../assets/svg/forgot-password-sent.svg";
import Countdown from "./Countdown";
import { HASH_ENUM, SchoolContext } from "../MainPageComponent";

const ForgotPasswordComponent = ({ ENV_NAME, ...props }) => {
	const location = useLocation();
	const [email, setEmail] = useState("");
	const [disableButton, setDisableButton] = useState(false);
	const [emailAlreadySent, setEmailAlreadySent] = useState(false);
	const [timerEnabled, setTimerEnabled] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const { schoolData, redirectHandler } = useContext(SchoolContext);
	const fpRef = useRef();

	useEffect(() => {
		fpRef && fpRef.current && fpRef.current.focus();
	}, []);

	const disableTimer = () => setTimerEnabled(false);

	const sendEmail = async () => {
		if (!schoolData?.id) {
			setErrorMessage(Variable.CANT_GET_SCHOOL_REFRESH[ENV_NAME]);
			setTimeout(() => setErrorMessage(null), 5000);
			return;
		}

		setErrorMessage(null);
		setTimerEnabled(true);

		let homeUrlTemp = homeUrl === "localhost" ? "portalsiswa.id" : homeUrl;

		let data = {
			email,
			school_id: schoolData?.id,
			url_initials: "https://ppdb." + homeUrlTemp + location?.pathname + "/",
		};

		if (!data.email) {
			setDisableButton(false);
			setErrorMessage(Variable.FILL_USER_DETAILS[ENV_NAME]);
			setTimeout(() => setErrorMessage(null), 5000);
		} else {
			setDisableButton(true);
			setSuccessMessage(Variable.FORGOT_PASSWORD_MAIL_SENT_SHORT[ENV_NAME]);
			setEmailAlreadySent(true);
			setTimeout(() => setSuccessMessage(null), 5000);
			let res = await makeRequest({
				...generateRequestOptions("sendResetLink"),
				body: data,
				json: true,
			});
			if (res.code === 200) {
				setSuccessMessage(Variable.FORGOT_PASSWORD_MAIL_SENT_SHORT[ENV_NAME]);
				setEmailAlreadySent(true);
				setTimeout(() => setSuccessMessage(null), 5000);
			} else {
				if (res.message.startsWith("Request")) {
					setErrorMessage(Variable.REQUEST_ALREADY_SENT[ENV_NAME]);
				} else {
					setErrorMessage(res.message);
				}
				setDisableButton(false);
				setTimeout(() => setErrorMessage(null), 5000);
			}
			setDisableButton(false);
		}
	};

	const goToLogin = () => {
		redirectHandler(HASH_ENUM.LOGIN);
	};

	return (
		<div className="rightSideBox d-flex justify-content-center align-items-center positionRelative">
			{!emailAlreadySent && (
				<form
					className="noMar formContainer"
					onSubmit={(e) => {
						e.preventDefault();
						sendEmail();
					}}
				>
					<div className="form-group d-flex flex-column align-items-center text-center">
						<img className="mb-3" src={forgotPasswordIcon} alt="forgot password icon" />
						<span className="text-label-dark-gray font-weight-medium label-message">
							{Variable.FORGOT_PASSWORD_MESSAGE[ENV_NAME]}
						</span>
					</div>
					<div className="form-label-group in-border">
						<input
							type="email"
							className={`form-control ${errorMessage ? "border-danger" : ""}`}
							aria-describedby="emailHelp"
							name="email"
							placeholder={Variable.ENTER[ENV_NAME] + " " + Variable.EMAIL[ENV_NAME]}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							ref={fpRef}
						/>
						<label className="font-weight-medium">{Variable.EMAIL[ENV_NAME]}</label>
					</div>
					<div className="col-12 noPad">
						<button type="submit" disabled={disableButton} className="btn btn-primary btn-primary-login">
							{Variable.SEND[ENV_NAME]}
						</button>
					</div>
					<div className="col-12 noPad centerTextToOtherPage text-center mt-3">
						<label>
							{Variable.OR_LABEL[ENV_NAME]} &nbsp;
							<span className="cursorPointer text-label-blue font-weight-semibold" onClick={goToLogin}>
								{Variable.SIGNIN[ENV_NAME]}
							</span>
						</label>
					</div>
				</form>
			)}

			{emailAlreadySent && (
				<div className="noMar formContainer">
					<div className="form-group d-flex flex-column align-items-center text-center">
						<img className="mb-3" src={forgotPasswordSentIcon} alt="email sent icon" />
						<span className="text-label-dark-gray font-weight-medium label-message">
							{Variable.FORGOT_PASSWORD_MAIL_SENT[ENV_NAME]}
						</span>
					</div>
					<div className="col-12 noPad">
						<button
							type="submit"
							onClick={() => goToLogin()}
							disabled={disableButton}
							className="btn btn-primary btn-primary-login"
						>
							{Variable.BACK_TO_LOGIN[ENV_NAME]}
						</button>
					</div>
					<div className="col-12 noPad centerTextToOtherPage text-center mt-3">
						<label>
							{Variable.HAVENT_GOT_EMAIL[ENV_NAME]}{" "}
							{!timerEnabled ? (
								<span
									className="cursorPointer text-label-blue font-weight-semibold"
									onClick={() => sendEmail()}
								>
									{Variable.RESENT[ENV_NAME]}
								</span>
							) : (
								<Countdown disableTimer={disableTimer} />
							)}
						</label>
					</div>
				</div>
			)}

			<div className={`errorMsgBox ${errorMessage ? "show" : ""}`}>{errorMessage}</div>

			<div className={`errorMsgBox successBox ${successMessage ? "show" : ""}`}>{successMessage}</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		ENV_NAME: state.auth.selectedEnvironment,
	};
};

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ForgotPasswordComponent));
