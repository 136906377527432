import React, { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Image from "../../../shared/component/UI/Image";
import * as Variable from "../../../shared/utils/variables";
import { CUSTOM_FIELD_TYPE } from "../RegistrationComponent";
import tooltipIcon from "../../../assets/icon/tooltip.svg";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from "moment";
import { SUBMIT_TIME_FORMAT, TIME_FORMAT } from "./StudentInfoTab";
import defaultFile from "../../../assets/svg/paper.svg";

import "../RegistrationComponent.scss";

// For upload type, check if the URL is an image
function checkIsImage(url) {
	return url?.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

const FormCustomField = ({
	id,
	idx,
	customFieldId,
	ENV_NAME,
	type,
	onTypeChange,
	title,
	onTitleChange,
	mandatory,
	onMandatoryChange,
	instructions,
	onInstructionsChange,
	options,
	onOptionsChange,
	onDelete,
	isError,
	hideType,
	customFieldTitle,
	answer,
	onAnswerChange,
	isUploading,
	...props
}) => {
	const fileRef = useRef(null);
	return (
		<div className={`regist-form-container custom-form-field ${isError ? "has-error" : ""}`}>
			<h5 className="regist-form-header">
				{`${title || Variable.CUSTOM_FIELD[ENV_NAME]}${!mandatory ? ` (${Variable.OPTIONAL[ENV_NAME]})` : ""}`}
			</h5>
			<div className="font-weight-medium text-label-dark-gray-2 mb-3 text-truncate">{instructions || ""}</div>
			<div className="regist-form-row w-100">
				<div className="regist-form-row flex-column w-100">
					<div className="form-group">
						{type === CUSTOM_FIELD_TYPE.FILE && (
							<label>
								{Variable.DOCUMENT_UPLOAD_APPLICANT[ENV_NAME]}
								<OverlayTrigger
									placement="right"
									overlay={
										<Tooltip className="custom-tooltip">
											<span>{Variable.MAX_5MB[ENV_NAME]}</span>
										</Tooltip>
									}
								>
									<Image src={tooltipIcon} className="ml-2 cursorPointer" alt="tooltip-icon" />
								</OverlayTrigger>
							</label>
						)}
						{type === CUSTOM_FIELD_TYPE.MULTIPLECHOICE || type === CUSTOM_FIELD_TYPE.CHECKBOXES ? (
							options?.map((i, idxOpt) => (
								<div className="d-flex align-items-center w-100 mb-3" key={idx + "-" + idxOpt}>
									{type === CUSTOM_FIELD_TYPE.MULTIPLECHOICE ? (
										<div className="da-custom-radio mr-4">
											<input
												className="custom-control-input"
												type="radio"
												name={idx + "-" + idxOpt}
												checked={answer?.choice_based_answer?.some((j) => j === i)}
												onChange={() => {}}
											/>
											<label
												className="cursorPointer custom-control-label"
												onClick={() =>
													onAnswerChange(id, type, {
														isAdd: answer?.choice_based_answer?.[0] !== i,
														value: i,
													})
												}
											></label>
										</div>
									) : (
										<div className="d-flex custom-control custom-checkbox mr-4">
											<input
												type="checkbox"
												className="custom-control-input"
												checked={answer?.choice_based_answer?.some((j) => j === i)}
												onChange={() => {}}
											/>
											<label
												className={`custom-control-label empty-label cursorPointer ${
													isError &&
													mandatory &&
													(!Array.isArray(answer?.choice_based_answer) ||
														answer?.choice_based_answer?.length === 0)
														? " red-border"
														: ""
												}`}
												onClick={() => {
													onAnswerChange(id, type, {
														isAdd: !answer?.choice_based_answer?.some((j) => j === i),
														value: i,
													});
												}}
											/>
										</div>
									)}
									<div
										className={`form-control d-flex align-items-center cursorPointer ${
											isError &&
											mandatory &&
											(!Array.isArray(answer?.choice_based_answer) ||
												answer?.choice_based_answer?.length === 0)
												? " red-border"
												: ""
										}`}
										onClick={(e) => {
											if (type === CUSTOM_FIELD_TYPE.MULTIPLECHOICE) {
												onAnswerChange(id, type, {
													isAdd: answer?.choice_based_answer?.[0] !== i,
													value: i,
												});
												return;
											}
											onAnswerChange(id, type, {
												isAdd: !answer?.choice_based_answer?.some((j) => j === i),
												value: i,
											});
										}}
									>
										{i || ""}
									</div>
								</div>
							))
						) : type === CUSTOM_FIELD_TYPE.FILE ? (
							<div className={`form-group custom-input-upload mt-0 ${isUploading ? "disabled" : ""}`}>
								<label
									className="custom-upload-label"
									htmlFor="exampleFormControlFile1"
									onClick={() => {
										if (!isUploading) {
											fileRef.current && fileRef.current.click();
										}
									}}
								>
									{Variable.UPLOAD[ENV_NAME]}
								</label>
								<label
									className={`form-control-file form-control ${
										isError && mandatory && !answer?.file ? "red-border" : ""
									}`}
									onClick={() => {
										if (!isUploading) {
											fileRef.current && fileRef.current.click();
										}
									}}
								/>
								<input
									ref={fileRef}
									name="file"
									type="file"
									id="file"
									onChange={(e) => onAnswerChange(id, type, e)}
									required
								/>
								{isUploading ? (
									<div className="w-100 d-flex mt-3 align-items-center">
										<div className="spinner-border spinner-border-sm m-2" role="status">
											<span className="sr-only"></span>
										</div>
										<span className="image-preview-label mr-3">{Variable.UPLOADING[ENV_NAME]}</span>
									</div>
								) : answer?.file ? (
									<div className="w-100 d-flex mt-3 align-items-center">
										<Image
											className={`image-preview mr-2 ${
												!checkIsImage(answer?.file) ? "no-styling" : ""
											}`}
											src={checkIsImage(answer?.file) ? answer?.file : defaultFile}
											alt="Image preview"
										/>
										<span className="image-preview-label">{answer?.file || ""}</span>
										<button
											className="btn btn-cross small"
											onClick={() => onAnswerChange(id, type, null)}
										>
											{"\xd7"}
										</button>
									</div>
								) : undefined}
							</div>
						) : type === CUSTOM_FIELD_TYPE.DATE ? (
							<Datetime
								locale={ENV_NAME === "eng" ? "en" : "id"}
								onChange={(e) => {
									if (moment(e).isValid()) {
										onAnswerChange(id, type, moment(e).format(SUBMIT_TIME_FORMAT));
									}
								}}
								value={moment(answer?.date_answer || "")}
								timeFormat={false}
								dateFormat={TIME_FORMAT}
								inputProps={{
									placeholder: Variable.ENTER[ENV_NAME],
									className: `form-control timepickerlike ${
										isError &&
										mandatory &&
										(!answer?.date_answer || !moment(answer?.date_answer).isValid())
											? "red-border"
											: ""
									}`,
									name: "custom-field-" + idx,
								}}
								className="position-relative rdtPickerOpenUpwards"
							/>
						) : (
							<input
								className={`form-control ${
									isError &&
									mandatory &&
									(type === CUSTOM_FIELD_TYPE.NUMBER ? !answer?.number_answer : !answer?.text_answer)
										? " red-border"
										: ""
								}`}
								type={type === CUSTOM_FIELD_TYPE.NUMBER ? "number" : "text"}
								value={
									type === CUSTOM_FIELD_TYPE.NUMBER
										? answer?.number_answer || ""
										: answer?.text_answer || ""
								}
								placeholder={Variable.ENTER[ENV_NAME]}
								onChange={(e) => onAnswerChange(id, type, e.target.value)}
							/>
						)}
					</div>
				</div>
				<div className="form-group d-flex flex-column" />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
		academicYear: state.auth.academicYear,
	};
};
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(FormCustomField));
