import { homeUrl } from "./apiEndPoints";
// const request = require("request");
import axios from "axios";
const cookies = require("react-cookies");
const makeRequest = (options) => {
	return new Promise((resolve, reject) => {
		let url = new URL(options.url);

		options = {
			...options,
			...(options.body && { data: options.body }),
			url: url.href,
			headers: {
				...options.headers,
				"Accept-Language": `${
					cookies.load("lang") === "eng" ? "en-us" : "id"
				}`,
			}
		};
		
		if (
			cookies.load("applicantauthtoken", { domain: homeUrl }) &&
			cookies.load("applicantrefreshtoken", { domain: homeUrl })
		) {
			options = {
				...options,
				headers: {
					...options.headers,
					Authorization: `Bearer ${cookies.load("applicantauthtoken")}`,
					"FE_url": window.location.href,
					"Accept-Language": `${
						cookies.load("lang") === "eng" ? "en-us" : "id"
					}`,
					// refresh: `${cookie.load('applicantrefreshtoken')}`,
				},
			};
		}

		axios(options)
			.then(async (response) => {
				let apiResponse,
					body = response.data;

				try {
					apiResponse = typeof body === "string" ? JSON.parse(body) : body;
				} catch (e) {
					apiResponse = body;
				}

				// console.log(apiResponse);
				if (
					response.status === 401 &&
					cookies.load("applicantauthtoken") &&
					cookies.load("applicantrefreshtoken")
				) {
					await cookies.remove("lang", { path: "/", domain: homeUrl });
					await cookies.remove("applicantauthtoken", { path: "/", domain: homeUrl });
					await cookies.remove("applicantrefreshtoken", {
						path: "/",
						domain: homeUrl,
					});
					await cookies.remove("applicanttokenexpiry", { path: "/", domain: homeUrl });
					setTimeout(() => {
						window.location.reload();
					}, 200);
				} else {
					resolve(apiResponse);
				}
			})
			.catch((err) => {
				let apiResponse;
				if (err) {
					try {
						apiResponse = JSON.parse(err).response.data;
					} catch (e) {
						apiResponse = err?.response?.data;
					}
				}
				resolve(apiResponse);
			});
	});
};

export default makeRequest;
