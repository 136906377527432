import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { classHelper } from "../../utils/stringUtils";
import * as Variable from "../../utils/variables";
import "./ChangeLanguageModal.scss";

const ChangeLanguageModal = (props) => {
	const ENV_NAME = props.selectedEnvironment || "bhs";
	const [selectedLanguage, setSelectedLanguage] = useState(ENV_NAME);

	return (
		<Modal
			size="sm"
			show={props.show}
			onHide={props.onHide}
			dialogClassName="change-language-box modal-dialog-centered"
			backdrop="static"
		>
			<Modal.Body>
				<div className={classHelper("row", props.className)}>
					<h5 className="col-12 font-weight-bold text-label-dark-gray-2 py-2">
						{Variable.LANGUAGE_LBL[ENV_NAME]}
					</h5>
					<div className="col-12 content">
						<div
							className={`select-like ${selectedLanguage === "bhs" ? "checked" : ""}`}
							onClick={() => setSelectedLanguage("bhs")}
						>
							<div>Bahasa Indonesia</div>
							<div className="check-like">
								{selectedLanguage === "bhs" && <i className="fa fa-check"></i>}
							</div>
						</div>
						<div
							className={`select-like ${selectedLanguage === "eng" ? "checked" : ""}`}
							onClick={() => setSelectedLanguage("eng")}
						>
							<div>English</div>
							<div className="check-like">
								{selectedLanguage === "eng" && <i className="fa fa-check"></i>}
							</div>
						</div>
					</div>
					<div className="col-12 button-wrapper">
						<button
							className="btn no-btn"
							type="button"
							onClick={() =>
								typeof props.onAction === "function" && props.onAction(false)
							}
						>
							{Variable.CANCEL_LABEL[ENV_NAME]}
						</button>
						<button
							className="btn yes-btn"
							type="button"
							onClick={() =>
								typeof props.onAction === "function" &&
								props.onAction(selectedLanguage)
							}
						>
							{Variable.SAVE_LABEL[ENV_NAME]}
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default connect((state) => ({
	selectedEnvironment: state.auth?.selectedEnvironment,
}))(ChangeLanguageModal);
