import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Variable from "../../../shared/utils/variables";
import * as RoutePath from "../../../shared/utils/routeLink";
import * as USERROLES from "../../../shared/utils/userroles";
import UnsavedChanges from "../../../shared/component/UnsavedChanges";
import FormCustomField from "./FormCustomField";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import { CUSTOM_FIELD_TYPE, RegistContext } from "../RegistrationComponent";
import { NoticeMessageContext } from "../../../App";

import "../RegistrationComponent.scss";
import Confirm from "../../../shared/component/ConfirmBox/Confirm";
import { isFileSizeLessOrEqual } from "../../../shared/utils/file";
import fetchRequest from "../../../shared/utils/fetch";

const TAB_NAME = "documents_upload";
const STATEMENT_VALID_DEFAULT =
	"Saya menyatakan bahwa saya telah mengisi data di atas dengan jujur dan telah memahami persyaratan pendaftaran di atas, serta akan mematuhi kebijakan dan peraturan sekolah.";
const TAB_ENUM = {
	STUDENT: "#student",
	PARENT: "#parent",
	DOCUMENT: "#document",
};
const CONFIRMTYPE = {
	SUBMIT: "submit",
	DELETEATTACH: "delete-attachment",
};

const UploadTab = ({ ENV_NAME, isOpened, ...props }) => {
	const { showNotice } = useContext(NoticeMessageContext);
	const { admissionId, batchId, applyId, registData, customFieldAnswer, admissionData, getFormData } =
		useContext(RegistContext);
	const [registDataTemp, setRegistDataTemp] = useState(null);
	const [customFields, setCustomFields] = useState(null);
	const [customFieldsAnswerTemp, setCustomFieldsAnswerTemp] = useState(null);
	const [fieldsError, setFieldsError] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isUploading, setIsUploading] = useState({});
	const [showConfirmModal, setShowConfirmModal] = useState({
		show: false,
		data: null,
		type: null,
	});

	// If opened, we want to load the custom fields for this particular tab
	useEffect(() => {
		if (isOpened && admissionId) {
			getCustomFields();
			getFormData();
		}
		if (!isOpened) {
			setIsEditing(false);
		}
	}, [isOpened, admissionId]); // eslint-disable-line

	// If opened and other supporting data is available
	// We want to load the custom fields answer
	useEffect(() => {
		if (isOpened && Array.isArray(customFieldAnswer) && Array.isArray(customFields)) {
			getCustomFieldsAnswer(customFields, customFieldAnswer);
		}
	}, [isOpened, customFieldAnswer, customFields]); // eslint-disable-line

	// For every form changes from parent, we update the data
	useEffect(() => {
		if (registData) {
			setRegistDataTemp(registData);
		}
	}, [registData]);

	// Self explainatory, only the questions
	const getCustomFields = async () => {
		const res = await makeRequest({
			...generateRequestOptions("getCustomForm", {
				queryParams: { school_admission: admissionId, reg_form_setting_tab: TAB_NAME },
			}),
		});
		if (res?.code === 200) {
			setCustomFields(res?.data);
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	// Get the custom field answers from the applicant (the answer is actually from the parent component)
	// This function will convert those answers into an object for faster access
	// Also, we only show the answer in this tab only
	const getCustomFieldsAnswer = (custField, custFieldAns) => {
		let custFieldObjTemp = {};
		let thisTabCustField = {};

		custField?.forEach((i) => {
			thisTabCustField = { ...thisTabCustField, [i?.id]: true };
		});

		// Change the data into a object for faster access
		// But first we want to filter only
		custFieldAns
			?.filter((i) => thisTabCustField?.[i?.custom_field])
			?.forEach((i) => {
				custFieldObjTemp = { ...custFieldObjTemp, [+i?.custom_field]: i };
			});

		setCustomFieldsAnswerTemp(custFieldObjTemp);
	};

	// Handler of onChange for Custom Fields
	// In this tab we are only handling file upload
	const onCustomFieldAnswerChange = async (id, type, value, confirmed = false) => {
		if (type !== CUSTOM_FIELD_TYPE.FILE) {
			showNotice(Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
			return;
		}

		// Show confirm modal if no value is being passed
		// Meaning that we want to remove this current attachment
		if (!value && !confirmed) {
			setShowConfirmModal({ show: true, data: { id, type, value }, type: CONFIRMTYPE.DELETEATTACH });
			return;
		}

		// Check the size of the file
		let file = null;
		if (
			(value && isFileSizeLessOrEqual(value?.target?.files?.[0], 5 * 1024 * 1024)) ||
			!value?.target?.files?.[0]
		) {
			file = value?.target?.files?.[0];
		} else {
			showNotice(Variable.MAX_5MB[ENV_NAME], "error");
			return;
		}

		// Show to the user that we're currently uploading the file
		setIsUploading((p) => ({ ...p, [+id]: true }));

		let sendData = customFieldsAnswerTemp?.[id] || {
			custom_field: id,
			batch_application: applyId,
			school_admission: admissionId,
		};
		sendData = {
			id: sendData?.id,
			custom_field: sendData?.custom_field,
			batch_application: sendData?.batch_application,
			school_admission: sendData?.school_admission,
			file: file || null,
		};

		let url = {};
		if (sendData?.id) {
			// Update existing
			url = generateRequestOptions("editFileCustomFormAnswer", { urlParams: sendData?.id + "/" });
		} else {
			// Create a new one
			url = generateRequestOptions("createFileCustomFormAnswer");
		}
		let res;
		// So fetchrequest cannot take "null" value
		if (sendData?.file) {
			res = await fetchRequest({
				...url,
				body: sendData,
			});
		} else {
			res = await makeRequest({
				...url,
				body: sendData,
			});
		}
		if (res?.code === 200) {
			setCustomFieldsAnswerTemp((p) => ({ ...p, [+id]: res?.data }));
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
		setIsUploading((p) => ({ ...p, [+id]: false }));
	};

	const validateAndSave = async (destination = "next", confirmed = false) => {
		setIsSaving(true);

		if (!registData?.id || !admissionId) {
			showNotice(Variable.CANT_GET_DATA[ENV_NAME], "error");
			return;
		}

		// Step 1 Validating Mandatory Fields
		// We don't need to validate custom fields since it's autosaved
		if (
			typeof registDataTemp?.data_validation_statement_agreed !== "boolean" ||
			!registDataTemp?.data_validation_statement_agreed
		) {
			setFieldsError(true);
			setIsSaving(false);
			showNotice(Variable.FILL_ALL_REQUIRED_LABEL[ENV_NAME], "error");
			return;
		}

		// Check whether the necessary data has been filled
		if (!confirmed) {
			setShowConfirmModal({ show: true, data: null, type: CONFIRMTYPE.SUBMIT });
			setIsSaving(false);
			return;
		}

		// Mandatory saving
		let hasError = false;
		const resMandatory = await makeRequest({
			...generateRequestOptions("saveRegistData", { urlParams: registData?.id }),
			body: registDataTemp,
			json: true,
		});
		if (resMandatory?.code === 200) {
			// Do nothing
		} else {
			showNotice(resMandatory?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
			hasError = true;
			setIsSaving(false);
			return;
		}

		if (destination === "next") {
			// Change status of the submission and submit the form
			const resSubmit = await makeRequest({
				...generateRequestOptions("updateAdmissionBatchApply", { urlParams: applyId }),
				body: { submission_status: "submitted_for_review" },
				json: true,
			});
			if (resSubmit?.code === 200) {
				// Do nothing
			} else {
				showNotice(resSubmit?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
				hasError = true;
				setIsSaving(false);
				return;
			}
		}

		if (!hasError) {
			setIsEditing(false);
			await getCustomFields();
			await getFormData(admissionId);
		}
		setIsSaving(false);
		if (!hasError) {
			// Sadly we need to use timeout for this one
			setTimeout(() => {
				if (destination === "next") {
					showNotice(Variable.FORM_SUBMITTED_SUCCESSFULLY[ENV_NAME]);
					props.history.push(RoutePath.DASHBOARD);
				} else if (destination === "previous") {
					props.history.push(
						RoutePath.APPLY_BATCH + `/${admissionId}/${batchId}/${applyId}` + TAB_ENUM.PARENT
					);
				}
			}, 200);
		}
	};

	return (
		<div className="h-100 d-flex flex-column general-form regist-form">
			<div id="form-scroll-upload" className="flex-grow-1 mh-0 overflow-auto">
				{customFields?.map((i, key) => (
					<FormCustomField
						key={"custom-field-" + key}
						idx={key}
						id={i?.id}
						title={i?.field_title}
						type={i?.field_type}
						instructions={i?.field_question}
						mandatory={i?.is_mandatory}
						options={i?.field_choices}
						isError={fieldsError}
						answer={customFieldsAnswerTemp?.[+i?.id]}
						onAnswerChange={onCustomFieldAnswerChange}
						isUploading={isUploading?.[+i?.id]}
					/>
				))}
				<h5 className="regist-form-header">{Variable.STATEMENT_OF_DATA_VALIDATION[ENV_NAME]}</h5>
				<div className="form-group d-flex align-items-center mb-3">
					<div className="d-flex custom-control custom-checkbox mr-4">
						<input
							type="checkbox"
							className="custom-control-input"
							checked={registDataTemp?.data_validation_statement_agreed || false}
							onChange={() => {}}
						/>
						<label
							className={`custom-control-label empty-label cursorPointer ${
								fieldsError && !registDataTemp?.data_validation_statement_agreed ? " red-border" : ""
							}`}
							onClick={() => {
								setIsEditing(true);
								setRegistDataTemp((p) => ({
									...p,
									data_validation_statement_agreed: !p?.data_validation_statement_agreed,
								}));
							}}
						/>
					</div>
					<div
						className="text-label-dark-gray font-weight-medium cursorPointer"
						onClick={() => {
							setIsEditing(true);
							setRegistDataTemp((p) => ({
								...p,
								data_validation_statement_agreed: !p?.data_validation_statement_agreed,
							}));
						}}
					>
						{admissionData?.data_validation_statement || STATEMENT_VALID_DEFAULT}
					</div>
				</div>
			</div>
			<div className="d-flex align-items-center mt-3">
				<button
					className="btn btn-cancel ml-auto font-weight-semibold py-3 px-4"
					onClick={() => validateAndSave("previous", true)}
				>
					{Variable.PREVIOUS_LABEL[ENV_NAME]}
				</button>
				<button
					className="btn btn-primary ml-5 font-weight-semibold py-3 px-4"
					onClick={() => validateAndSave()}
					disabled={isSaving}
				>
					{Variable.SUBMIT_LABEL[ENV_NAME]}
				</button>
			</div>
			<UnsavedChanges when={isEditing || isSaving} message={Variable.UNSAVED_CHANGES[ENV_NAME]} />
			<Confirm
				selectedEnvironment={ENV_NAME}
				show={showConfirmModal?.show}
				onHide={() => {
					setShowConfirmModal({
						show: false,
						data: null,
						type: null,
					});
				}}
				body={
					showConfirmModal?.type === CONFIRMTYPE.SUBMIT ? (
						<div className="delete-subtitle">{Variable.CONFIRM_SEND_FORM[ENV_NAME]}</div>
					) : undefined
				}
				onAction={(action) => {
					if (action && showConfirmModal?.type === CONFIRMTYPE.SUBMIT) {
						validateAndSave("next", true);
					} else if (action && showConfirmModal?.type === CONFIRMTYPE.DELETEATTACH) {
						let { id, type, value } = showConfirmModal?.data;
						onCustomFieldAnswerChange(id, type, value, true);
					}
					setShowConfirmModal({
						show: false,
						data: null,
						type: null,
					});
				}}
				className={showConfirmModal?.type === CONFIRMTYPE.SUBMIT ? "confirm-blueBtn" : undefined}
				labelYes={showConfirmModal?.type === CONFIRMTYPE.SUBMIT ? Variable.APP_YES_LBL[ENV_NAME] : undefined}
				labelNo={showConfirmModal?.type === CONFIRMTYPE.SUBMIT ? Variable.APP_NO_LBL[ENV_NAME] : undefined}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
		academicYear: state.auth.academicYear,
		isOfficial:
			state.auth.userDetails?.role === USERROLES.OFFICIAL_HIGHSCHOOL ||
			state.auth.userDetails?.role === USERROLES.OFFICIAL_PRIMARYSCHOOL ||
			state.auth.userDetails?.role === USERROLES.FOUNDATION_USER,
	};
};
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(UploadTab));
