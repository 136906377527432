export const LOGIN_SUCCEED = "LOGIN_SUCCEED";
export const SET_USER = "SET_USER";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const LOGGED_IN_USER_FETCHED = "LOGGED_IN_USER_FETCHED";
export const UPDATE_FROM_PERSIST = "UPDATE_FROM_PERSIST";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const UPDATE_SELECTED_LANGUAGE = "UPDATE_LANGUAGE";

export const LOGOUT = "LOGOUT";
export const UPDATE_LOGOUT = "UPDATE_LOGOUT";
export const UPDATE_USER_STATUS_DATA = "UPDATE_USER_STATUS_DATA";
export const SET_STORE_DATA = "SET_STORE_DATA";
export const VIDEO_URL = "VIDEO_URL";
export const SET_LOCK_ALL = "SET_LOCK_ALL";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_IN_STORE = "UPDATE_USER_IN_STORE";
export const SET_SCHOOL_DATA = "SET_SCHOOL_DATA";
export const SET_USER_TYPE = "SET_USER_TYPE";
