import React, { useEffect } from  "react";
import { Prompt } from "react-router-dom";

const UnsavedChanges = ({message, when}) => {

    useEffect(() => {
        window.onbeforeunload = when && (() => message);

        return () => {
            window.onbeforeunload = null;
        };
    }, [when]); //eslint-disable-line

    return <Prompt when={when} message={message}></Prompt>
};

export default UnsavedChanges;