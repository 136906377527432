export const t = (key, env, text) => {
	if (key == null || typeof key !== "object") {
		key == null && console.error("translation not found", text);
		return text || "--";
	}
	return key[env];
};

// Loading Component
export const LOADING_LABEL = { eng: "Loading...", bhs: "Memuat..." };

// No content Component
export const NO_CONTENT_DEFAULT_LABEL = {
	eng: "No Content",
	bhs: "Tidak ada konten",
};
export const NO_CONTENT_DEFAULT_DESCRIPTION = {
	eng: "No Content Available",
	bhs: "Tidak ada konten tersedia",
};
export const PAGE_404 = {
	eng: "404 - Page Not Found",
	bhs: "404 - Halaman Tidak Ditemukan",
};
export const PAGE_NOT_FOUND = {
	eng: "Sorry, the page you are looking for could not be found",
	bhs: "Maaf, halaman yang anda cari tidak dapat ditemukan",
};

// Login Page
export const NEW_STUDENT_ADMISSION = {
	eng: "New Student Admission",
	bhs: "Penerimaan Peserta Didik Baru (PPDB)"
};
export const PLEASE_LOGIN_LONE = {
	eng: "Please login to access the admission site",
	bhs: "Silakan masuk untuk mengakses situs pendaftaran"
};
export const PLEASE_LOGIN_LTWO = {
	eng: "or sign up to create an account.",
	bhs: "atau daftar untuk membuat akun."
};
export const POWERED_BY = {
	eng: "Powered by:",
	bhs: "Oleh"
};
export const FILL_ALL_DETAILS_CORRECTLY = {
	eng: "Fill details correctly",
	bhs: "Isi detail dengan benar",
};
export const ERROR_INVALID_LOGIN = {
	eng: "Login or password invalid",
	bhs: "Nama Pengguna atau Kata Sandi Salah",
};
export const FILL_CORRECT_PASSWORD = {
	eng: "Password Empty",
	bhs: "Kata Sandi kosong",
};
export const FILL_CORRECT_USER_ID = {
	eng: "User Name Empty",
	bhs: "Nama Pengguna kosong",
};
export const FILL_CORRECT_USER_ID_PASSWORD = {
	eng: "User Name & Password Empty",
	bhs: "Nama Pengguna & Kata Sandi kosong",
};
export const SIGNIN = {
	eng: "Sign In",
	bhs: "Masuk",
};
export const EMAIL = {
	eng: "Email",
	bhs: "Email",
};
export const DONT_HAVE_ACCOUNT = {
	eng: "Don't have account?",
	bhs: "Tidak punya akun?",
};
export const CANT_GET_SCHOOL_REFRESH = {
	eng: "Unable to get school information, please refresh",
	bhs: "Tidak dapat menemukan informasi sekolah, silakan refresh",
};
export const CANT_GET_DATA_REFRESH = {
	eng: "Unable to get data for resetting the password, please recheck your link",
	bhs: "Tidak dapat data untuk mengatur ulang kata sandi, silakan cek kembali link anda",
};

// Forgot Password
export const FILL_USER_DETAILS = {
	eng: "Please fill user details",
	bhs: "Silakan isi detail pengguna",
};
export const FORGOT_PASSWORD_MAIL_SENT_SHORT = {
	eng: "Confirmation Email Sent!",
	bhs: "Email konfirmasi terkirim!",
};
export const FORGOT_PASSWORD = {
	eng: "Forgot Password",
	bhs: "Lupa Kata Sandi",
};
export const FORGOT_PASSWORD_MESSAGE = {
	eng: "Please enter your username",
	bhs: "Silakan masukkan nama pengguna anda",
};
export const ENTER = {
	eng: "Enter",
	bhs: "Masukkan",
};
export const SEND = {
	eng: "Send",
	bhs: "Kirim",
};
export const FORGOT_PASSWORD_MAIL_SENT = {
	eng: "We have sent a confirmation email. please check your email to reset your password",
	bhs: "Kami telah mengirimkan email konfirmasi. silakan cek email Anda untuk mengatur ulang kata sandi Anda",
};
export const REQUEST_ALREADY_SENT = {
	eng: "Request already sent",
	bhs: "Permintaan telah dikirim sebelumnya",
};
export const OR_LABEL = {
	eng: "or",
	bhs: "atau",
};
export const BACK_TO_LOGIN = {
	eng: "Back To Login",
	bhs: "Kembali untuk masuk",
};
export const HAVENT_GOT_EMAIL = {
	eng: "Haven't received an email yet?",
	bhs: "Belum mendapatkan e-mail?",
};
export const RESENT = {
	eng: "Resend",
	bhs: "Kirim Ulang",
};

// Signup
export const SIGNUP = {
	eng: "Signup",
	bhs: "Daftar",
};
export const FIRST_NAME = {
	eng: "First Name",
	bhs: "Nama Depan",
};
export const LAST_NAME = {
	eng: "Last Name",
	bhs: "Nama Belakang",
};
export const USERNAME = {
	eng: "Username",
	bhs: "Nama Pengguna",
};
export const EMAIL_ID = {
	eng: "Email ID",
	bhs: "Email ID",
};
export const PASSWORD_VAL_1 = {
	eng: "contains at least 8 characters",
	bhs: "memiliki 8 karakter",
};
export const PASSWORD_VAL_2 = {
	eng: "contains at least one number (0-9) or a symbol",
	bhs: "memiliki satu angka (0-9) atau simbol",
};
export const PASSWORD_FOLLOW_PATTERN = {
	eng: "Your password needs to follow the format",
	bhs: "Kata sandi anda harus memenuhi format",
};
export const CONFIRM_PASSWORD = {
	eng: "Confirm Password",
	bhs: "Konfirmasi Kata Sandi",
};
export const ENTER_CONFIRM_PASSWORD = {
	eng: "Enter Confirm Password",
	bhs: "Masukkan Konfirmasi Kata Sandi",
};
export const PASSWORD_NOT_MATCH = {
	eng: "Those passwords didn't match. Try again.",
	bhs: "Kata sandi tidak cocok. Silakan coba lagi.",
};
export const DO_YOU_HAVE_AN_ACCOUNT = {
	eng: "Do you have an account?",
	bhs: "Apakah anda memiliki akun?",
};
export const PASSWORD = {
	eng: "Password",
	bhs: "Kata Sandi",
};
export const LOGIN = {
	eng: "Login",
	bhs: "Masuk",
};
export const FILL_ALL_FIELDS = {
	eng: "Please fill all of the fields",
	bhs: "Silakan semua kolom",
};
export const INVALID_EMAIL_FORMAT = {
	eng: "Invalid email format",
	bhs: "Format email tidak valid",
};
export const INVALID_USERNAME_FORMAT = {
	eng: "Invalid username format, username can only accept letters, digits, @, ., +, -, and _",
	bhs: "Format nama pengguna tidak valid, nama pengguna hanya menerima huruf, angka, @, ., +, -, dan _",
};
export const ACCOUNT_CREATED = {
	eng: "Account has been created, please check your email.",
	bhs: "Akun telah berhasil dibuat, silakan cek email anda.",
};
export const USERNAME_IS_TAKEN = {
	eng: "This username is taken",
	bhs: "Nama Pengguna ini sudah dipakai",
};

// Reset Password
export const FIELD_ERROR = {
	eng: "Please input your fields correctly",
	bhs: "Silakan isi semua kolom dengan benar",
};
export const PASSWORD_RESET_SUCCESS_L1 = {
	eng: "Your password was successfully reset.",
	bhs: "Kata sandi anda berhasil disetel ulang.",
};
export const PASSWORD_RESET_SUCCESS_L2 = {
	eng: "Please proceed to login.",
	bhs: "Silakan lanjutkan untuk masuk",
};
export const PROCEED_TO_LOGIN = {
	eng: "Proceed to login.",
	bhs: "Lanjutkan untuk masuk",
};

// Validate User
export const VERIFY_SUCCESS_L1 = {
	eng: "Your account has been verified.",
	bhs: "Akun anda berhasil diverifikasi.",
};
export const VERIFY_FAILED_L1 = {
	eng: "Account verification failed!",
	bhs: "Verifikasi akun gagal!",
};

export const START_TOUR = {
	eng: "Start Tour in",
	bhs: "Mulai Tur di",
};
export const TOAST_SESSION_EXPIRED = {
	eng: "Session Expired",
	bhs: "Sesi berakhir",
};
export const TOAST_USER_NOT_VALID = {
	eng: "User Not Valid",
	bhs: "Pengguna Tidak Valid",
};

export const SELECT_FILTER = {
	eng: "Select Filter",
	bhs: "Pilih Filter",
};
export const CALENDAR = {
	eng: "Calendar",
	bhs: "Kalender",
};
export const ANNOUNCEMENT = {
	eng: "Announcement",
	bhs: "Pengumuman",
};

// MENU NAVIGATION LABEL
export const DASHBOARD = {
	eng: "Dashboard",
	bhs: "Dasbor",
};

// Profile
export const USER_INFORMATION = {
	eng: "User Information",
	bhs: "Informasi Pengguna",
};
export const ACCOUNT_NAME = {
	eng: "Account Name",
	bhs: "Nama Akun",
};
export const EMAIL_ADDRESS = {
	eng: "Email Address",
	bhs: "Alamat Email",
};
export const PROFILE_DETAIL = {
	eng: "Profile Detail",
	bhs: "Profil Lengkap",
};

// Registration
export const STUDENT_INFORMATION = {
	eng: "Student Information",
	bhs: "Informasi Siswa",
};
export const PARENT_INFORMATION = {
	eng: "Parent/Guardian Information",
	bhs: "Informasi Orang Tua/Wali",
};
export const DOCUMENTS_UPLOAD = {
	eng: "Documents Upload",
	bhs: "Pengunggahan Dokumen",
};

// Registration
export const CUSTOM_FIELD = {
	eng: "Custom Field",
	bhs: "Kolom Kustom",
};
export const FILL_ALL_REQUIRED_LABEL = {
	eng: "Fill all required fields",
	bhs: "Isi semua bidang wajib",
};
export const CANT_GET_DATA = {
	eng: "Unable to get important data, please try again",
	bhs: "Gagal mendapatkan data penting, silakan coba kembali",
};
export const REGISTRATION_FORM = {
	eng: "Registration Form",
	bhs: "Formulir Pendaftaran",
};
export const OPTIONAL = {
	eng: "Optional",
	bhs: "Opsional",
};
export const FORM_ALREADY_SUBMITTED = {
	eng: "You have submitted this form",
	bhs: "Anda sudah pernah mengirimkan formulir ini",
};

// Student Information
export const ALUMNI_NON_ALUMNI = {
	eng: "Alumni/Non Alumni",
	bhs: "Alumni/Non-Alumni",
};
export const ALUMNI_SCHOOL_NAMES = {
	eng: "Alumni School Names",
	bhs: "Nama Sekolah Alumni",
};
export const NON_ALUMNI_SCHOOL_NAMES = {
	eng: "Non-Alumni School Names",
	bhs: "Nama Sekolah Non-Alumni",
};
export const ALUMNI_SCHOOL_NAMES_PLACEHOLDER = {
	eng: "Type a school name owned by your foundation here",
	bhs: "Ketikkan nama sekolah yang dimiliki oleh yayasan Anda di sini",
};
export const ADD_SCHOOL = {
	eng: "Add School",
	bhs: "Tambah Sekolah",
};
export const TEXT_INPUT_BY_APPLICANT = {
	eng: "{0} text inputted by the applicant",
	bhs: "Pengisian {0} oleh pendaftar",
};
export const FILLED_BY_APPLICANT = {
	eng: "Filled by applicant",
	bhs: "Diisi oleh pendaftar",
};
export const OPTION_SELECTED_BY_APPLICANT = {
	eng: "{0} option selected by the applicant",
	bhs: "Pemilihan {0} oleh pendaftar",
};
export const APPLICANT_NAME = {
	eng: "Applicant Name",
	bhs: "Nama Siswa",
};
export const BIRTH_PLACE_DATE = {
	eng: "Birth Place & Date",
	bhs: "Tempat dan Tanggal Lahir",
};
export const BIRTH_PLACE = {
	eng: "Birth Place",
	bhs: "Tempat Lahir",
};
export const BIRTH_DATE = {
	eng: "Birth Date",
	bhs: "Tanggal Lahir",
};
export const BIRTH_CERTIFICATE_LABEL = {
	eng: "Birth Certificate",
	bhs: "Akta Kelahiran",
};
export const ACADEMIC_CERTIFICATE_LABEL = {
	eng: "Academic Certificate",
	bhs: "Ijazah",
};
export const HOME_ADDRESS = {
	eng: "Home Address",
	bhs: "Alamat Rumah",
};
export const CITY_REGENCY = {
	eng: "City/Regency",
	bhs: "Kota/Kabupaten",
};
export const FULL_ADDRESS = {
	eng: "Full Address",
	bhs: "Alamat Lengkap",
};
export const ADD_CUSTOM_FIELD = {
	eng: "Add Custom Field",
	bhs: "Tambahkan Kolom Kustom",
};
export const APPLICANT_ANSWER = {
	eng: "Applicant's Answer",
	bhs: "Jawaban Pendaftar",
};
export const MALE_LABEL = {
	eng: "Male",
	bhs: "Laki-Laki",
};
export const FEMALE_LABEL = {
	eng: "Female",
	bhs: "Perempuan",
};
export const SCHOOL_NAME = {
	eng: "School Name",
	bhs: "Nama Sekolah",
};
export const DELETED_SUCCESSFULLY = {
	eng: "deleted successfully.",
	bhs: "berhasil dihapus.",
};
export const GENDER_LABEL = {
	eng: "Gender",
	bhs: "Jenis Kelamin",
};
export const RELIGION_LABEL = {
	eng: "Religion",
	bhs: "Agama",
};
export const ADDRESS_LABEL = {
	eng: "Address",
	bhs: "Alamat",
};
export const NISN = {
	eng: "NISN",
	bhs: "NISN",
};

// Parent Information
export const PARENT_GUARDIAN_NAME = {
	eng: "Parent/Guardian Name",
	bhs: "Nama Orang Tua/Wali",
};
export const FULL_NAME = {
	eng: "Full Name",
	bhs: "Nama Lengkap",
};
export const PARENT_GUARDIAN_RELATION = {
	eng: "Parent/Guardian Relation",
	bhs: "Relasi dengan Orang Tua/Wali",
};
export const RELATION_WITH_PARENT_GUARDIAN = {
	eng: "Relation with Parent/Guardian",
	bhs: "Relasi dengan Orang Tua/Wali",
};
export const PARENT_GUARDIAN_OCCUPATION = {
	eng: "Parent/Guardian Occupation",
	bhs: "Pekerjaan Orang Tua/Wali",
};
export const OCCUPATION = {
	eng: "Occupation",
	bhs: "Pekerjaan",
};
export const INCOME = {
	eng: "Income (Rp)",
	bhs: "Pendapatan per bulan (Rp)",
};
export const PARENT_GUARDIAN_CONTACT = {
	eng: "Parent/Guardian Contact Details",
	bhs: "Detil Kontak Orang Tua/Wali",
};
export const FATHER = {
	eng: "Father",
	bhs: "Ayah",
};
export const MOTHER = {
	eng: "Mother",
	bhs: "Ibu",
};
export const GUARDIAN = {
	eng: "Guardian",
	bhs: "Wali",
};

// Document Upload Tab
export const ADD_DOCUMENT_FIELD = {
	eng: "Add Document Field",
	bhs: "Tambahkan Pengunggahan Dokumen",
};
export const DOCUMENT_UPLOAD_APPLICANT = {
	eng: "Document Upload by Applicant",
	bhs: "Pengunggahan Dokumen dari Pendaftar",
};
export const STATEMENT_OF_DATA_VALIDATION = {
	eng: "Statement of Data Validation",
	bhs: "Pernyataan Validasi Data",
};
export const MAX_5MB = {
	eng: "Maximum File size 5MB",
	bhs: "Ukuran Berkas Maksimal 5MB",
};
export const CONFIRM_SEND_FORM = {
	eng: "Are you sure you want to submit this form?",
	bhs: "Apakah anda yakin untuk mengirim formulir ini?",
};
export const UPLOADING = {
	eng: "Uploading...",
	bhs: "Mengunggah...",
};
export const FORM_SUBMITTED_SUCCESSFULLY = {
	eng: "Form submitted successfully",
	bhs: "Formulir berhasil dikirim",
};

// Custom Field
export const DOCUMENT_FIELD = {
	eng: "Document Field",
	bhs: "Pengunggahan Dokumen",
};
export const FIELD_TYPE = {
	eng: "Field Type",
	bhs: "Tipe Kolom",
};
export const FIELD_TITLE = {
	eng: "Field Title",
	bhs: "Judul Kolom",
};
export const TEXT_TYPE = {
	eng: "Text",
	bhs: "Teks",
};
export const NUMBER_TYPE = {
	eng: "Number",
	bhs: "Angka",
};
export const MULTIPLE_CHOICE_TYPE = {
	eng: "Multiple Choice",
	bhs: "Pilihan Ganda",
};
export const CHECKBOXES_TYPE = {
	eng: "Checkboxes",
	bhs: "Checkbox",
};
export const DATE_TYPE = {
	eng: "Date",
	bhs: "Tanggal",
};
export const UPLOAD_TYPE = {
	eng: "File",
	bhs: "Unggah",
};
export const IS_MANDATORY = {
	eng: "Is mandatory",
	bhs: "Adalah Wajib",
};
export const SAVED_SUCCESSFULLY = {
	eng: "Saved Successfully",
	bhs: "Berhasil Disimpan",
};

export const LANGUAGE_LBL = {
	eng: "Language",
	bhs: "Bahasa",
};
export const PROFILE = {
	eng: "Profile",
	bhs: "Profil",
};
export const OPTION_LABEL = {
	eng: "Options",
	bhs: "Pilihan",
};
export const ADD_OPTION_LABEL = {
	eng: "Add Options",
	bhs: "Tambah Pilihan",
};
export const UPLOAD = {
	eng: "Upload",
	bhs: "Unggah",
};
export const INSTRUCTION_LABEL = {
	eng: "Instruction",
	bhs: "Instruksi",
};
export const SHOW_LABEL = {
	eng: "Show",
	bhs: "Tampilkan",
};
export const HIDE_LABEL = {
	eng: "Hide",
	bhs: "Sembunyikan",
};
export const PROVINCE = {
	eng: "Province",
	bhs: "Provinsi",
};
export const PHONE_NUMBER = {
	eng: "Phone Number",
	bhs: "Nomor Telepon",
};
export const CANCEL = {
	eng: "Cancel",
	bhs: "Batal",
};

// Unsaved Changes
export const UNSAVED_CHANGES = {
	eng: "You have unsave changes, are you sure you want to leave this page?",
	bhs: "Anda memiliki perubahan yang belum disimpan, apakah anda yakin untuk meninggalkan halaman ini?",
};
export const LEAVE_PAGE_LABEL = {
	eng: "Are you sure you want to leave this page?",
	bhs: "Apakah anda yakin untuk meninggalkan halaman ini?",
};

export const TEACHER = {
	eng: "Teacher",
	bhs: "Guru",
};
export const ADMIN = {
	eng: "Admin",
	bhs: "Admin",
};
export const EXAM_MENU = {
	eng: "Exam",
	bhs: "Kuis",
};
export const CLASS = {
	eng: "Class",
	bhs: "Kelas",
};
export const ADD_EVENT = {
	eng: "Add Event",
	bhs: "Tambah Acara",
};
export const WORKBOOK_MENU = {
	eng: "Workbook",
	bhs: "Buku Kerja",
};
export const ADD_TEMPLATE = {
	eng: "Add Template",
	bhs: "Buat Templat",
};
export const SCHOOL_MEETINGS = {
	eng: "School Meetings",
	bhs: "Konferensi Sekolah",
};
export const SCHOOL_TIMETABLE = {
	eng: "School Timetable",
	bhs: "Jadwal Sekolah",
};
export const NOT_ACTIVE_ACADEMIC_YEAR = {
	eng: "Not an active academic year",
	bhs: "Bukan tahun akademik aktif",
};
export const ACADEMIC_YEAR_LBL = {
	eng: "Academic Year",
	bhs: "Tahun Akademik",
};
export const CANCEL_LABEL = { eng: "Cancel", bhs: "Batal" };
export const SAVE_LABEL = { eng: "Save", bhs: "Simpan" };
export const SUBMIT_LABEL = { eng: "Submit", bhs: "Kirim" };

export const COMMUNICATION = {
	eng: "Communication",
	bhs: "Komunikasi",
};
export const FORUM = {
	eng: "Forum",
	bhs: "Forum",
};
export const WORKBOOK_WIKI = {
	eng: "Workbook Wiki",
	bhs: "Petunjuk Buku Kerja",
};
export const VIRTUAL_LAB = {
	eng: "Virtual Lab",
	bhs: "Lab Maya",
};
export const GRADEBOOK = {
	eng: "Gradebook",
	bhs: "Buku Nilai",
};
export const TEST_BANK_LABEL = {
	eng: "Test Bank",
	bhs: "Bank Soal",
};
export const SAVE = {
	eng: "Save",
	bhs: "Simpan",
};
export const VIDEO_CONFERENCING = {
	eng: "Conference",
	bhs: "Konferensi",
};
export const SCHEDULE_MEETING = {
	eng: "Schedule Meeting",
	bhs: "Jadwalkan Konferensi",
};
export const ASSESSMENT = {
	eng: "Assessment",
	bhs: "Penilaian",
};
export const TIME_TABLE = { eng: "Timetable", bhs: "Jadwal" };
export const REPORT = {
	eng: "Report",
	bhs: "Laporan",
};
export const PROJECT = {
	eng: "Project",
	bhs: "Proyek",
};
export const MY_TIMETABLE = {
	eng: "My Timetable",
	bhs: "Jadwal Saya",
};
export const CLASS_TRANSFER_LABEL = {
	eng: "Class Transfer",
	bhs: "Pemindahan Kelas",
};
export const ADD_USER_LABEL = {
	eng: "Add New User",
	bhs: "Tambah Pengguna Baru",
};
export const ELECTIVE_SUBJECT_ENROLLMENT = {
	eng: "Elective Subject Enrollment",
	bhs: "Pengalokasian Mata Pelajaran Pilihan",
};
export const ADD_ASSESSMENT = {
	eng: "Add Assessment",
	bhs: "Tambah Penilaian",
};
export const TEST_BANK = {
	eng: "Test Bank",
	bhs: "Bank Soal",
};
export const SETTINGS_LBL = {
	eng: "Settings",
	bhs: "Pengaturan",
};
export const HELP_AND_SUPPORT_LBL = {
	eng: "Help & Support",
	bhs: "Bantuan & Dukungan",
};
export const LOGOUT_LBL = {
	eng: "Logout",
	bhs: "Keluar",
};
export const ALL_TEXT = { eng: "All", bhs: "Semua" };
export const NOTIFICATION = {
	eng: "Notification",
	bhs: "Notifikasi",
};
export const VIEW_ALL = {
	eng: "View All",
	bhs: "Lihat semua",
};
export const FILTER_BY_UNREAD = {
	eng: "Filter by unread",
	bhs: "Filter belum dibaca",
};
export const MARK_ALL_AS_READ = {
	eng: "Mark all as read",
	bhs: "Tandai semua sudah dibaca",
};
export const NO_UNREAD_NOTIFICATIONS = {
	eng: "No Notifications",
	bhs: "Tidak ada pemberitahuan",
};
export const HELLO_WELCOME = { eng: "Hello Welcome!", bhs: "Selamat datang!" };
export const ADMIN_VIEW = {
	eng: "Admin View",
	bhs: "Tampilan Admin",
};
export const EXIT_VIEW = {
	eng: "Exit View",
	bhs: "Keluar",
};
export const CLOSE = {
	eng: "Close",
	bhs: "Tutup",
};
export const CREATE_GROUP = {
	eng: "Create Group",
	bhs: "Buat kelompok",
};
export const OK_LABEL = { eng: "OK", bhs: "OK" };
export const EDIT_TEXT = { eng: "Edit", bhs: "Ubah" };
export const SCROLL_LOADING = {
	eng: "Loading...",
	bhs: "Memuat...",
};
export const SCROLL_PULL_DOWN_TO_REFRESH = {
	eng: "↓ Pull down to refresh",
	bhs: "↓ Tarik ke bawah untuk menyegarkan",
};
export const SCROLL_RELEASE_TO_REFRESH = {
	eng: "↑ Release to refresh",
	bhs: "↑ Lepaskan untuk menyegarkan",
};
export const SEARCH = {
	eng: "Search",
	bhs: "Cari",
};
export const GLOSSARY = {
	eng: "Glossary",
	bhs: "Glosarium",
};
export const NOTI_WORKBOOK_DELETED = {
	eng: "Workbook no longer exists or has been deleted",
	bhs: "Buku kerja sudah tidak ada atau sudah dihapus",
};
export const NOTE_TAKING = {
	eng: "Note Taking",
	bhs: "Catatan",
};
export const NOTI_MEETING_DELETED = {
	eng: "Meeting no longer exists or has been deleted",
	bhs: "Meeting sudah tidak ada atau sudah dihapus",
};
export const NOTI_ANNOUNCEMENT_DELETED = {
	eng: "Announcement no longer exists or has been deleted",
	bhs: "Pengumuman sudah tidak ada atau sudah dihapus",
};
export const NOTI_ASSESSMENT_DELETED = {
	eng: "Assessment no longer exists or has been deleted",
	bhs: "Penilaian sudah tidak ada atau sudah dihapus",
};
export const NOTI_EVENT_DELETED = {
	eng: "Calendar event no longer exists or has been deleted",
	bhs: "Acara sudah tidak ada atau sudah dihapus",
};
export const NOTI_EVENT_NOT_CREATOR = {
	eng: "Cannot edit event that is not created by you",
	bhs: "Tidak bisa mengubah acara yang tidak anda buat",
};
export const NOTI_MEETING_NOT_CREATOR = {
	eng: "Cannot edit meeting that is not created by you",
	bhs: "Tidak bisa mengubah konferensi yang tidak anda buat",
};
export const NOTI_SUBJECT_DELETED = {
	eng: "Subject content no longer exists or has been deleted",
	bhs: "Konten pelajaran sudah tidak ada atau sudah dihapus",
};
export const SECTION_LABEL = { eng: "Section", bhs: "Kelas" };
export const SECTION_PLACEHOLDER = {
	eng: "Select Section",
	bhs: "Pilih Kelas",
};

// Note Taking
export const CREATE_CATEGORY = {
	eng: "Create Category",
	bhs: "Buat kategori",
};
export const CREATE_NEW_CATEGORY = {
	eng: "Create New Category",
	bhs: "Buat kategori baru",
};
export const CATEGORY = {
	eng: "Category",
	bhs: "Kategori",
};
export const MOVE_TO_CATEGORY = {
	eng: "Move to Category",
	bhs: "Pindahkan ke kategori",
};
export const CATEGORY_NAME = {
	eng: "Category Name",
	bhs: "Nama kategori",
};
export const SELECT_CATEGORY = {
	eng: "Select Category",
	bhs: "Pilih Kategori",
};
export const ADD_NOTE = {
	eng: "Add Note",
	bhs: "Tambah Catatan",
};
export const DRAWING = {
	eng: "Drawing",
	bhs: "Menggambar",
};
export const ADD_IMAGE = {
	eng: "Add Image",
	bhs: "Tambah Gambar",
};
export const OPEN_IN_TAB = {
	eng: "Open in Tab",
	bhs: "Buka di Halaman Penuh",
};
export const DICTIONARY_SEARCH_ERROR = {
	eng: "No similar word found.",
	bhs: "Tidak ada kata yang sama ditemukan.",
};
export const SEARCH_WORD_HERE = {
	eng: "Search words here",
	bhs: "Cari kata disini",
};
export const NOTE_DELETED_SUCCESSFULLY = {
	eng: "Note deleted successfully.",
	bhs: "Catatan berhasil dihapus.",
};
export const CATEGORY_DELETED_SUCCESSFULLY = {
	eng: "Category deleted successfully.",
	bhs: "Kategori berhasil dihapus.",
};
export const TAKE_A_NOTE = {
	eng: "Take a note",
	bhs: "Tulis catatan",
};
export const TITLE_LENGTH_ERROR = {
	eng: "Title can't be typed anymore when reach 100 characters",
	bhs: "Judul tidak dapat melebihi 100 karakter",
};
export const SEARCH_ERROR = {
	eng: "Minimum 2 letters required for search",
	bhs: "Minimal 2 huruf untuk mencari",
};
export const DICTIONARY_SEARCH_PLACEHOLDER = {
	eng: "Type word to search",
	bhs: "Ketikkan kata untuk mencari",
};
export const CHOOSE_LANGUAGE_LBL = {
	eng: "Choose Language",
	bhs: "Pilih Bahasa",
};

export const COLOR = {
	eng: "Color",
	bhs: "Warna",
};

export const DELETE = {
	eng: "Delete",
	bhs: "Hapus",
};

export const ENTER_NOTE_TITLE = {
	eng: "Enter Note Title",
	bhs: "Masukkan Judul Catatan",
};
export const OPTIONS = {
	eng: "Options",
	bhs: "Pilihan",
};
export const SOME_ERROR_OCCURED = {
	eng: "Some error occured",
	bhs: "Beberapa kesalahan terjadi",
};
export const TITLE = {
	eng: "Title",
	bhs: "Judul",
};
export const OF = {
	eng: "of",
	bhs: "dari",
};
export const PAGE = {
	eng: "Page",
	bhs: "Halaman",
};

export const UPLOAD_IMAGE = {
	eng: "Uploading Image",
	bhs: "Mengunggah Gambar",
};
export const UPLOAD_IMAGE_LABEL = {
	eng: "Upload Image",
	bhs: "Unggah Gambar",
};
export const TEXT = {
	eng: "Text",
	bhs: "Teks",
};
export const SOMETHING_WENT_WRONG = {
	eng: "Something went wrong!!",
	bhs: "Ada Kesalahan!!",
};
export const LOADING = {
	eng: "Loading",
	bhs: "Memuat",
};
export const DIALOG_CONFIRM_TITLE = {
	eng: "Are You Sure?",
	bhs: "Apakah Anda Yakin?",
};
export const DIALOG_CONFIRM_BODY = {
	eng: "Are you sure you want to delete this item?",
	bhs: "Anda yakin ingin menghapus ini?",
};
export const APP_NO_LBL = { eng: "No", bhs: "Tidak" };
export const APP_YES_LBL = { eng: "Yes", bhs: "Ya" };
export const SEARCH_BY_NAME = {
	eng: "Search by name",
	bhs: "Cari berdasarkan nama",
};
export const SELECT_LABEL = { eng: "Select", bhs: "Pilih" };
export const UI_SELECT_NO_OPTION_LBL = {
	eng: "No option",
	bhs: "Tidak ada pilihan",
};
export const PRELOADED_LABEL = {
	eng: "Preloaded",
	bhs: "Standar",
};
export const CALENDAR_FREQ_CUSTOM = {
	eng: "Custom",
	bhs: "Kustom",
};
export const UI_SELECT_DEFAULT_OPTION_LBL = { eng: "Select", bhs: "Pilih" };

// Math Equation Modal
export const MATH_EQUATION = {
	eng: "Math Equation",
	bhs: "Rumus Matematika",
};
export const ENTER_EQUATION = {
	eng: "Enter your equation:",
	bhs: "Masukkan rumus:",
};
export const USE_LATEX = {
	eng: "Or use LaTeX:",
	bhs: "Atau gunakan LaTeX:",
};
export const ANDROID_OPEN_IN = {
	eng: "Open Portal Sekolah in...",
	bhs: "Buka Portal Sekolah di...",
};
export const PORTALSEKOLAH_APP = {
	eng: "PortalSekolah App",
	bhs: "Aplikasi Portal Sekolah",
};
export const OPEN_LBL = {
	eng: "Open",
	bhs: "Buka",
};
export const BROWSER_LABEL = {
	eng: "Browser",
	bhs: "Peramban",
};
export const CONTINUE_LBL = {
	eng: "Continue",
	bhs: "Lanjutkan",
};
export const PAGE_LABEL = { eng: "Page", bhs: "Halaman" };
export const OF_LABEL = { eng: "of", bhs: "dari" };
export const PREVIOUS_LABEL = { eng: "Previous", bhs: "Sebelumnya" };
export const NEXT_LABEL = { eng: "Next", bhs: "Selanjutnya" };
export const FAILED_TO_FETCH_GRADES = {
	eng: "Failed to fetch grades",
	bhs: "Gagal mengambil data jenjang kelas",
};
export const SMS = {
	eng: "SMS",
	bhs: "SMS"
};
export const SCHOOL_LIBRARY = {
	eng: "Library",
	bhs: "Library"
};
export const SCHOOL_ADMISSION = {
	eng: "School Admission",
	bhs: "PPDB"
};
export const IMAGE_SIZE_EXCEEDED_15_MB = {
	eng: "Image size should be less than 15MB",
	bhs: "Ukuran gambar maksimal adalah 15 MB",
};
export const NOIT_FORUM_DELETED = {
	eng: "Forum no longer exists or has been deleted",
	bhs: "Forum sudah tidak ada atau sudah dihapus",
};

// SMS Landing Page
export const SCHOOL_MANAGEMENT_SYSTEM = {
	eng: "School Management System",
	bhs: "Sistem Manajemen Sekolah",
};
export const SMS_SUBTITLE = {
	eng: "Menus on School Management System page provides you the functionality to manage school-related administrative data and transactions, such as Library, School Admission, School Fee, and School Finance",
	bhs: "Menu dalam halaman Sistem Manajemen Sekolah memberikan Anda fungsionalitas untuk mengatur data administratif dan transaksi terkait kepentingan sekolah, seperti Perpustakaan, PPDB (Penerimaan Peserta Didik Baru), Uang Sekolah, dan Finansial Sekolah",
};

// List Books
export const LIST_BOOKS = {
	eng: "List of Books",
	bhs: "Daftar Buku"
};
export const MY_LOAN_HISTORY = {
	eng: "My Loan History",
	bhs: "History Pinjaman Saya"
};
export const SELECT_LIBRARY_TYPE = {
	eng: "Select Library Type",
	bhs: "Pilih Tipe Perpustakaan"
};
export const SORT_BY = {
	eng: "Sort By",
	bhs: "Urutkan berdasarkan"
};
export const A_TO_Z = {
	eng: "A To Z",
	bhs: "A To Z"
};
export const SEARCH_BOOK = {
	eng: "Search Book",
	bhs: "Cari Buku"
};
export const ADVANCE_SEARCH = {
	eng: "Advance Search",
	bhs: "Pencarian Lebih Lanjut"
};
export const BOOK_CODE = {
	eng: "Book Code",
	bhs: "Kode Buku"
};
export const BOOK_CODE_PLACEHOLDER = {
	eng: "Enter Book Code",
	bhs: "Masukkan Kode Buku"
};
export const PUBLISHER_LABEL = {
	eng: "Publisher",
	bhs: "Penerbit"
};
export const PUBLISHER_PLACEHOLDER = {
	eng: "Enter Publisher Name",
	bhs: "Masukkan Nama Penerbit"
};
export const ISBN_LABEL = {
	eng: "ISBN",
	bhs: "ISBN"
};
export const ISBN_PLACEHOLDER = {
	eng: "Enter ISBN Code",
	bhs: "Masukkan Kode ISBN"
};
export const CONTRIBUTOR_LABEL = {
	eng: "Contributor",
	bhs: "Pengarang"
};
export const CONTRIBUTOR_PLACEHOLDER = {
	eng: "Enter Contributor Name",
	bhs: "Masukkan Nama Pengarang"
};
export const TAGS_LABEL = {
	eng: "Tags",
	bhs: "Tanda"
};
export const TAGS_PLACEHOLDER = {
	eng: "Enter Tags",
	bhs: "Masukkan Tanda"
};
export const LIBRARY_TYPE_LABEL = {
	eng: "Library Type",
	bhs: "Tipe Perpustakaan"
};

// Modal Details Book
export const PREVIEW_LABEL = {
	eng: "Preview",
	bhs: "Pratinjau"
};
export const RESERVE_BOOK_LABEL = {
	eng: "Reserve Book",
	bhs: "Pesan Buku"
};
export const PAGES_LABEL = {
	eng: "Pages",
	bhs: "Halaman",
}
export const COPIES_LABEL = {
	eng: "Copies",
	bhs: "Salinan",
}
export const AVAILABLE_COPY_LABEL = {
	eng: "Available Copy",
	bhs: "Salinan yang Tersedia",
}
export const YEAR_OF_PUBLISH_LABEL = {
	eng: "Year of Publish",
	bhs: "Tahun Terbit",
}
export const PUBLICATION_LABEL = {
	eng: "Publication",
	bhs: "Publikasi",
}
export const PRICE_LABEL = {
	eng: "Price",
	bhs: "Harga",
}
export const SIMILAR_BOOKS_LABEL = {
	eng: "Similar Books",
	bhs: "Buku Sejenis",
}
export const RESERVED_DESCRIPTION = {
	eng: "The book will be reserved and you will be notified when your reservation is approved.",
	bhs: "Buku akan dipesan dan Anda akan diberi tahu saat reservasi Anda disetujui.",
}

// Loan History
export const LOAN_HISTORY = {
	eng: "Loan History",
	bhs: "Sejarah Peminjaman.",
}
export const BOOK_IN_RESERVATIONS = {
	eng: "Book in Reservations ",
	bhs: "Buku yang Dipinjam",
}
export const ACTIVE_LOANS = {
	eng: "Active Loans",
	bhs: "Peminjaman Aktif",
}
export const LENDING_DATE_TIME = {
	eng: "Lending Date/Time",
	bhs: "Komentar Tanggal/Waktu",
}
export const LENDING_DATE_TO_RETURN = {
	eng: "Date to Return",
	bhs: "Tanggal untuk dikembalikan",
}
export const LENDING_RETURNED_DATE = {
	eng: "Returned Date",
	bhs: "Tanggal Pengembalian",
}
export const LENDING_COMMENTS = {
	eng: "Lending Comments",
	bhs: "Komentar Peminjaman",
}
export const CANCEL_RESERVATION = {
	eng: "Cancel Reservation",
	bhs: "Batalkan Peminjaman",
}
export const RESERVATION_DATE_TIME = {
	eng: "Reservation Date/Time",
	bhs: "Tanggal/Waktu Peminjaman",
}
export const RESERVATION_STATUS = {
	eng: "Reservation Status",
	bhs: "Status Peminjaman",
}

// Dashboard
export const REACHED_LIMIT_LABEL = {
	eng: "Applicants have reached limit",
	bhs: "Pendaftar telah mencapai batas",
}
export const APPLY_LABEL = {
	eng: "Apply",
	bhs: "Lamar",
}
export const APPLICATION_STATUS = {
	eng: "Application Status",
	bhs: "Status Pendaftaran",
}
export const BATCH_APPLIED_LABEL = {
	eng: "Batch Applied",
	bhs: "Gelombang Terdaftar",
}
export const SUBMISSION_LABEL = {
	eng: "Submission",
	bhs: "Pengumpulan",
}
export const STATUS_LABEL = {
	eng: "Status",
	bhs: "Status",
}
export const CONTINUE_APPLY_LABEL = {
	eng: "Continue Apply",
	bhs: "Lanjutkan Pendaftaran",
}
export const UNFINISHED_REGISTRATION = {
	eng: "Unfinished",
	bhs: "Belum Selesai",
}
export const IN_REVIEW_LABEL = {
	eng: "In Review",
	bhs: "Dalam Tinjauan",
}
export const APPROVED_LABEL = {
	eng: "Approved",
	bhs: "Disetujui",
}
export const REJECTED_LABEL = {
	eng: "Rejected",
	bhs: "Ditolak",
}
