import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Variable from "../../shared/utils/variables";
import * as RoutePath from "../../shared/utils/routeLink";
import "./ProfileComponent.scss";
import ProfileContainer from "./comps/ProfileContainer";

const ProfileComponent = ({ ENV_NAME, userDetails, ...props }) => {
	const goToDashboard = () => {
		props.history.push(RoutePath.DASHBOARD);
	};

	return (
		<div className="widget profile-component">
			<div className="container-fluid h-100 d-flex flex-column p-0 w-100">
				<div className="w-100 px-0 mx-0 mb-4 pb-3 pt-2 d-block">
					<div className="col-12 p-0 d-flex align-items-center">
						<h3 className="p-back mr-4 cursorPointer" onClick={() => goToDashboard()}>
							<i className="fa fa-arrow-left"></i>
						</h3>
						<h3 className="p-header">{Variable.PROFILE_DETAIL[ENV_NAME]}</h3>
					</div>
				</div>
				<div className="col-12 col-lg-8 p-0 d-flex flex-column">
					<div className="row mx-0 flex-nowrap flex-column p-scroll">
						<ProfileContainer header={Variable.USER_INFORMATION[ENV_NAME]} headerClassName="first">
							<div className="mock-field d-block d-md-flex justify-content-between">
								<div className="d-flex flex-column">
									<div className="mf-item">
										<label>{Variable.ACCOUNT_NAME[ENV_NAME]}</label>
										<div className="mf-field">
											{`${userDetails?.first_name || "-"} ${userDetails?.last_name || "-"}`}
										</div>
									</div>
									<div className="mf-item mb-md-0">
										<label>{Variable.EMAIL[ENV_NAME]}</label>
										<div className="mf-field d-flex align-items-center">
											{userDetails?.email || "-"}
										</div>
									</div>
								</div>
								<div className="d-flex flex-column">
									<div className="mf-item">
										<label>{Variable.USERNAME[ENV_NAME]}</label>
										<div className="mf-field">{userDetails?.username || "-"}</div>
									</div>
								</div>
							</div>
						</ProfileContainer>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		userDetails: state.auth.userDetails,
		ENV_NAME: state.auth.selectedEnvironment,
	};
};

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ProfileComponent));
