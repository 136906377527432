import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ActionTypes from '../../../store/actions/actionTypes';

class OfflineComponent extends React.Component {
    state = {
        isUserOnline: false
    };

    componentDidMount = () => {
        
        this.props.updateUserStatus(this.state.isUserOnline);
        
    }
    
    render() {
        return (
            <>
                {/* Its off line */}
            </>
        )
    }
}
const mapStateToProps = state => {
    return state;
};
const mapStateToDispatch = dispatch => {
    return {
        updateUserStatus: payload =>
            dispatch({ type: ActionTypes.UPDATE_USER_STATUS, payload: payload})
    };
};
export default connect(
    mapStateToProps,
    mapStateToDispatch,
)(withRouter(OfflineComponent));
