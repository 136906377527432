import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "../../../shared/component/Select";
import * as Variable from "../../../shared/utils/variables";
import * as RoutePath from "../../../shared/utils/routeLink";
import UnsavedChanges from "../../../shared/component/UnsavedChanges";
import FormCustomField from "./FormCustomField";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import { CUSTOM_FIELD_TYPE, RegistContext } from "../RegistrationComponent";
import { NoticeMessageContext } from "../../../App";

import "../RegistrationComponent.scss";
import isValidEmail from "../../../shared/utils/isValidEmail";

const ONCHANGE_TYPE = {
	DEFAULT: "default",
	OPTION: "option",
	RADIO: "radio",
	DATE: "date",
};
const TAB_NAME = "parent_guardian_information";
const TAB_ENUM = {
	STUDENT: "#student",
	PARENT: "#parent",
	DOCUMENT: "#document",
};

const ParentInfoTab = ({ ENV_NAME, isOpened, ...props }) => {
	const PARENT_RELATION_OPT = [
		{ id: "father", name: Variable.FATHER[ENV_NAME] },
		{ id: "mother", name: Variable.MOTHER[ENV_NAME] },
		{ id: "guardian", name: Variable.GUARDIAN[ENV_NAME] },
	];
	const { showNotice } = useContext(NoticeMessageContext);
	const { admissionId, batchId, applyId, registData, customFieldAnswer, admissionData, getFormData } =
		useContext(RegistContext);
	const [registDataTemp, setRegistDataTemp] = useState(null);
	const [hiddenFields, setHiddenFields] = useState(admissionData?.hidden_registration_form_fields);
	const [customFields, setCustomFields] = useState(null);
	const [customFieldsAnswerTemp, setCustomFieldsAnswerTemp] = useState(null);
	const [fieldsError, setFieldsError] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [provinceOpt, setProvinceOpt] = useState([]);
	const [cityOpt, setCityOpt] = useState([]);

	// If opened, we want to load the custom fields for this particular tab
	useEffect(() => {
		if (isOpened && admissionId) {
			getCustomFields();
		}
		if (!isOpened) {
			setIsEditing(false);
		}
	}, [isOpened, admissionId]); // eslint-disable-line

	// If opened and other supporting data is available
	// We want to load the custom fields answer
	useEffect(() => {
		if (isOpened && Array.isArray(customFieldAnswer) && Array.isArray(customFields)) {
			getCustomFieldsAnswer(customFields, customFieldAnswer);
		}
	}, [isOpened, customFieldAnswer, customFields]); // eslint-disable-line

	// For every form changes from parent, we update the data
	useEffect(() => {
		if (registData) {
			// We convert the varying father/mother/parent/guardian fields
			// into one unified key for editing purposes
			setRegistDataTemp({
				...registData,
				parent_name: registData?.father_name || registData?.mother_name || registData?.guardian_name || "",
				parent_occupation:
					registData?.father_occupation ||
					registData?.mother_occupation ||
					registData?.guardian_occupation ||
					"",
				parent_email: registData?.parent_email || registData?.guardian_email || "",
				parent_mobile: registData?.parent_mobile || registData?.guardian_mobile || "",
				parent_address_province:
					registData?.parent_address_province || registData?.guardian_address_province || "",
				parent_address_city: registData?.parent_address_city || registData?.guardian_address_city || "",
				parent_address: registData?.parent_address || registData?.guardian_address || "",
				parent_income_pm: registData?.parent_income_pm || registData?.guardian_income_pm || 0,
			});
		}
	}, [registData]);

	useEffect(() => {
		let hiddenFieldsTemp = admissionData?.hidden_registration_form_fields || [];
		let hideHomeAddress = hiddenFieldsTemp?.some((i) =>
			[
				"parent_address_province",
				"parent_address_city",
				"parent_address",
				"guardian_address_province",
				"guardian_address_city",
				"guardian_address",
			]?.includes(i)
		);
		let hideOccupation = hiddenFieldsTemp?.some((i) =>
			[
				"father_occupation",
				"mother_occupation",
				"parent_income_pm",
				"guardian_occupation",
				"guardian_income_pm",
			]?.includes(i)
		);
		setHiddenFields({
			parent_home_address: !hideHomeAddress,
			parent_guardian_occupation: !hideOccupation,
		});
		if (!hideHomeAddress) {
			// Get location listing
			getLocationListing();
		}
	}, [admissionData?.hidden_registration_form_fields]); // eslint-disable-line

	// Get the city for that particular city if the province is changed
	useEffect(() => {
		if (registDataTemp?.parent_address_province) {
			getLocationListing("city", registDataTemp?.parent_address_province);
		}
	}, [registDataTemp?.parent_address_province]); // eslint-disable-line

	const getCustomFields = async () => {
		const res = await makeRequest({
			...generateRequestOptions("getCustomForm", {
				queryParams: { school_admission: admissionId, reg_form_setting_tab: TAB_NAME },
			}),
		});
		if (res?.code === 200) {
			setCustomFields(res?.data);
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	// Get the custom field answers from the applicant (the answer is actually from the parent component)
	// This function will convert those answers into an object for faster access
	// Also, we only show the answer in this tab only
	const getCustomFieldsAnswer = (custField, custFieldAns) => {
		let custFieldObjTemp = {};
		let thisTabCustField = {};

		custField?.forEach((i) => {
			thisTabCustField = { ...thisTabCustField, [i?.id]: true };
		});

		// Change the data into a object for faster access
		// But first we want to filter only
		custFieldAns
			?.filter((i) => thisTabCustField?.[i?.custom_field])
			?.forEach((i) => {
				custFieldObjTemp = { ...custFieldObjTemp, [+i?.custom_field]: i };
			});

		setCustomFieldsAnswerTemp(custFieldObjTemp);
	};

	// Handler for onChange
	const onChangeHandler = (e, type = ONCHANGE_TYPE.DEFAULT) => {
		setIsEditing(true);
		if (type === ONCHANGE_TYPE.OPTION) {
			setRegistDataTemp((p) => ({ ...p, [e.name]: e.value?.id || "" }));
			return;
		}
		if (type === ONCHANGE_TYPE.DATE || type === ONCHANGE_TYPE.RADIO) {
			setRegistDataTemp((p) => ({ ...p, [e.name]: e.value }));
			return;
		}

		setRegistDataTemp((p) => ({ ...p, [e.target.name]: e.target.value }));
	};

	// Handler of onChange for Custom Fields
	const onCustomFieldAnswerChange = (id, type, value) => {
		setIsEditing(true);
		setCustomFieldsAnswerTemp((p) => {
			let currAns = p?.[id] || { custom_field: id, batch_application: applyId, school_admission: admissionId };
			switch (type) {
				case CUSTOM_FIELD_TYPE.TEXT:
					currAns = { ...currAns, text_answer: value };
					break;
				case CUSTOM_FIELD_TYPE.NUMBER:
					currAns = { ...currAns, number_answer: Number(value) || 0 };
					break;
				case CUSTOM_FIELD_TYPE.MULTIPLECHOICE:
					if (value.isAdd) {
						currAns = {
							...currAns,
							choice_based_answer: [value?.value],
						};
					} else {
						currAns = {
							...currAns,
							choice_based_answer: null,
						};
					}
					break;
				case CUSTOM_FIELD_TYPE.CHECKBOXES:
					if (value.isAdd) {
						currAns = {
							...currAns,
							choice_based_answer: [...(currAns?.choice_based_answer || []), value?.value],
						};
					} else {
						currAns = {
							...currAns,
							choice_based_answer: (currAns?.choice_based_answer || [])?.filter(
								(i) => i !== value?.value
							),
						};
					}
					break;
				case CUSTOM_FIELD_TYPE.DATE:
					currAns = { ...currAns, date_answer: value };
					break;
				default:
					break;
			}
			return { ...p, [+id]: currAns };
		});
	};

	// API to get provinces and cities
	const getLocationListing = async (type = null, loc_name = null) => {
		let queryP;
		if (type === "city") {
			queryP = {
				province_name: loc_name,
			};
		} else {
			queryP = { is_province: true };
		}
		const res = await makeRequest({
			...generateRequestOptions("locationDetails", {
				queryParams: queryP,
			}),
		});
		if (res?.code === 200) {
			let processed = res.data?.map((i) => ({
				id: i,
				name: i,
			}));
			if (type === "city") {
				setCityOpt(processed);
			} else {
				setProvinceOpt(processed);
			}
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	// API to validate, save and going to the next step
	const validateAndSave = async (destination = "next") => {
		setIsSaving(true);

		if (!registData?.id || !admissionId) {
			showNotice(Variable.CANT_GET_DATA[ENV_NAME], "error");
			return;
		}

		// Step 1 Validating Mandatory Fields
		if (
			!registDataTemp?.student_registrar_relation ||
			!registDataTemp?.parent_name ||
			(hiddenFields?.parent_home_address &&
				(!registDataTemp?.parent_address_province ||
					!registDataTemp?.parent_address_city ||
					!registDataTemp?.parent_address)) ||
			(hiddenFields?.parent_guardian_occupation &&
				(!registDataTemp?.parent_occupation ||
					isNaN(registDataTemp?.parent_income_pm) ||
					registDataTemp?.parent_income_pm === "")) ||
			!registDataTemp?.parent_email ||
			!isValidEmail(registDataTemp?.parent_email) ||
			!registDataTemp?.parent_mobile || 
			registDataTemp?.parent_income_pm < 0 ||
			registDataTemp?.parent_mobile < 0
		) {
			setFieldsError(true);
			setIsSaving(false);
			showNotice(Variable.FILL_ALL_REQUIRED_LABEL[ENV_NAME], "error");
			return;
		}

		// Step 2 Validating Custom Fields
		let customFieldError = false;
		customFields?.forEach((i) => {
			let ans = customFieldsAnswerTemp?.[i?.id];
			if (i?.is_mandatory) {
				let hasAns = false;
				switch (i?.field_type) {
					case CUSTOM_FIELD_TYPE.TEXT:
						hasAns = Boolean(ans?.text_answer ?? null);
						break;
					case CUSTOM_FIELD_TYPE.NUMBER:
						hasAns = Boolean(ans?.number_answer ?? null);
						break;
					case CUSTOM_FIELD_TYPE.DATE:
						hasAns = Boolean(ans?.date_answer ?? null);
						break;
					case CUSTOM_FIELD_TYPE.CHECKBOXES:
					case CUSTOM_FIELD_TYPE.MULTIPLECHOICE:
						hasAns = ans?.choice_based_answer?.length > 0;
						break;
					default:
						break;
				}

				if (i?.is_mandatory && !hasAns) {
					setFieldsError(true);
					showNotice(Variable.FILL_ALL_REQUIRED_LABEL[ENV_NAME], "error");
					customFieldError = true;
					setIsSaving(false);
					return;
				}
				if (
					i?.is_mandatory &&
					hasAns &&
					(!ans?.custom_field || !ans?.batch_application || !ans?.school_admission)
				) {
					setFieldsError(true);
					showNotice(Variable.CANT_GET_DATA[ENV_NAME], "error");
					customFieldError = true;
					setIsSaving(false);
					return;
				}
			}
		});
		if (customFieldError) return;

		// Mandatory saving
		// We need to convert the sent data first according to the relation
		let sentRegistData = {
			student_registrar_relation: registDataTemp?.student_registrar_relation,
			father_name: "",
			father_occupation: "",
			mother_name: "",
			mother_occupation: "",
			parent_email: "",
			parent_mobile: "",
			parent_address_province: "",
			parent_address_city: "",
			parent_address: "",
			parent_income_pm: null,
			guardian_name: "",
			guardian_occupation: null,
			guardian_email: "",
			guardian_mobile: null,
			guardian_address_province: null,
			guardian_address_city: null,
			guardian_address: null,
			guardian_income_pm: null,
		};

		switch (sentRegistData?.student_registrar_relation) {
			case "father":
				sentRegistData = {
					...sentRegistData,
					father_name: registDataTemp?.parent_name,
					father_occupation: registDataTemp?.parent_occupation,
					parent_email: registDataTemp?.parent_email,
					parent_mobile: registDataTemp?.parent_mobile,
					parent_address_province: registDataTemp?.parent_address_province,
					parent_address_city: registDataTemp?.parent_address_city,
					parent_address: registDataTemp?.parent_address,
					parent_income_pm: registDataTemp?.parent_income_pm,
				};
				break;
			case "mother":
				sentRegistData = {
					...sentRegistData,
					mother_name: registDataTemp?.parent_name,
					mother_occupation: registDataTemp?.parent_occupation,
					parent_email: registDataTemp?.parent_email,
					parent_mobile: registDataTemp?.parent_mobile,
					parent_address_province: registDataTemp?.parent_address_province,
					parent_address_city: registDataTemp?.parent_address_city,
					parent_address: registDataTemp?.parent_address,
					parent_income_pm: registDataTemp?.parent_income_pm,
				};
				break;
			case "guardian":
				sentRegistData = {
					...sentRegistData,
					guardian_name: registDataTemp?.parent_name,
					guardian_occupation: registDataTemp?.parent_occupation,
					guardian_email: registDataTemp?.parent_email,
					guardian_mobile: registDataTemp?.parent_mobile,
					guardian_address_province: registDataTemp?.parent_address_province,
					guardian_address_city: registDataTemp?.parent_address_city,
					guardian_address: registDataTemp?.parent_address,
					guardian_income_pm: registDataTemp?.parent_income_pm,
				};
				break;
			default:
				break;
		}

		let hasError = false;
		const resMandatory = await makeRequest({
			...generateRequestOptions("saveRegistData", { urlParams: registData?.id }),
			body: sentRegistData,
			json: true,
		});
		if (resMandatory?.code === 200) {
			// Do nothing
		} else {
			showNotice(resMandatory?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
			hasError = true;
			setIsSaving(false);
			return;
		}

		// Custom field saving
		// Step 1 determine which one is new which one is existing
		let newData = Object.values(customFieldsAnswerTemp || {})?.filter((i) => !i?.id);
		let existingData = Object.values(customFieldsAnswerTemp || {})?.filter((i) => i?.id);

		// Create new results (from existing)
		if (newData?.length > 0) {
			const resCustFieldNew = await makeRequest({
				...generateRequestOptions("createCustomFormAnswer"),
				body: newData,
				json: true,
			});
			if (resCustFieldNew?.code === 200) {
				// Do nothing
			} else {
				showNotice(resCustFieldNew?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
				hasError = true;
				setIsSaving(false);
				return;
			}
		}

		// Edit bulk new results (from existing)
		if (existingData?.length > 0) {
			const resCustFieldExisting = await makeRequest({
				...generateRequestOptions("editCustomFormAnswer"),
				body: existingData,
				json: true,
			});
			if (resCustFieldExisting?.code === 200) {
				// Do nothing
			} else {
				showNotice(resCustFieldExisting?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
				hasError = true;
				setIsSaving(false);
				return;
			}
		}

		if (!hasError) {
			setIsEditing(false);
			await getCustomFields();
			await getFormData(admissionId);
		}
		setIsSaving(false);
		if (!hasError) {
			// Sadly we need to use timeout for this one
			setTimeout(() => {
				if (destination === "next") {
					props.history.push(
						RoutePath.APPLY_BATCH + `/${admissionId}/${batchId}/${applyId}` + TAB_ENUM.DOCUMENT
					);
				} else if (destination === "previous") {
					props.history.push(
						RoutePath.APPLY_BATCH + `/${admissionId}/${batchId}/${applyId}` + TAB_ENUM.STUDENT
					);
				}
			}, 200);
		}
	};

	return (
		<div className="h-100 d-flex flex-column general-form regist-form">
			<div id="form-scroll-parent" className="flex-grow-1 mh-0 overflow-auto">
				<div className="regist-form-container">
					<h5 className="regist-form-header">{Variable.PARENT_GUARDIAN_NAME[ENV_NAME]}</h5>
					<div className="regist-form-row">
						<div className="form-group">
							<label>{Variable.FULL_NAME[ENV_NAME]}</label>
							<input
								className={`form-control ${
									fieldsError && !registDataTemp?.parent_name ? "red-border" : ""
								}`}
								type="text"
								name="parent_name"
								value={registDataTemp?.parent_name || ""}
								placeholder={Variable.ENTER[ENV_NAME]}
								onChange={onChangeHandler}
								disabled={isSaving}
							/>
						</div>
						<div className="form-group"></div>
					</div>
				</div>
				<div className="regist-form-container">
					<h5 className="regist-form-header">{Variable.PARENT_GUARDIAN_RELATION[ENV_NAME]}</h5>
					<div className="regist-form-row">
						<div className="form-group">
							<label>{Variable.RELATION_WITH_PARENT_GUARDIAN[ENV_NAME]}</label>
							<Select
								className={`select-filter form-control ${
									fieldsError && !registDataTemp?.student_registrar_relation ? "red-border" : ""
								}`}
								value={{
									id: registDataTemp?.student_registrar_relation || "",
									name: registDataTemp?.student_registrar_relation || "",
								}}
								onChangeItem={(e) =>
									onChangeHandler(
										{ name: "student_registrar_relation", value: e },
										ONCHANGE_TYPE.OPTION
									)
								}
								items={PARENT_RELATION_OPT || []}
								disabled={isSaving}
							/>
						</div>
						<div className="form-group"></div>
					</div>
				</div>
				{hiddenFields?.parent_home_address && (
					<div className="regist-form-container">
						<h5 className="regist-form-header">{Variable.HOME_ADDRESS[ENV_NAME]}</h5>
						<div className="regist-form-row">
							<div className="regist-form-row flex-column w-100">
								<div className="form-group">
									<label>{Variable.PROVINCE[ENV_NAME]}</label>
									<Select
										name="parent_address_province"
										className={`select-filter form-control ${
											fieldsError &&
											hiddenFields?.parent_home_address &&
											!registDataTemp?.parent_address_province
												? "red-border"
												: ""
										}`}
										value={{
											id: registDataTemp?.parent_address_province || "",
											name: registDataTemp?.parent_address_province || "",
										}}
										onChangeItem={(e) => {
											onChangeHandler(
												{ name: "parent_address_province", value: e },
												ONCHANGE_TYPE.OPTION
											);
											setCityOpt([]);
										}}
										items={provinceOpt || []}
										disabled={provinceOpt?.length === 0 || isSaving}
									/>
								</div>
								<div className="form-group">
									<label>{Variable.CITY_REGENCY[ENV_NAME]}</label>
									<Select
										name="parent_address_city"
										className={`select-filter form-control ${
											fieldsError &&
											hiddenFields?.parent_home_address &&
											!registDataTemp.parent_address_city
												? "red-border"
												: ""
										}`}
										value={{
											id: registDataTemp?.parent_address_city || "",
											name: registDataTemp?.parent_address_city || "",
										}}
										onChangeItem={(e) =>
											onChangeHandler(
												{ name: "parent_address_city", value: e },
												ONCHANGE_TYPE.OPTION
											)
										}
										items={cityOpt || []}
										disabled={
											!registDataTemp?.parent_address_province ||
											cityOpt?.length === 0 ||
											isSaving
										}
									/>
								</div>
							</div>
							<div className="form-group d-flex flex-column">
								<label>{Variable.FULL_ADDRESS[ENV_NAME]}</label>
								<textarea
									name="parent_address"
									className={`form-control flex-grow-1 ${
										fieldsError &&
										hiddenFields?.parent_home_address &&
										!registDataTemp.parent_address
											? "red-border"
											: ""
									}`}
									placeholder={Variable.ENTER[ENV_NAME]}
									value={registDataTemp?.parent_address || ""}
									onChange={onChangeHandler}
									disabled={isSaving}
								/>
							</div>
						</div>
					</div>
				)}
				{hiddenFields?.parent_guardian_occupation && (
					<div className="regist-form-container">
						<h5 className="regist-form-header">{Variable.PARENT_GUARDIAN_OCCUPATION[ENV_NAME]}</h5>
						<div className="regist-form-row">
							<div className="form-group">
								<label>{Variable.OCCUPATION[ENV_NAME]}</label>
								<input
									className={`form-control ${
										fieldsError && !registDataTemp?.parent_occupation ? "red-border" : ""
									}`}
									type="text"
									name="parent_occupation"
									value={registDataTemp?.parent_occupation || ""}
									placeholder={Variable.ENTER[ENV_NAME]}
									onChange={onChangeHandler}
									disabled={isSaving}
								/>
							</div>
							<div className="form-group">
								<label>{Variable.INCOME[ENV_NAME]}</label>
								<input
									className={`form-control ${
										fieldsError &&
										(isNaN(registDataTemp?.parent_income_pm) ||
											registDataTemp?.parent_income_pm === "" ||
											registDataTemp?.parent_income_pm < 0 
											)
											? "red-border"
											: ""
									}`}
									type="number"
									name="parent_income_pm"
									value={
										isNaN(registDataTemp?.parent_income_pm)
											? 0
											: registDataTemp?.parent_income_pm || 0
									}
									min="0"
									placeholder={Variable.ENTER[ENV_NAME]}
									onChange={onChangeHandler}
									disabled={isSaving}
								/>
							</div>
						</div>
					</div>
				)}
				<div className="regist-form-container">
					<h5 className="regist-form-header">{Variable.PARENT_GUARDIAN_CONTACT[ENV_NAME]}</h5>
					<div className="regist-form-row">
						<div className="form-group">
							<label>{Variable.EMAIL_ID[ENV_NAME]}</label>
							<input
								className={`form-control ${
									fieldsError &&
									(!registDataTemp?.parent_email || !isValidEmail(registDataTemp?.parent_email))
										? "red-border"
										: ""
								}`}
								type="email"
								name="parent_email"
								value={registDataTemp?.parent_email || ""}
								placeholder={Variable.ENTER[ENV_NAME]}
								onChange={onChangeHandler}
								disabled={isSaving}
							/>
						</div>
						<div className="form-group">
							<label>{Variable.PHONE_NUMBER[ENV_NAME]}</label>
							<input
								className={`form-control ${
									(fieldsError && (!registDataTemp?.parent_mobile || registDataTemp?.parent_mobile < 0)) ? "red-border" : ""
								}`}
								type="number"
								name="parent_mobile"
								min="0"
								max="13"
								value={registDataTemp?.parent_mobile || ""}
								placeholder={Variable.ENTER[ENV_NAME]}
								onChange={onChangeHandler}
								disabled={isSaving}
							/>
						</div>
					</div>
				</div>
				{customFields?.map((i, key) => (
					<FormCustomField
						key={"custom-field-" + key}
						idx={key}
						id={i?.id}
						title={i?.field_title}
						type={i?.field_type}
						instructions={i?.field_question}
						mandatory={i?.is_mandatory}
						options={i?.field_choices}
						isError={fieldsError}
						answer={customFieldsAnswerTemp?.[+i?.id]}
						onAnswerChange={onCustomFieldAnswerChange}
					/>
				))}
			</div>
			<div className="d-flex align-items-center mt-3">
				<button
					className="btn btn-cancel ml-auto font-weight-semibold py-3 px-4"
					onClick={() => validateAndSave("previous")}
				>
					{Variable.PREVIOUS_LABEL[ENV_NAME]}
				</button>
				<button
					className="btn btn-primary ml-5 font-weight-semibold py-3 px-4"
					onClick={() => validateAndSave()}
					disabled={isSaving}
				>
					{Variable.NEXT_LABEL[ENV_NAME]}
				</button>
			</div>
			<UnsavedChanges when={isEditing || isSaving} message={Variable.UNSAVED_CHANGES[ENV_NAME]} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
		academicYear: state.auth.academicYear,
	};
};
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ParentInfoTab));
