import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdmissionIcon from "../../../assets/svg/admission-image.svg";
import * as Variable from "../../../shared/utils/variables";
import Image from "../../../shared/component/UI/Image";

import "../MainPageComponent.scss";

/*
  This component contains the image and label for the left side of the login page
*/
const LeftSide = ({ ENV_NAME, ...props }) => {
	return (
		<div className="main-page-left-side mb-0">
			<h3 className="admission-title">{Variable.NEW_STUDENT_ADMISSION[ENV_NAME]}</h3>
			<Image src={AdmissionIcon} className="admission-icon my-4" alt="Admission Icon" />
			<h5 className="admission-subtitle">{Variable.PLEASE_LOGIN_LONE[ENV_NAME]}</h5>
			<h5 className="admission-subtitle">{Variable.PLEASE_LOGIN_LTWO[ENV_NAME]}</h5>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment,
});
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(LeftSide));
