import React, { useEffect, useState, createContext } from "react";
import Router from "./Route";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGa from "react-ga";
toast.configure({ draggable: false, hideProgressBar: true, position: toast.POSITION.TOP_RIGHT });

export const NoticeMessageContext = createContext();
let noticeTimeout = null;

function App() {
	const [noticeMessage, setNoticeMessage] = useState({
		show: false,
		type: null,
		message: "",
	});
	const showNotice = (message, type = "success", timeout = 5000) => {
		setNoticeMessage({
			show: true,
			type: type,
			message,
		});
        clearTimeout(noticeTimeout);
		noticeTimeout = setTimeout(() => {
			setNoticeMessage({
				show: false,
				type: null,
				message: "",
			});
		}, timeout);
	};

	useEffect(() => {
		ReactGa.initialize("UA-220083682-2");
    }, []);

	return (
        <React.Fragment>
            <NoticeMessageContext.Provider value={{ showNotice }}>
                <Router />
            </NoticeMessageContext.Provider>
            <ToastContainer draggable={false} hideProgressBar={true} position={toast.POSITION.TOP_RIGHT} />
            {noticeMessage?.show && (
                <div className={`snackbar-bottom-notice ${noticeMessage?.type === "error" ? "errorMessage" : ""}`}>
                    <div className="mr-4">{noticeMessage?.message}</div>
                    <i
                        className="fa fa-times cursorPointer"
                        onClick={() => {
                            setNoticeMessage({
                                show: false,
                                type: null,
                                message: "",
                            });
                        }}
                    ></i>
                </div>
            )}
        </React.Fragment>
    );
}

export default App;
