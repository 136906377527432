//converting camelCase to startCase
export const startCase = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase());

export const classHelper = (...classes) => {
  const newClass = classes
    .map((cls) => {
      if (
        (typeof cls === "object" || typeof cls === "function") &&
        cls !== null
      ) {
        const filteredClasses = Object.keys(cls).filter(
          (condCls) => cls[condCls]
        );
        if (filteredClasses.length > 0) {
          return filteredClasses.join(" ");
        }
        return null;
      }
      return cls;
    })
    .filter((cls) => typeof cls === "string" && cls.trim().length > 0);
  return newClass.length > 0 ? newClass.join(" ") : "";
};

export const uniqueKeyId = (t = 21) => {
  let e = "",
    r = crypto.getRandomValues(new Uint8Array(t));
  for (; t--; ) {
    let n = 63 & r[t];
    e +=
      n < 36
        ? n.toString(36)
        : n < 62
        ? (n - 26).toString(36).toUpperCase()
        : n < 63
        ? "_"
        : "-";
  }
  return e;
};

export const isValidUsername = (str) => {
	const regex = /^[\w.@+-]+$/;
	return regex.test(str);
}
