import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NoContentComponent from "../NoContentComponent/NoContentComponent";
import * as Variable from "../../shared/utils/variables";
import "./NotFoundPage.scss";

const NotFoundPage = ({ ENV_NAME, ...props }) => {
	return (
		<div className="not-found-page">
			<NoContentComponent
				title={<h1 className="font-weight-bold">{Variable.PAGE_404[ENV_NAME]}</h1>}
				description={<h5>{Variable.PAGE_NOT_FOUND[ENV_NAME]}</h5>}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment,
});
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(NotFoundPage));
