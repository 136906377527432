import React, { useRef, useEffect, useState } from "react";
import { homeUrl } from "../../shared/utils/apiEndPoints";
import ChangeLanguageModal from "../../shared/component/ChangeLanguageModal/ChangeLanguageModal";
import * as Variable from "../../shared/utils/variables";
import * as RoutePath from "../../shared/utils/routeLink";
import { ReactComponent as MenuIcon } from "../../assets/svg/menuicon.svg";
import { ReactComponent as MobileLogo } from "../../assets/svg/logo.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { ReactComponent as NotificationIcon } from "../../assets/svg/notification.svg";
import cookies from "react-cookies";
import Release from "../../shared/component/Release";
import { useHistory } from "react-router-dom";

const determineInitial = (userDetails) => {
	const name = `${userDetails?.first_name?.charAt(0)}${userDetails?.last_name?.charAt(0)}`;
	return (name || "")?.toUpperCase();
};

export const Navigation = ({
	isActiveComponent,
	userDetails,
	ENV_NAME,
	changeLanguage,
	logout,
	goToDashboard,
	isMobile,
	f,
}) => {
	const history = useHistory();
	const [openLanguage, setOpenLanguage] = useState(false);
	const [mobileMenu, setMobileMenu] = useState(false);
	const [listOfMenus, setListOfMenus] = useState([
		{
			content: "",
			icon: "icon-home",
			mobileIcon: "",
			route: RoutePath.DASHBOARD,
			goto: () => goToDashboard(),
			style: {
				backgroundImage: `url("https://dian-media-preloaded-jakarta.s3.ap-southeast-3.amazonaws.com/img/Logo%20NU.png")`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "100% auto",
				backgroundPosition: "center",
				width: "80%",
			},
		},
		{
			content: Variable.DASHBOARD,
			icon: "icon-dashboard",
			mobileIcon: "mobile-icon-dashboard",
			route: [RoutePath.DASHBOARD, RoutePath.APPLY_BATCH],
			goto: () => goToDashboard(),
		},
		{
			content: Variable.PROFILE,
			icon: "avatar-left",
			mobileIcon: "",
			route: [RoutePath.PROFILE],
			goto: () => {
				redirectHandler(RoutePath.PROFILE);
			},
			hasChildren: true,
			isChildrenListOpen: false,
			childrenList: [
				{
					id: "profile",
					icon: "",
					goTo: () => redirectHandler(RoutePath.PROFILE),
					isHighlight: true,
					content: Variable.PROFILE,
				},
				{
					id: "language",
					icon: "icon-language",
					goTo: () => {
						setOpenLanguage(true);
						_setProfileMenu(false);
					},
					isHighlight: false,
					content: Variable.LANGUAGE_LBL,
				},
			],
		},
		{
			content: Variable.LOGOUT_LBL,
			icon: "icon-logout",
			mobileIcon: "mobile-icon-logout",
			route: [],
			goto: () => logout(),
		},
	]);
	const profileRef = useRef();
	const containerRef = useRef();
	const _setProfileMenu = (data, icon = null) => {
		if (!icon && !data) {
			let temp = listOfMenus;
			setListOfMenus(() => []);
			temp = temp.map((key) => {
				key = {
					...key,
					isChildrenListOpen: false,
				};
				return key;
			});
			setListOfMenus(() => temp);
		} else if (icon) {
			let temp = listOfMenus;
			setListOfMenus(() => []);
			temp = temp.map((key) => {
				if (key.icon === icon) {
					key = {
						...key,
						isChildrenListOpen: !key.isChildrenListOpen,
					};
				} else {
					key = {
						...key,
						isChildrenListOpen: false,
					};
				}
				return key;
			});

			setListOfMenus(() => temp);
		}
	};
	const redirectHandler = (name) => {
		history.push(name);
		_setProfileMenu(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				profileRef.current &&
				!profileRef.current.contains(event.target) &&
				!containerRef.current.contains(event.target)
			) {
				_setProfileMenu(false);
			}
		};

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileRef]);

	const renderModals = () => {
		return (
			<>
				{openLanguage && (
					<ChangeLanguageModal
						show={openLanguage}
						onAction={async (action) => {
							if (action) {
								let val = action;
								await cookies.save("lang", val, {
									domain: homeUrl,
									path: "/",
									expires: new Date("9999-12-31T12:00:00.000Z"),
								});
								await changeLanguage(val);
								window.location.reload();
							}
							setOpenLanguage(false);
						}}
					/>
				)}
			</>
		);
	};

	const generateSubMenu = (children) => {
		if (children.id === "profile") {
			return (
				!children?.hide && (
					<button
						key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
						className={`profile-item divider ${children.isHighlight && "highlight"}`}
						onClick={children.goTo}
					>
						<div className="profile-left">
							<div className="circleImageBorder mb-0 mr-3">{determineInitial(userDetails)}</div>
							<div className="info">
								<div className="font-weight-semibold text-label-dark-gray-2">
									{userDetails?.first_name || ""} {userDetails?.last_name || ""}
								</div>
								<div>{userDetails?.email || ""}</div>
							</div>
						</div>
					</button>
				)
			);
		}
		if (children.id === "language" || children.id === "academic") {
			return (
				!children?.hide && (
					<Release
						to={children.release}
						validFor={children.validFor}
						key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
					>
						<button
							key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
							className={`profile-item ${children.isHighlight && "highlight"}`}
							onClick={children.goTo}
							disabled={children.id === "academic" && userDetails?.school_academic_years?.length === 0}
						>
							<div className="profile-left pr-0">
								{children.icon && <div className={`pr-icon ${children.icon}`}></div>}
								<span>{children.content[ENV_NAME] || children.content}</span>
								{children.id === "language" ? (
									<div className="ml-auto text-language">
										{ENV_NAME === "eng" ? "English" : "Indonesia"}
									</div>
								) : (
									""
								)}
							</div>
						</button>
					</Release>
				)
			);
		}
		return (
			!children?.hide && (
				<Release
					to={children.release}
					validFor={children.validFor}
					key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
				>
					<button
						key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
						className={`profile-item ${children.isHighlight && "highlight"} ${
							children.id === "setting" && "divider"
						}`}
						onClick={children.goTo}
					>
						<div className="profile-left">
							{children.icon && <div className={`pr-icon ${children.icon}`}></div>}
							<span>{children.content[ENV_NAME] || children.content}</span>
						</div>
					</button>
				</Release>
			)
		);
	};

	const generateSidebar = ({
		content,
		icon,
		route,
		goto,
		style,
		release,
		validFor,
		hide,
		hasChildren = null,
		isChildrenListOpen = false,
		childrenList = [],
	}) => {
		return (
			!hide && (
				<Release to={release} validFor={validFor} key={`${icon} ${content[ENV_NAME]}`}>
					<div className={`w-100 position-relative ${icon === "avatar-left" ? "mt-auto topborder" : ""}`}>
						<li
							className={
								icon === "avatar-left"
									? (typeof isActiveComponent === "string" &&
											typeof route === "object" &&
											route.length > 0 &&
											route.indexOf(isActiveComponent) > -1) ||
									  isChildrenListOpen
										? "menu-item avatar-left active-menu"
										: "menu-item avatar-left"
									: typeof route === "string"
									? isActiveComponent === route || isChildrenListOpen
										? `menu-item ${icon} active`
										: `menu-item ${icon}`
									: (typeof isActiveComponent === "string" &&
											typeof route === "object" &&
											route.length > 0 &&
											route.indexOf(isActiveComponent) > -1) ||
									  isChildrenListOpen
									? `menu-item ${icon} active`
									: `menu-item ${icon}`
							}
							onClick={(event) => {
								if (hasChildren) {
									_setProfileMenu(!isChildrenListOpen, icon);
								} else {
									goto();
								}
							}}
							style={
								(icon !== "avatar-left" &&
									homeUrl === "portalsekolah.co.id" &&
									userDetails?.school_id === 1032) ||
								(homeUrl !== "portalsekolah.co.id" && userDetails?.school_id === 3)
									? style
									: undefined
							}
						>
							{icon === "avatar-left" ? (
								<div className="positionRelative flex-all-center">
									<div className="circleImageBorder">{determineInitial(userDetails)}</div>
									<i className="fa fa-chevron-right arrow-icon"></i>
								</div>
							) : (
								<div className="menu-icon">
									{hasChildren && <i className="fa fa-chevron-right arrow-icon"></i>}
								</div>
							)}
							<div
								className="menu-label"
								style={{
									...(content[ENV_NAME] === Variable.COMMUNICATION[ENV_NAME]
										? { fontSize: 10, marginTop: 5 }
										: {}),
								}}
							>
								{content[ENV_NAME]}
							</div>
							<div className="side-accent"></div>
						</li>
						{isChildrenListOpen && hasChildren && (
							<div className="profile-menu" ref={profileRef}>
								{childrenList.map((children) => generateSubMenu(children))}
								<div className="triangle"></div>
							</div>
						)}
					</div>
				</Release>
			)
		);
		// }
	};

	const generateMobileSubMenu = (children) => {
		if (children.id === "profile")
			return (
				!children?.hide && (
					<div
						key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
						className="mobile-profile-item"
						onClick={() => {
							setMobileMenu(false);
							children.goTo();
						}}
					>
						<div className="profile-left pl-0">
							<div className="circleImageBorder text-label-dark-gray-2 mr-3">
								{determineInitial(userDetails)}
							</div>
							<div className="info">
								<div className="font-weight-semibold text-label-dark-gray-2">
									{userDetails?.first_name || ""} {userDetails?.last_name || ""}
								</div>
								<div>{userDetails?.email || ""}</div>
							</div>
						</div>
					</div>
				)
			);
		if (children.id === "language" || children.id === "academic")
			return (
				!children?.hide && (
					<Release
						to={children.release}
						validFor={children.validFor}
						key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
					>
						<div
							key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
							className="mobile-profile-item"
							onClick={() => {
								setMobileMenu(false);
								children.goTo(true);
							}}
						>
							<div className="profile-left">
								<div className={`pr-icon ${children.icon}`}></div>
								<span>{children.content[ENV_NAME]}</span>
								{children.id === "language" && (
									<div className="ml-auto mr-1 text-language">
										{ENV_NAME === "eng" ? "English" : "Indonesia"}
									</div>
								)}
							</div>
						</div>
					</Release>
				)
			);

		return (
			!children?.hide && (
				<Release
					to={children.release}
					validFor={children.validFor}
					key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
				>
					<div
						key={`${children.mobileIcon} ${children.content[ENV_NAME]}`}
						className="mobile-profile-item"
						onClick={() => {
							setMobileMenu(false);
							children.goTo();
						}}
					>
						<div className="profile-left">
							<div className={`pr-icon ${children.icon}`}></div>
							<span>{children.content[ENV_NAME]}</span>
						</div>
					</div>
				</Release>
			)
		);
	};
	const generateMobileSidebar = ({
		content,
		icon,
		mobileIcon,
		route,
		goto,
		style,
		release,
		validFor,
		hide,
		hasChildren = null,
		isChildrenListOpen = false,
		childrenList = [],
	}) => {
		if (icon === "notification" || icon === "icon-home") {
			return null;
		}

		return (
			!hide && (
				<Release to={release} validFor={validFor} key={`${mobileIcon} ${content[ENV_NAME]}`}>
					<div
						className={
							!hasChildren
								? `mobile-menu-item ${mobileIcon}`
								: isChildrenListOpen
								? `mobile-menu-item mobile-active-menu  ${mobileIcon}`
								: `mobile-menu-item  ${mobileIcon}`
						}
						onClick={() => {
							if (hasChildren) _setProfileMenu(!isChildrenListOpen, icon);
							else {
								setMobileMenu(false);
								goto();
							}
						}}
					>
						{icon === "avatar-left" ? (
							<div className="profile-image">{determineInitial(userDetails)}</div>
						) : (
							<div className="menu-icon"></div>
						)}
						<div className="mobile-menu-label">{content[ENV_NAME]}</div>
					</div>

					{hasChildren && isChildrenListOpen && (
						<div className="mobile-profile-menu">
							{childrenList.map((children) => generateMobileSubMenu(children))}
						</div>
					)}
				</Release>
			)
		);
	};

	if (!isMobile) {
		return (
			<ul className="block-menu" ref={containerRef}>
				{listOfMenus?.length > 0 && listOfMenus.map((item) => generateSidebar(item))}

				{renderModals()}
			</ul>
		);
	} else {
		return (
			<>
				<div className="mobile-header">
					<div className="iconDiv" onClick={() => setMobileMenu((prev) => !prev)}>
						{!mobileMenu ? <MenuIcon /> : <Close />}
					</div>
					<div onClick={() => goToDashboard()}>
						<MobileLogo />
					</div>
					<div>
						<NotificationIcon style={{ visibility: "hidden" }} />
					</div>
				</div>
				{mobileMenu && (
					<div className="mobile-menu">
						{listOfMenus?.length > 0 && listOfMenus.map((item) => generateMobileSidebar(item))}
					</div>
				)}
				{renderModals()}
			</>
		);
	}
};
