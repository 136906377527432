import React, { Component } from "react";
import cookies from "react-cookies";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions, homeUrl } from "../../shared/utils/apiEndPoints";
import moment from "moment";
import "moment/locale/id";
import { withRouter } from "react-router-dom";
import * as RoutePath from "../../shared/utils/routeLink";
import * as ActionTypes from "../../store/actions/actionTypes";
import { connect } from "react-redux";
import * as Variable from "../../shared/utils/variables";
import "./MainComponent.scss";
import { toast } from "react-toastify";
import LoadingComponent from "../../component/LoadingComponent/LoadingComponent";
import ReactGa from "react-ga";
import { Detector } from "react-detect-offline";
import { Helmet } from "react-helmet";
import { Navigation } from "./Navigation";
import { isEqual } from "lodash";
import OfflineComponent from "../../shared/component/OfflineComponent/OfflineComponent";
import OnlineComponent from "../../shared/component/OnlineComponent/OnlineComponent";
import NotFoundPage from "../../component/NotFoundPage/NotFoundPage";
import MainPageComponent from "../../component/MainPageComponent/MainPageComponent";
import DashboardComponent from "../../component/DashboardComponent/DashboardComponent";
import ProfileComponent from "../../component/ProfileComponent/ProfileComponent";
import RegistrationComponent from "../../component/RegistrationComponent/RegistrationComponent";

const getActiveComp = (name, isAuthenticated = false) => {
	if (name && name?.includes(RoutePath.APPLY_BATCH)) {
		name = RoutePath.APPLY_BATCH;
	}

	switch (name) {
		case RoutePath.DASHBOARD: {
			if (isAuthenticated) {
				return {
					activeComponent: DashboardComponent,
					isActiveComponent: RoutePath.DASHBOARD,
				};
			}
			return {
				activeComponent: NotFoundPage,
				isActiveComponent: null,
			};
		}
		case RoutePath.ROOT: {
			if (isAuthenticated) {
				return {
					activeComponent: DashboardComponent,
					isActiveComponent: RoutePath.DASHBOARD,
				};
			}
			return {
				activeComponent: NotFoundPage,
				isActiveComponent: null,
			};
		}
		case RoutePath.PROFILE: {
			return {
				activeComponent: ProfileComponent,
				isActiveComponent: RoutePath.PROFILE,
			};
		}
		case RoutePath.APPLY_BATCH: {
			return {
				activeComponent: RegistrationComponent,
				isActiveComponent: RoutePath.APPLY_BATCH,
			};
		}
		default:
			return {
				activeComponent: MainPageComponent,
				isActiveComponent: name,
			};
	}
};
class MainComponent extends Component {
	ENV_NAME = this.props.auth.selectedEnvironment ? this.props.auth.selectedEnvironment : "bhs";

	state = {
		activeComponent: LoadingComponent,
		isActiveComponent: null,
		currentTimeStamp: Date.now(),
	};

	profileRef = React.createRef();
	academicRef = React.createRef();

	profileClickOutside = async (event) => {
		if (this.profileRef && !this.profileRef.current?.contains(event.target) && this.state.profileMenu) {
			this.setState({
				profileMenu: false,
			});
		}
	};

	academicClickOutside = async (event) => {
		if (this.academicRef && !this.academicRef.current?.contains(event.target) && this.state.academicMenu) {
			this.setState({
				academicMenu: false,
			});
		}
	};

	isValidAuthToken = () => {
		let tokenData = JSON.parse(atob(cookies.load("applicantauthtoken").split(".")[1]));
		let checkUserId = tokenData.user_id === this.props.auth.userDetails?.id;
		let checkCookie = tokenData
			? tokenData.exp * 1000 + 9000000 - 300000 - Date.now(this.state.currentTimeStamp) > 0
				? true
				: false
			: false;
		let data = cookies.load("applicanttokenexpiry");
		if (data - Date.now() > 0 && checkCookie && checkUserId) {
			return true;
		} else {
			return false;
		}
	};

	startTimer = () => {
		let data = cookies.load("applicanttokenexpiry");
		let timeDiff = data - Date.now(this.state.currentTimeStamp);
		if (timeDiff > 0) {
			setTimeout(() => {
				this.requestNewAccessToken();
			}, timeDiff);
		} else {
			this.logout();
		}
	};

	isValidRefreshToken = () => {
		let tokenData = JSON.parse(atob(cookies.load("applicantrefreshtoken").split(".")[1]));
		let checkUserId = tokenData.user_id === this.props.auth.userDetails?.id;
		if (checkUserId) {
			return true;
		} else {
			return false;
		}
	};

	requestNewAccessToken = async () => {
		if (this.props.auth.isAuthenticated) {
			const formData = new FormData();
			formData.append("refresh", cookies.load("applicantrefreshtoken"));
			fetch(
				{
					...generateRequestOptions("getNewAccessToken"),
				}.url,
				{
					method: "POST",
					body: formData,
				}
			)
				.then(async (response) => {
					// console.log(response);
					const res = await response.json();

					if (res.access) {
						await cookies.save("applicantauthtoken", res.access, {
							domain: homeUrl,
							path: "/",
							expires: new Date("9999-12-31T12:00:00.000Z"),
							// httpOnly:true
						});
						// let tokenData = JSON.parse(atob(res.access.split(".")[1]));
						let timerData = Date.now(res?.data?.current_timestamp);
						await cookies.save("applicanttokenexpiry", timerData + 870000, {
							domain: homeUrl,
							path: "/",
							expires: new Date("9999-12-31T12:00:00.000Z"),
							// httpOnly:true
						});
						this.setState(
							{
								currentTimeStamp: timerData,
							},
							this.getUserDetails
						);
					} else {
						// await toast(Variable.TOAST_SESSION_EXPIRED[this.ENV_NAME]);
						// console.log("logging out 1");
						this.logout();
					}
				})
				.catch(async (err, response, body) => {
					if (err.status === 500) {
						console.error("invalid logout");
					}
				});
		}
	};

	logout = async () => {
		let schoolName = this.props.auth?.userDetails?.school_name;
		let schoolId = this.props.auth?.userDetails?.schoolId || cookies.load("applicantschoolid");
		if (cookies.load("applicantrefreshtoken")) {
			await makeRequest({
				...generateRequestOptions("logout"),
				headers: {
					Accept: "application/json",
					"content-type": "application/json",
				},
				body: {
					revoke_token: false,
					refresh_token: cookies.load("applicantrefreshtoken"),
				},
				json: true,
			});
		}

		await cookies.remove("applicantauthtoken", { path: "/", domain: homeUrl });
		await cookies.remove("applicantrefreshtoken", { path: "/", domain: homeUrl });
		await cookies.remove("applicanttokenexpiry", { path: "/", domain: homeUrl });
		await cookies.remove("applicantschoolid", { path: "/", domain: homeUrl });
		await this.props.logout(null);

		if (schoolName && schoolId) {
			let schoolNameURL = encodeURIComponent(schoolName?.replace(/\s+/g, "-").toLowerCase().substring(0, 20));
			this.props?.history?.push("/" + schoolNameURL + "-" + schoolId);
		}
		// this.redirectHandler(RoutePath.SIGNIN);
	};

	getUserDetails = async () => {
		let res = await makeRequest(generateRequestOptions("getUserViaToken"));
		if (res?.code === 200) {
			let userDetails = res.data;
			this.setState({
				userDetails,
			});
			ReactGa.ga("set", "userId", res.data.id);
			delete userDetails.grade;
			userDetails.subjects = userDetails.subjects?.map((key) => {
				return {
					...key,
					course_id: key.courses
						?.map((ik) => {
							return ik.course_id;
						})
						.toString(),
				};
			});
			await this.props.dispatch_loginSucceed(userDetails);
			this.setState(
				{
					currentTimeStamp: res.data.current_timestamp,
				},
				this.getDetails
			);
		} else {
			// console.log("logging out 2");
			this.logout();
		}
	};

	getDetails = async () => {
		if (this.isValidAuthToken()) {
			if (this.props.auth)
				if (this.props.auth.persistedState)
					if (this.props.auth.persistedState.auth) {
						// console.log("setting values");
						this.props.updateFromPersist(this.props.auth.persistedState.auth);
					}
			if (this.props.auth.userDetails) {
				this.props.history.push(this.props.history.location);
				this.setUserDetails();
				this.startTimer();
				if (this.props.auth.videoUrl) {
					this.setState({
						videoUrlAvailable: true,
					});
				}

				if (this.props.history.location.pathname === "/") {
					this.props.history.push(RoutePath.DASHBOARD);
				}
				// this.goToDashboard();
			} else {
				await toast(Variable.TOAST_USER_NOT_VALID[this.ENV_NAME]);
				// console.log("logging out 3");
				this.logout();
			}
		} else {
			if (this.isValidRefreshToken()) {
				this.requestNewAccessToken();
			} else {
				console.error("error occured, failed to refresh token");
				await toast(Variable.TOAST_USER_NOT_VALID[this.ENV_NAME]);
				// console.log("logging out 4");
				this.logout();
			}
		}
	};

	setUserDetails = () => {
		this.setState({
			userDetails: this.props.auth.userDetails,
		});
	};

	goToDashboard = () => {
		this.props.history.push(RoutePath.DASHBOARD);
	};

	themeChanger = () => {
		const colors = [
			["#017632", "#000000", "#FFFFFF30"],
			["#204F8D", "#034aa6", "#488bff", "#4f92ea", "#056cf2", "#0959c1", "#007bff;"],
		];

		if (
			(homeUrl === "portalsekolah.co.id" && this.props.auth?.userDetails?.school_id === 1032) ||
			(homeUrl !== "portalsekolah.co.id" && this.props.auth?.userDetails?.school_id === 3)
		) {
			// school_id for bekti.wulandari
			return (document.documentElement.style.cssText = `--bg-dark-blue: ${colors[0][0]};--blue-primary: ${colors[0][0]};--themeBlue: ${colors[0][0]};--label-color-blue: ${colors[0][0]};--label-color-dark-blue: ${colors[0][0]};--borderTop-color: ${colors[0][2]}; --borderBottom-color: ${colors[0][2]};--blue-assessment: ${colors[0][0]}`);
		} else {
			return (document.documentElement.style.cssText = `--bg-dark-blue: ${colors[1][0]};--blue-primary: ${colors[1][1]};--themeBlue: ${colors[1][2]};--label-color-blue: ${colors[1][3]};--label-color-dark-blue: ${colors[1][4]}; --borderTop-color: ${colors[1][5]}; --borderBottom-color: ${colors[1][5]};--blue-assessment: ${colors[1][6]}`);
		}
	};

	async componentDidMount() {
		this.themeChanger();

		if (homeUrl === "portalsekolah.co.id") {
			console.log = function () {};
		}

		if (this.state?.userDetails?.role === "student") {
			let uD = this.state?.userDetails;
			delete uD?.section;
			this.props.changeUserDetails(uD);
		}

		// if (cookies.load("lang") !== this.props.auth.selectedEnvironment)
		this.ENV_NAME = cookies.load("lang");
		await this.props.changeLanguage(cookies.load("lang"));
		if (this.props.auth?.selectedEnvironment !== "eng") {
			moment.locale("id");
			moment.updateLocale("id", {
				longDateFormat: {
					LT: "HH:mm",
					LTS: "HH:mm:ss",
					L: "DD/MM/YYYY",
					LL: "D MMMM YYYY",
					LLL: "D MMMM YYYY [pukul] HH:mm",
					LLLL: "dddd, D MMMM YYYY [pukul] HH:mm",
				},
			});
		} else moment.locale("en");

		// Get an active component based on the current location
		let component = getActiveComp(this.props.location.pathname, this?.props?.auth?.isAuthenticated);
		if (component)
			this.setState({
				...component,
			});

		// Add if the URL is changed
		this.unlisten = this.props.history.listen(async (location, action) => {
			let component = getActiveComp(location.pathname, this?.props?.auth?.isAuthenticated);
			if (component)
				this.setState({
					...component,
				});
		});

		if (cookies.load("applicantauthtoken") && cookies.load("applicantrefreshtoken")) {
			this.getUserDetails();

			// Redirect to last visited page before mainpage redirection
			// If they accessed it before login
			// if (cookies.load("previousPage")) {
			// 	window.open(cookies.load("previousPage"), "_self");
			// 	cookies.remove("previousPage", { path: "/", domain: homeUrl });
			// }
		} else {
			// Store the current page before redirecting to mainpage
			// await cookies.save("previousPage", `${window.location.href}`, {
			// 	domain: homeUrl,
			// 	path: "/",
			// 	expires: moment().add(30, "minutes").toDate(),
			// });
			this.logout();
		}

		document.addEventListener("mousedown", this.profileClickOutside);
		document.addEventListener("mousedown", this.academicClickOutside);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.auth?.userDetails?.school_id !== this.props.auth?.userDetails?.school_id) {
			this.themeChanger();
		}

		if (cookies.load("lang") !== this.props.auth.selectedEnvironment) {
			this.props.changeLanguage(cookies.load("lang"));
			this.ENV_NAME = cookies.load("lang");
		}
		if (this.props.auth?.selectedEnvironment !== prevProps.auth?.selectedEnvironment) {
			if (this.props.auth?.selectedEnvironment !== "eng") {
				moment.locale("id");
				moment.updateLocale("id", {
					longDateFormat: {
						LT: "HH:mm",
						LTS: "HH:mm:ss",
						L: "DD/MM/YYYY",
						LL: "D MMMM YYYY",
						LLL: "D MMMM YYYY [pukul] HH:mm",
						LLLL: "dddd, D MMMM YYYY [pukul] HH:mm",
					},
				});
			} else moment.locale("en");
		}

		if (!isEqual(prevProps.auth, this.props.auth)) {
			if (prevProps.auth.isLoginSuccess !== this.props.auth.isLoginSuccess && this.props.auth.isLoginSuccess) {
				this.startTimer();
			}
		}

		if (!isEqual(prevProps.auth, this.props.auth)) {
			this.setUserDetails();
		}

		if (
			!isEqual(prevProps.auth, this.props.auth) &&
			prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated
		) {
			if (this.props.auth.isAuthenticated) {
				this.requestNewAccessToken();
			}
		}

		if (prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
			// Redo the location listen
			if (typeof this.unlisten === "function") this.unlisten();
			let component = getActiveComp(
				this.props.location.pathname,
				this?.props?.auth?.isAuthenticated
			);
			if (component)
				this.setState({
					...component,
				});
			this.unlisten = this.props.history.listen(async (location, action) => {
				let component = getActiveComp(location.pathname, this?.props?.auth?.isAuthenticated);
				if (component)
					this.setState({
						...component,
					});
			});
		}

		if (prevState.isActiveComponent !== this.state.isActiveComponent) {
			const activeEl = document.querySelector(".menu-item.active");
			if (activeEl) {
				activeEl.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		}
	}

	componentWillUnmount() {
		if (typeof this.unlisten === "function") this.unlisten();
		if (this.T30sListener) clearInterval(this.T30sListener);
		document.removeEventListener("mousedown", this.profileClickOutside);
		document.removeEventListener("mousedown", this.academicClickOutside);
		// this.props.clearToolkit();
	}

	render() {
		const {
			isSidebarActive,
			// isLogin,
			isActiveComponent,
		} = this.state;
		return (
			<div>
				<Helmet>
					{homeUrl === "portalsiswa.id" && <title>Portal Siswa</title>}
					{homeUrl === "portalmurid.com" && <title>Portal Murid</title>}
					{homeUrl === "localhost" && <title>Localhost</title>}
					<link rel="canonical" href="http://mysite.com/example" />
				</Helmet>

				<Detector
					polling={{ interval: 10000, url: "https://ipv4.icanhazip.com" }}
					render={({ online }) =>
						online ? (
							<OnlineComponent language={this.state.language} />
						) : (
							<OfflineComponent language={this.state.language} />
						)
					}
				/>
				{this.props.auth.isAuthenticated && this.props.auth.userDetails ? (
					// This is for unauthenticated user
					<div className="container-fluid p-0 d-flex w-100">
						<div className={"sidebarWidth4 float-left"}>
							<div className="sidebarWidth3">
								<Navigation
									isMobile={false}
									isActiveComponent={isActiveComponent}
									ENV_NAME={this.ENV_NAME}
									schoolId={this.props.auth?.userDetails?.school_id}
									userDetails={this.props.auth?.userDetails}
									logout={() => this.logout()}
									goToDashboard={this.goToDashboard}
									changeLanguage={(val) => this.props.changeLanguage(val)}
								/>
							</div>
						</div>

						<div
							className={
								isSidebarActive ? "contentContainer contentWidth1" : "contentContainer contentWidth2"
							}
						>
							<div className="border-btm-main fixed-bottom"></div>

							<div className="container-fluid bg-ocean border-main">
								<div className="row">
									<div className="col-12 col-md-12 col-xl-12 p-0">
										<Navigation
											isMobile={true}
											ENV_NAME={this.ENV_NAME}
											userDetails={this.props.auth?.userDetails}
											logout={() => this.logout()}
											changeLanguage={(val) => this.props.changeLanguage(val)}
											goToDashboard={this.goToDashboard}
										/>
										<this.state.activeComponent />
									</div>
								</div>
							</div>
						</div>
					</div>
				) : this.props.auth.isAuthenticated ? (
					<LoadingComponent />
				) : (
					// This is for unauthenticated user
					<this.state.activeComponent />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	// console.log(state)
	return state;
};

const mapStateToDispatch = (dispatch) => {
	return {
		dispatch_loginSucceed: (payload) => dispatch({ type: ActionTypes.LOGIN_SUCCEED, payload: payload }),
		userDetailsFetched: (payload) => dispatch({ type: ActionTypes.LOGGED_IN_USER_FETCHED, payload: payload }),
		updateFromPersist: (payload) => dispatch({ type: ActionTypes.UPDATE_FROM_PERSIST, payload: payload }),
		changeLanguage: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_SELECTED_LANGUAGE,
				payload: payload,
			}),
		logout: (payload) => dispatch({ type: ActionTypes.LOGOUT, payload: payload }),
		changeUserDetails: (payload) => dispatch({ type: ActionTypes.SET_USER, payload: payload }),
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(MainComponent));
