import React from "react";
import PropTypes from "prop-types";
import { homeUrl } from "../../utils/apiEndPoints";
import { useSelector } from "react-redux";
import * as USERROLES from "../../utils/userroles";

const Release = ({ to, children, validFor }) => {
	const userType =
		useSelector((state) => state.auth.userDetails?.role) || "student";
	const isValid = (to) => {
		if (!Array.isArray(to)) return true;
		return (
			((homeUrl === "portalsiswa.id" || homeUrl === "localhost") &&
				to.includes("staging")) ||
			(homeUrl === "portalmurid.com" && to.includes("uat")) ||
			(homeUrl === "portalsekolah.co.id" && to.includes("production"))
		);
	};

	const isValidFor = (validFor) => {
		if (!Array.isArray(validFor)) return true;
		return (
			(userType === "admin" && validFor.includes("admin")) ||
			(userType === "teacher" && validFor.includes("teacher")) ||
			(userType === "student" && validFor.includes("student")) ||
			(userType === "parent" && validFor.includes("parent")) ||
			(userType === USERROLES.OFFICIAL_HIGHSCHOOL &&
				validFor.includes(USERROLES.OFFICIAL_HIGHSCHOOL)) ||
			(userType === USERROLES.OFFICIAL_PRIMARYSCHOOL &&
				validFor.includes(USERROLES.OFFICIAL_PRIMARYSCHOOL)) ||
			(userType === USERROLES.FOUNDATION_USER &&
				validFor.includes(USERROLES.FOUNDATION_USER))
		);
	};

	return isValid(to) && isValidFor(validFor) ? children : <></>;
};

Release.propTypes = {
	to: PropTypes.arrayOf(PropTypes.oneOf(["staging", "uat", "production"])),
	validFor: PropTypes.arrayOf(
		PropTypes.oneOf(["admin", "teacher", "student", "parent"])
	),
	children: PropTypes.any,
};

export default Release;
