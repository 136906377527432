import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import makeRequest from "../../shared/utils/request";
import * as Variable from "../../shared/utils/variables";
import * as RoutePath from "../../shared/utils/routeLink";
import * as ActionTypes from "../../store/actions/actionTypes";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";

import "./DashboardComponent.scss";
import BatchList from "../../assets/image/batch_list.jpg";
import BatchStatus from "../../assets/image/status_batch.png";
import Image from "../../shared/component/UI/Image";

const DATE_FORMAT = "DD-MM-YYYY";
const DATE_TIME_FORMAT = "DD-MM-YYYY h:mm";

const DashboardComponent = ({ schoolData, ENV_NAME, ...props }) => {
  const [isHover, setIsHover] = useState(false);
  const [reachedLimit, setReachedLimit] = useState(false);
  const [batchData, setBatchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [batchActive, setBatchActive] = useState(null);
  const [uncompleted, setUncompleted] = useState("");
  const [admissionData, setAdmissionData] = useState(null);
  const [noticeMessage, setNoticeMessage] = useState({
    show: false,
    type: null,
    message: "",
  });

  const currentDate = moment(new Date()).format("YYYY-MM-DD");

  const showNotice = (message, type = "success", timeout = 5000) => {
    setNoticeMessage({
      show: true,
      type: type,
      message,
    });
    setTimeout(() => {
      setNoticeMessage({
        show: false,
        type: null,
        message: "",
      });
    }, timeout);
  };

  const getAdmission = async () => {
    setIsLoading(true);
    const res = await makeRequest(generateRequestOptions("listAdmission"));
    if (res?.code === 200) {
      setAdmissionData(res?.data);
      fetchBatchData(res?.data?.[0]?.id);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showNotice(
        res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME],
        "error"
      );
    }
  };

  useEffect(() => {
    getAdmission();
    fetchSchoolData(props.userDetails?.school_id);
  }, []); //eslint-disable-line

  useEffect(() => {
    // if (batchActive && admissionData) {
    // }
    checkApplicant();
  }, [batchActive, admissionData]); //eslint-disable-line

  const fetchBatchData = async (id) => {
    setIsLoading(true);
    const res = await makeRequest({
      ...generateRequestOptions("getBatch", {
        queryParams: { school_admission: id },
      }),
    });

    if (res?.code === 200) {
      let temp = res?.data.reduce(
        (obj, curr) => ({
          ...obj,
          [curr.id]: {
            ...obj[curr.id],
            id: curr?.id,
            batch_description: curr?.batch_description,
            batch_name: curr?.batch_name,
            start_date: curr?.start_date,
            end_date: curr?.end_date,
            // We make the validation inclusive by added more paramaters
            isActive: moment(currentDate).isBetween(
              curr?.start_date,
              curr?.end_date,
              "days",
              "[]"
            ),
          },
        }),
        {}
      );
      setIsLoading(false);
      setBatchData(Object.values(temp));
      setBatchActive(Object.values(temp).find((i) => i?.isActive === true));
    } else {
      setIsLoading(false);
      showNotice(
        res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME],
        "error"
      );
    }
  };

  const fetchSchoolData = async (id) => {
    setIsLoading(true);
    const res = await makeRequest(
      generateRequestOptions("getSchoolData", { urlParams: id + "/" })
    );

    if (res?.code === 200) {
      props.retrieveSchoolData(res?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showNotice(
        res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME],
        "error"
      );
    }
  };

  const applyParticularBatch = async (bId) => {
    setIsLoading(true);
    const res = await makeRequest({
      ...generateRequestOptions("applyBatch"),
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: { batch: bId },
      json: true,
    });

    if (res?.code === 200) {
      setIsLoading(false);
      props.history.push(
        RoutePath.APPLY_BATCH +
          (admissionData?.length !== 0 ? `/${admissionData?.[0]?.id}` : "") +
          "/" +
          (bId ? bId : "") +
          "/" +
          res?.data?.id
      );
    } else if (
      res?.code === 400 &&
      (res?.message?.includes("already applied") ||
        res?.message?.includes("telah mendaftar"))
    ) {
      setIsLoading(false);
      showNotice(
        res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME],
        "error"
      );
    } else if (res?.code === 400) {
      setIsLoading(false);
      setReachedLimit(true);
      showNotice(
        res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME],
        "error"
      );
    } else {
      setIsLoading(false);
      showNotice(
        res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME],
        "error"
      );
    }
  };

  const checkApplicant = () => {
    setIsLoading(true);
    makeRequest({
      ...generateRequestOptions("applicantListing", {
        // queryParams: {
        //   batch: batchActive?.id,
        //   batch__school_admission: admissionData?.[0]?.id,
        // },
        queryParams: {
          applicant: props?.userDetails?.id,
        },
        queryOptions: { indices: false },
      }),
    })
      .then((res) => {
        if (res?.code === 200) {
          // setUncompleted(
          //   res?.data.find((i) => i?.applicant === props.userDetails?.id)
          // );
          setUncompleted(
            (res?.data || [])?.sort((x, y) =>
              moment.utc(y?.end_date).diff(moment.utc(x?.end_date))
            )?.[0]
          );
        } else {
          showNotice(
            res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME],
            "error"
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="widget dash">
      {isLoading ? (
        <div className="dash-spinner">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <div className="container-fluid nopad">
            <div className="row m-0 hundredPheight">
              <div className="col-sm-12 col-md-12 py-2 nopad border-heading d-flex justify-content-end align-items-center">
                <h5>{schoolData?.name}</h5>
                <Image className="dash-logo" src={schoolData?.logo} />
              </div>
            </div>
          </div>
          <div className="container w-100 h-100">
            <div className="dash-header">
              <h1>
                {admissionData?.[0]?.school_admission_name
                  ? admissionData?.[0]?.school_admission_name
                  : "-"}
              </h1>
              <h3>{`${Variable.ACADEMIC_YEAR_LBL[ENV_NAME]} - ${admissionData?.[0]?.target_academic_year}`}</h3>
            </div>
            <div className="dash-content">
              <div className="dash-content-body">
                {batchData?.map((bd, bdIdx) => {
                  return (
                    <div
                      key={bdIdx}
                      onMouseEnter={() =>
                        bd?.isActive === true ? setIsHover(true) : ""
                      }
                      onMouseLeave={() =>
                        bd?.isActive === true ? setIsHover(false) : ""
                      }
                      className={`dcBox ${
                        (isHover && bd?.isActive === true) ||
                        (uncompleted &&
                          bd?.isActive === true &&
                          uncompleted === "rejected") ||
                        (bd?.isActive === true &&
                          uncompleted?.submission_status ===
                            "submitted_for_review")
                          ? "hover"
                          : ""
                      }`}
                    >
                      <div
                        className={`dcBox-img ${
                          (isHover && bd?.isActive === true) ||
                          (uncompleted &&
                            bd?.isActive === true &&
                            uncompleted === "rejected") ||
                          (bd?.isActive === true &&
                            uncompleted?.submission_status ===
                              "submitted_for_review")
                            ? "d-none"
                            : ""
                        } `}
                      >
                        <Image src={BatchList} />
                      </div>
                      <div
                        className={`dcBox-text ${
                          (isHover && bd?.isActive === true) ||
                          (uncompleted &&
                            bd?.isActive === true &&
                            uncompleted === "rejected") ||
                          (bd?.isActive === true &&
                            uncompleted?.submission_status ===
                              "submitted_for_review")
                            ? "hover"
                            : ""
                        }`}
                      >
                        <h4>{bd?.batch_name}</h4>
                        <h5>{`${moment(bd?.start_date).format(
                          DATE_FORMAT
                        )} - ${moment(bd?.end_date).format(DATE_FORMAT)}`}</h5>
                      </div>
                      {((bd?.isActive === true && uncompleted === undefined) ||
                        (bd?.isActive === true &&
                          uncompleted?.submission_status === "rejected" &&
                          ((uncompleted !== undefined &&
                            moment(uncompleted?.end_date)?.isSameOrBefore(
                              moment()
                            ) &&
                            uncompleted?.submission_status === "rejected") ||
                            (uncompleted?.submission_status === null &&
                              currentDate >= uncompleted?.end_date)))) && (
                        <div className="ml-auto d-flex flex-column justify-content-center align-items-center mx-2">
                          <div className="dcBox-btn">
                            <button
                              className={`${
                                isHover && bd?.isActive === true ? "hover" : ""
                              } ${reachedLimit ? "cursorDisabled" : ""}`}
                              onClick={() => {
                                applyParticularBatch(bd?.id);
                              }}
                              disabled={reachedLimit ? true : false}
                            >
                              {Variable.APPLY_LABEL[ENV_NAME]}
                            </button>
                          </div>
                          {reachedLimit && (
                            <div
                              className={`dcBox-note ${isHover ? "hover" : ""}`}
                            >
                              {Variable.REACHED_LIMIT_LABEL[ENV_NAME]}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {uncompleted && (
                <div className="dash-content-status">
                  <div
                    className={`statBox ${
                      uncompleted?.submission_status === null ||
                      uncompleted?.submission_status === "unfinised"
                        ? "continueApply"
                        : uncompleted?.submission_status ===
                          "submitted_for_review"
                        ? "inReview"
                        : uncompleted?.submission_status === "approved"
                        ? "approved"
                        : uncompleted?.submission_status === "rejected"
                        ? "rejected"
                        : "-"
                    }`}
                  >
                    <Image src={BatchStatus} />
                    <h4>{Variable.APPLICATION_STATUS[ENV_NAME]}</h4>
                    <div className="statBox-main">
                      <div className="statBox-main-left">
                        <p>{`${Variable.BATCH_APPLIED_LABEL[ENV_NAME]}`}</p>
                        <p>{`${Variable.SUBMISSION_LABEL[ENV_NAME]}`}</p>
                        <p>{`${Variable.STATUS_LABEL[ENV_NAME]}`}</p>
                      </div>
                      <div className="statBox-main-right">
                        <p>{`${uncompleted?.batch_name || "-"}`}</p>
                        <p>{`${
                          moment(uncompleted?.created_at).format(
                            DATE_TIME_FORMAT
                          ) || "-"
                        }`}</p>
                        <p>{`${
                          uncompleted?.submission_status === null ||
                          uncompleted?.submission_status === "unfinised"
                            ? Variable.UNFINISHED_REGISTRATION[ENV_NAME]
                            : uncompleted?.submission_status ===
                              "submitted_for_review"
                            ? Variable.IN_REVIEW_LABEL[ENV_NAME]
                            : uncompleted?.submission_status === "approved"
                            ? Variable.APPROVED_LABEL[ENV_NAME]
                            : uncompleted?.submission_status === "rejected"
                            ? Variable.REJECTED_LABEL[ENV_NAME]
                            : "-"
                        }`}</p>
                      </div>
                    </div>
                  </div>
                  {(uncompleted?.submission_status === null ||
                    uncompleted?.submission_status === "unfinished") && (
                    <div
                      className={`statBtn ${
                        uncompleted?.end_date < currentDate ? "d-none" : ""
                      }`}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          props.history.push(
                            RoutePath.APPLY_BATCH +
                              "/" +
                              admissionData?.[0]?.id +
                              "/" +
                              batchActive?.id +
                              "/" +
                              uncompleted?.id
                          )
                        }
                      >
                        {Variable.CONTINUE_APPLY_LABEL[ENV_NAME]}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {noticeMessage?.show && (
            <div
              className={`snackbar-bottom-notice ${
                noticeMessage?.type === "error" ? "errorMessage" : ""
              }`}
            >
              <div className="mr-4">{noticeMessage?.message}</div>
              <i
                className="fa fa-times cursorPointer"
                onClick={() => {
                  setNoticeMessage({
                    show: false,
                    type: null,
                    message: "",
                  });
                }}
              ></i>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStoP = (state) => {
  return {
    ENV_NAME: state.auth.selectedEnvironment || "bhs",
    userDetails: state.auth.userDetails,
    schoolData: state.auth.schoolData,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    retrieveSchoolData: (payload) =>
      dispatch({
        type: ActionTypes.SET_SCHOOL_DATA,
        payload: payload,
      }),
  };
};

export default connect(
  mapStoP,
  mapStateToDispatch
)(withRouter(DashboardComponent));
