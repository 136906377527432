import cookies from "react-cookies";
import { put } from "redux-saga/effects";
import { homeUrl } from "../../shared/utils/apiEndPoints";
import * as actionTypes from "../actions/actionTypes";
export function* logoutUser(action) {
  yield put({ type: actionTypes.UPDATE_LOGOUT, payload: action.payload });
}
export function* updateUserStatus(action) {
  yield put({
    type: actionTypes.UPDATE_USER_STATUS_DATA,
    payload: action.payload,
  });
}
export function* updateSelectedLanguage(action) {
  yield put({
    type: actionTypes.UPDATE_LANGUAGE,
    payload: action.payload,
  });
}
export function* loginSucceed(action) {
  cookies.save("last_appl_user_id", action.payload?.id, {
    domain: homeUrl,
    path: "/",
    expires: new Date("9999-12-31T12:00:00.000Z"),
  });
  yield put({ type: actionTypes.SET_USER, payload: action.payload });
}
export function* loggedInUserFetched(action) {
  cookies.save("last_appl_user_id", action.payload?.id, {
    domain: homeUrl,
    path: "/",
    expires: new Date("9999-12-31T12:00:00.000Z"),
  });
  yield put({ type: actionTypes.SET_USER, payload: action.payload });
}
export function* updateUserInStore(action) {
  yield put({
    type: actionTypes.UPDATE_USER_IN_STORE,
    payload: action.payload,
  });
}
export function* updateAllDataInStore(action) {
  yield put({ type: actionTypes.SET_STORE_DATA, payload: action.payload });
}
