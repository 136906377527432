import React from "react";
import PropTypes from "prop-types";
import * as mlang from "../../utils/variables";
import { connect } from "react-redux";

const toVal = (v, key = "id") => (typeof v === "object" ? v?.[key] : v);

const Select = (props) => {
  const {
    items,
    className,
    value,
    placeholder,
    disabled,
    name,
    id,
    noDefault,
    defaultValue,
    defaultOpt = {},
    selected,
    hidden,
    selectedEnvironment: ENV_NAME = "bhs",
  } = props;

  let itemsDict =
    (Array.isArray(items) &&
      items.reduce(
        (obj, item) =>
          Object.assign(
            obj,
            typeof item === "object" ? { [item.id]: item } : { [item]: item }
          ),
        {}
      )) ||
    {};

  const onChange = (event) => {
    if (typeof props.onChange === "function") props.onChange(event);
    if (typeof props.onChangeItem === "function")
      props.onChangeItem(itemsDict[event.target.value], event);
  };

  return (
    <select
      name={name}
      placeholder={placeholder || mlang.UI_SELECT_DEFAULT_OPTION_LBL[ENV_NAME]}
      id={id}
      className={className}
      onChange={onChange}
      disabled={disabled}
      onClick={props.onClick}
      value={selected ? toVal(selected) : toVal(value)}
      defaultValue={toVal(defaultValue)}
      hidden={hidden}
    >
      {noDefault || (
        <option
          value={toVal(defaultOpt) != null ? toVal(defaultOpt) : ""}
          disabled={defaultOpt?.disabled}
          hidden={defaultOpt?.hidden}
        >
          {toVal(defaultOpt, "name") ||
            mlang.UI_SELECT_DEFAULT_OPTION_LBL[ENV_NAME]}
        </option>
      )}
      {Array.isArray(items) &&
        items.map((item) => (
          <option
            key={toVal(item)}
            value={toVal(item)}
            disabled={typeof item === "object" ? item.disabled : false}
          >
            {toVal(item, "name")}
          </option>
        ))}
    </select>
  );
};

Select.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.isRequired,
        name: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
      })
    ),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),

  // label: PropTypes.string.isRequired,
  // divClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.any,
  noDefault: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeItem: PropTypes.func,
  defaultOpt: PropTypes.shape({
    id: PropTypes.isRequired,
    name: PropTypes.isRequired,
  }),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.isRequired,
    }),
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.any,
    }),
  ]),
};

export default connect((state) => ({
  selectedEnvironment: state.auth?.selectedEnvironment,
}))(Select);
