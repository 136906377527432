import React, { useState, useRef, useContext } from "react";
import cookies from "react-cookies";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactGa from "react-ga";

import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions, homeUrl } from "../../../shared/utils/apiEndPoints";
import * as Variable from "../../../shared/utils/variables";
import * as RoutePath from "../../../shared/utils/routeLink";
import * as ActionTypes from "../../../store/actions/actionTypes";
import { HASH_ENUM, SchoolContext } from "../MainPageComponent";
import { useEffect } from "react";
import isValidEmail from "../../../shared/utils/isValidEmail";

// import SearchSchool from "../../shared/component/SearchSchoolComponent/SearchSchoolComponent";

const LoginComponent = ({ ENV_NAME, ...props }) => {
	const [form, setForm] = useState({
		email: "",
		password: "",
	});
	const [disableButton, setDisableButton] = useState(false);
	const [isShowPassword, setIsShowPassword] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");
	const loginRef = useRef();
	const { schoolData, redirectHandler } = useContext(SchoolContext);

	useEffect(() => {
		loginRef && loginRef.current && loginRef.current.focus();
	}, []);

	const inputHandler = (event) => {
		setForm((p) => ({ ...p, [event.target.name]: event.target.value }));
	};

	const logIn = async () => {
		if (!schoolData?.id) {
			setErrorMessage(Variable.CANT_GET_SCHOOL_REFRESH[ENV_NAME]);
			setTimeout(() => setErrorMessage(null), 5000);
			return;
		}
		let data = {
			...(isValidEmail(form?.email) ? { email: form?.email } : { login: form?.email }),
			password: form?.password,
			school_id: schoolData?.id,
		};
		if (!(data.email || data.login) || !data.password) {
			if (!(data.email || data.login) && data.password) {
				setDisableButton(false);
				setErrorMessage(Variable.FILL_CORRECT_USER_ID[ENV_NAME]);
			} else if (!data.password && (data.email || data.login)) {
				setDisableButton(false);
				setErrorMessage(Variable.FILL_CORRECT_PASSWORD[ENV_NAME]);
			} else {
				setDisableButton(false);
				setErrorMessage(Variable.FILL_CORRECT_USER_ID_PASSWORD[ENV_NAME]);
			}
			setTimeout(() => setErrorMessage(null), 5000);
		} else {
			setDisableButton(true);
			let res = await makeRequest({
				...generateRequestOptions("logIn"),
				headers: {
					Accept: "application/json",
					"content-type": "application/json",
					"Accept-Language": `${cookies.load("lang") === "eng" ? "en-us" : "id"}`,
				},
				body: data,
				json: true,
			});

			if (res.code === 200) {
				if (homeUrl === "portalsekolah.co.id") {
					ReactGa.set({ email: res.data.id });
					ReactGa.ga("set", "email", res.data.id);
					ReactGa.event({ category: res.data.role, action: "login" });
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "user-login",
						schoolId: res.data.school_id,
						role: res.data.role,
						schoolNameAndRole: `${res.data.school_name} (${res.data.role})`,
						username: res.data.username,
					});
				}

				await cookies.save("lang", "bhs", {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});
				await cookies.save("applicantauthtoken", res.data?.jwt?.access, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});
				await cookies.save("applicantrefreshtoken", res.data?.jwt?.refresh, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});
				let timerData = Date.now(res?.data?.last_login);
				await cookies.save("applicanttokenexpiry", timerData + 870000, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});
				await cookies.save("applicantschoolid", schoolData?.id, {
					domain: homeUrl,
					path: "/",
					expires: new Date("9999-12-31T12:00:00.000Z"),
				});
				props.dispatch_loginSucceed(null);
				props.history.push(RoutePath.DASHBOARD);
				window.location.reload();
			} else {
				setErrorMessage(Variable.ERROR_INVALID_LOGIN[ENV_NAME]);
				setDisableButton(false);
				setTimeout(() => setErrorMessage(null), 5000);
			}
			setDisableButton(false);
		}
	};

	const forgotPassword = () => {
		redirectHandler(HASH_ENUM.FORGOTPASSWORD);
	};
	const goToSignup = () => {
		redirectHandler(HASH_ENUM.SIGNUP);
	};

	return (
		<div className="rightSideBox d-flex justify-content-center align-items-center positionRelative">
			<form
				className="formContainer noMar"
				onSubmit={(e) => {
					e.preventDefault();
					logIn();
				}}
			>
				<div className="mb-4 d-flex align-content-center">
					<h1 className="heading mb-0">{Variable.SIGNIN[ENV_NAME]}</h1>
				</div>
				<div className="form-group form-label-group in-border">
					<input
						id="login"
						type="text"
						className={`form-control ${errorMessage ? "border-danger" : ""}`}
						name="email"
						value={form?.email || ""}
						onChange={inputHandler}
						placeholder={`${Variable.EMAIL_ID[ENV_NAME]}/${Variable.USERNAME[ENV_NAME]}`}
						ref={loginRef}
						required
					/>
					<label htmlFor="login" className="font-weight-medium">
						{`${Variable.EMAIL_ID[ENV_NAME]}/${Variable.USERNAME[ENV_NAME]}`}
					</label>
				</div>
				<div className="form-group form-label-group in-border smallMargin">
					<div className="positionRelative">
						<input
							id="password"
							type={isShowPassword ? "password" : "text"}
							className={`form-control ${errorMessage ? "border-danger" : ""}`}
							name="password"
							value={form?.password || ""}
							onChange={inputHandler}
							placeholder={Variable.PASSWORD[ENV_NAME]}
							required
						/>
						<label htmlFor="password" className="font-weight-medium">
							{Variable.PASSWORD[ENV_NAME]}
						</label>
						<div
							className={`pwd-eye cursorPointer ${!isShowPassword ? "active" : ""}`}
							onClick={() => {
								setIsShowPassword((p) => !p);
							}}
						>
							<i className={`fa fa-eye${isShowPassword ? "-slash" : ""}`}></i>
						</div>
					</div>
					<div className="mt-3 text-right">
						<span className="text-label-blue font-weight-medium cursorPointer" onClick={forgotPassword}>
							{Variable.FORGOT_PASSWORD[ENV_NAME]}?
						</span>
					</div>
				</div>
				<div className="col text-right noPad mb-4 mt-1"></div>
				{/* <div className="col text-left mb-4">
						<span className="errorMessage">{errorMessage}</span>
					</div> */}
				<button type="submit" className="btn btn-block btn-primary-login" disabled={disableButton}>
					{Variable.SIGNIN[ENV_NAME]}
				</button>
				<div className="col text-center nopad  mt-4">
					<div className="font-weight-medium">
						{Variable.DONT_HAVE_ACCOUNT[ENV_NAME]}
						<span className="text-label-blue font-weight-bold cursorPointer ml-2" onClick={goToSignup}>
							{Variable.SIGNUP[ENV_NAME]}
						</span>
					</div>
				</div>
			</form>
			<div className={`errorMsgBox ${errorMessage ? "show" : ""}`}>{errorMessage}</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment,
});
const mapStateToDispatch = (dispatch) => {
	return {
		dispatch_loginSucceed: (payload) => dispatch({ type: ActionTypes.LOGIN_SUCCEED, payload: payload }),
	};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(LoginComponent));
