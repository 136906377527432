import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "../../../shared/component/Select";
import * as Variable from "../../../shared/utils/variables";
import * as RoutePath from "../../../shared/utils/routeLink";
import UnsavedChanges from "../../../shared/component/UnsavedChanges";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import FormCustomField from "./FormCustomField";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import { CUSTOM_FIELD_TYPE, RegistContext } from "../RegistrationComponent";
import { NoticeMessageContext } from "../../../App";

import "../RegistrationComponent.scss";
import moment from "moment";

export const TIME_FORMAT = "DD-MM-YYYY";
export const SUBMIT_TIME_FORMAT = "YYYY-MM-DD";
const ONCHANGE_TYPE = {
	DEFAULT: "default",
	OPTION: "option",
	RADIO: "radio",
	DATE: "date",
};
const GENDER_OPT = [
	{ id: "Male", name: Variable.MALE_LABEL },
	{ id: "Female", name: Variable.FEMALE_LABEL },
];
const RELIGION_OPT = [
	{ id: "Islam", name: "Islam" },
	{ id: "Protestan", name: "Protestan" },
	{ id: "Katolik", name: "Katolik" },
	{ id: "Buddha", name: "Buddha" },
	{ id: "Hindu", name: "Hindu" },
	{ id: "Khonghucu", name: "Khonghucu" },
];
const TAB_NAME = "student_information";
const TAB_ENUM = {
	STUDENT: "#student",
	PARENT: "#parent",
	DOCUMENT: "#document",
};

const StudentInfoTab = ({ ENV_NAME, isOpened, ...props }) => {
	const { showNotice } = useContext(NoticeMessageContext);
	const { admissionId, batchId, applyId, registData, customFieldAnswer, admissionData, getFormData } =
		useContext(RegistContext);
	const [registDataTemp, setRegistDataTemp] = useState(null);
	const [hiddenFields, setHiddenFields] = useState(admissionData?.hidden_registration_form_fields);
	const [customFields, setCustomFields] = useState(null);
	const [customFieldsAnswerTemp, setCustomFieldsAnswerTemp] = useState(null);
	const [fieldsError, setFieldsError] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [provinceOpt, setProvinceOpt] = useState([]);
	const [cityOpt, setCityOpt] = useState([]);

	// If opened, we want to load the custom fields for this particular tab
	useEffect(() => {
		if (isOpened && admissionId) {
			getCustomFields();
		}
		if (!isOpened) {
			setIsEditing(false);
		}
	}, [isOpened, admissionId]); // eslint-disable-line

	// If opened and other supporting data is available
	// We want to load the custom fields answer
	useEffect(() => {
		if (isOpened && Array.isArray(customFieldAnswer) && Array.isArray(customFields)) {
			getCustomFieldsAnswer(customFields, customFieldAnswer);
		}
	}, [isOpened, customFieldAnswer, customFields]); // eslint-disable-line

	// For every form changes from parent, we update the data
	useEffect(() => {
		if (registData) {
			setRegistDataTemp(registData);
		}
	}, [registData]);

	// For handling showing/hiding parts of the forms
	useEffect(() => {
		let hiddenFieldsTemp = admissionData?.hidden_registration_form_fields || [];
		let hideBirthPlaceDate = hiddenFieldsTemp?.some((i) => i === "birth_place" || i === "birth_date");
		let hideHomeAddress = hiddenFieldsTemp?.some((i) => i === "province" || i === "city" || i === "address");
		setHiddenFields({
			birth_place_date: !hideBirthPlaceDate,
			home_address: !hideHomeAddress,
		});
		if (!hideHomeAddress) {
			// Get location listing
			getLocationListing();
		}
	}, [admissionData?.hidden_registration_form_fields]); // eslint-disable-line

	// Get the city for that particular city if the province is changed
	useEffect(() => {
		if (registDataTemp?.province) {
			getLocationListing("city", registDataTemp?.province);
		}
	}, [registDataTemp?.province]); // eslint-disable-line

	// Self explainatory, only the questions
	const getCustomFields = async () => {
		const res = await makeRequest({
			...generateRequestOptions("getCustomForm", {
				queryParams: { school_admission: admissionId, reg_form_setting_tab: TAB_NAME },
			}),
		});
		if (res?.code === 200) {
			setCustomFields(res?.data);
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	// Get the custom field answers from the applicant (the answer is actually from the parent component)
	// This function will convert those answers into an object for faster access
	// Also, we only show the answer in this tab only
	const getCustomFieldsAnswer = (custField, custFieldAns) => {
		let custFieldObjTemp = {};
		let thisTabCustField = {};

		custField?.forEach((i) => {
			thisTabCustField = { ...thisTabCustField, [i?.id]: true };
		});

		// Change the data into a object for faster access
		// But first we want to filter only
		custFieldAns
			?.filter((i) => thisTabCustField?.[i?.custom_field])
			?.forEach((i) => {
				custFieldObjTemp = { ...custFieldObjTemp, [+i?.custom_field]: i };
			});

		setCustomFieldsAnswerTemp(custFieldObjTemp);
	};

	// Handler for onChange
	const onChangeHandler = (e, type = ONCHANGE_TYPE.DEFAULT) => {
		setIsEditing(true);
		if (type === ONCHANGE_TYPE.OPTION) {
			setRegistDataTemp((p) => ({ ...p, [e.name]: e.value?.id }));
			return;
		}
		if (type === ONCHANGE_TYPE.DATE || type === ONCHANGE_TYPE.RADIO) {
			setRegistDataTemp((p) => ({ ...p, [e.name]: e.value }));
			return;
		}

		setRegistDataTemp((p) => ({ ...p, [e.target.name]: e.target.value }));
	};

	// Handler of onChange for Custom Fields
	const onCustomFieldAnswerChange = (id, type, value) => {
		setIsEditing(true);
		setCustomFieldsAnswerTemp((p) => {
			let currAns = p?.[id] || { custom_field: id, batch_application: applyId, school_admission: admissionId };
			switch (type) {
				case CUSTOM_FIELD_TYPE.TEXT:
					currAns = { ...currAns, text_answer: value };
					break;
				case CUSTOM_FIELD_TYPE.NUMBER:
					currAns = { ...currAns, number_answer: Number(value) || 0 };
					break;
				case CUSTOM_FIELD_TYPE.MULTIPLECHOICE:
					if (value.isAdd) {
						currAns = {
							...currAns,
							choice_based_answer: [value?.value],
						};
					} else {
						currAns = {
							...currAns,
							choice_based_answer: null,
						};
					}
					break;
				case CUSTOM_FIELD_TYPE.CHECKBOXES:
					if (value.isAdd) {
						currAns = {
							...currAns,
							choice_based_answer: [...(currAns?.choice_based_answer || []), value?.value],
						};
					} else {
						currAns = {
							...currAns,
							choice_based_answer: (currAns?.choice_based_answer || [])?.filter(
								(i) => i !== value?.value
							),
						};
					}
					break;
				case CUSTOM_FIELD_TYPE.DATE:
					currAns = { ...currAns, date_answer: value };
					break;
				default:
					break;
			}
			return { ...p, [+id]: currAns };
		});
	};

	// API to get provinces and cities
	const getLocationListing = async (type = null, loc_name = null) => {
		let queryP;
		if (type === "city") {
			queryP = {
				province_name: loc_name,
			};
		} else {
			queryP = { is_province: true };
		}
		const res = await makeRequest({
			...generateRequestOptions("locationDetails", {
				queryParams: queryP,
			}),
		});
		if (res?.code === 200) {
			let processed = res.data?.map((i) => ({
				id: i,
				name: i,
			}));
			if (type === "city") {
				setCityOpt(processed);
			} else {
				setProvinceOpt(processed);
			}
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	// API to validate, save and going to the next step
	const validateAndSave = async () => {
		setIsSaving(true);

		if (!registData?.id || !admissionId) {
			showNotice(Variable.CANT_GET_DATA[ENV_NAME], "error");
			return;
		}

		// Step 1 Validating Mandatory Fields
		if (
			typeof registDataTemp?.is_alumni !== "boolean" ||
			(registDataTemp?.is_alumni ? !registDataTemp?.alumni : !registDataTemp?.non_alumni) ||
			!registDataTemp?.first_name ||
			!registDataTemp?.last_name ||
			(hiddenFields?.birth_place_date && (!registDataTemp?.birth_place || !registDataTemp?.birth_date)) ||
			!registDataTemp?.gender ||
			!registDataTemp?.religion ||
			(hiddenFields?.home_address &&
				(!registDataTemp?.province || !registDataTemp?.city || !registDataTemp?.address)) ||
			!registDataTemp?.nisn
		) {
			setFieldsError(true);
			setIsSaving(false);
			showNotice(Variable.FILL_ALL_REQUIRED_LABEL[ENV_NAME], "error");
			return;
		}

		// Step 2 Validating Custom Fields
		let customFieldError = false;
		customFields?.forEach((i) => {
			let ans = customFieldsAnswerTemp?.[i?.id];
			if (i?.is_mandatory) {
				let hasAns = false;
				switch (i?.field_type) {
					case CUSTOM_FIELD_TYPE.TEXT:
						hasAns = Boolean(ans?.text_answer ?? false);
						break;
					case CUSTOM_FIELD_TYPE.NUMBER:
						hasAns = Boolean(ans?.number_answer ?? false);
						break;
					case CUSTOM_FIELD_TYPE.DATE:
						hasAns = Boolean(ans?.date_answer ?? false);
						break;
					case CUSTOM_FIELD_TYPE.CHECKBOXES:
					case CUSTOM_FIELD_TYPE.MULTIPLECHOICE:
						hasAns = ans?.choice_based_answer?.length > 0;
						break;
					default:
						break;
				}

				if (!hasAns) {
					setFieldsError(true);
					showNotice(Variable.FILL_ALL_REQUIRED_LABEL[ENV_NAME], "error");
					customFieldError = true;
					setIsSaving(false);
					return;
				}
				if (hasAns && (!ans?.custom_field || !ans?.batch_application || !ans?.school_admission)) {
					setFieldsError(true);
					showNotice(Variable.CANT_GET_DATA[ENV_NAME], "error");
					customFieldError = true;
					setIsSaving(false);
					return;
				}
			}
		});
		if (customFieldError) return;

		// Mandatory saving
		let hasError = false;
		const resMandatory = await makeRequest({
			...generateRequestOptions("saveRegistData", { urlParams: registData?.id }),
			body: registDataTemp,
			json: true,
		});
		if (resMandatory?.code === 200) {
			// Do nothing
		} else {
			showNotice(resMandatory?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
			hasError = true;
			setIsSaving(false);
			return;
		}

		// Custom field saving
		// Step 1 determine which one is new which one is existing
		let newData = Object.values(customFieldsAnswerTemp || {})?.filter((i) => !i?.id);
		let existingData = Object.values(customFieldsAnswerTemp || {})?.filter((i) => i?.id);

		// Create new results (from existing)
		if (newData?.length > 0) {
			const resCustFieldNew = await makeRequest({
				...generateRequestOptions("createCustomFormAnswer"),
				body: newData,
				json: true,
			});
			if (resCustFieldNew?.code === 200) {
				// Do nothing
			} else {
				showNotice(resCustFieldNew?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
				hasError = true;
				setIsSaving(false);
				return;
			}
		}

		// Edit bulk new results (from existing)
		if (existingData?.length > 0) {
			const resCustFieldExisting = await makeRequest({
				...generateRequestOptions("editCustomFormAnswer"),
				body: existingData,
				json: true,
			});
			if (resCustFieldExisting?.code === 200) {
				// Do nothing
			} else {
				showNotice(resCustFieldExisting?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
				hasError = true;
				setIsSaving(false);
				return;
			}
		}

		if (!hasError) {
			setIsEditing(false);
			await getCustomFields();
			await getFormData(admissionId);
		}
		setIsSaving(false);
		if (!hasError) {
			// Sadly we need to use timeout for this one
			setTimeout(() => {
				props.history.push(RoutePath.APPLY_BATCH + `/${admissionId}/${batchId}/${applyId}` + TAB_ENUM.PARENT);
			}, 200);
		}
	};

	return (
		<div className="h-100 d-flex flex-column general-form regist-form">
			<div id="form-scroll-student" className="flex-grow-1 mh-0 overflow-auto">
				<div className="regist-form-container">
					<h5 className="regist-form-header">{Variable.ALUMNI_NON_ALUMNI[ENV_NAME]}</h5>
					<div className="regist-form-row">
						<div className="form-group">
							<div className="da-custom-radio mr-4">
								<input
									className="custom-control-input"
									type="radio"
									// Make sure you use different name for different radio button groups
									name="alumni-type-opt"
									id="alumni"
									value="alumni"
									checked={registDataTemp?.is_alumni || false}
									onChange={() => {
										setRegistDataTemp((p) => ({ ...p, is_alumni: true, non_alumni: "" }));
									}}
								/>
								<label className="cursorPointer custom-control-label" htmlFor="alumni"></label>
								<label className="ml-2 cursorPointer font-weight-medium" htmlFor="alumni">
									{Variable.ALUMNI_SCHOOL_NAMES[ENV_NAME]}
								</label>
							</div>
							{registDataTemp?.is_alumni && (
								<Select
									className={`select-filter form-control mr-5 ${
										fieldsError && registDataTemp?.is_alumni && !registDataTemp?.alumni
											? "red-border"
											: ""
									}`}
									items={admissionData?.alumini_school_names?.map((i) => ({ id: i, name: i })) || []}
									value={{ id: registDataTemp?.alumni || "", name: registDataTemp?.alumni || "" }}
									onChangeItem={(e) =>
										onChangeHandler({ name: "alumni", value: e }, ONCHANGE_TYPE.OPTION)
									}
									disabled={isSaving}
								/>
							)}
						</div>
						<div className="form-group">
							<div className="da-custom-radio mr-4">
								<input
									className="custom-control-input"
									type="radio"
									// Make sure you use different name for different radio button groups
									name="alumni-type-opt"
									id="non-alumni"
									value="non-alumni"
									checked={!registDataTemp?.is_alumni || false}
									onChange={() => {
										setRegistDataTemp((p) => ({ ...p, is_alumni: false, alumni: "" }));
									}}
								/>
								<label className="cursorPointer custom-control-label" htmlFor="non-alumni"></label>
								<label className="ml-2 cursorPointer font-weight-medium" htmlFor="non-alumni">
									{Variable.NON_ALUMNI_SCHOOL_NAMES[ENV_NAME]}
								</label>
							</div>
							{!registDataTemp?.is_alumni && (
								<input
									className={`form-control ${
										fieldsError && !registDataTemp?.is_alumni && !registDataTemp?.non_alumni
											? "red-border"
											: ""
									}`}
									type="text"
									name="non_alumni"
									value={registDataTemp?.non_alumni || ""}
									placeholder={Variable.ENTER[ENV_NAME]}
									onChange={onChangeHandler}
									disabled={isSaving}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="regist-form-container">
					<h5 className="regist-form-header">{Variable.APPLICANT_NAME[ENV_NAME]}</h5>
					<div className="regist-form-row">
						<div className="form-group">
							<label>{Variable.FIRST_NAME[ENV_NAME]}</label>
							<input
								className={`form-control ${
									fieldsError && !registDataTemp?.first_name ? "red-border" : ""
								}`}
								type="text"
								name="first_name"
								value={registDataTemp?.first_name || ""}
								placeholder={Variable.ENTER[ENV_NAME]}
								onChange={onChangeHandler}
								disabled={isSaving}
							/>
						</div>
						<div className="form-group">
							<label>{Variable.LAST_NAME[ENV_NAME]}</label>
							<input
								className={`form-control ${
									fieldsError && !registDataTemp?.last_name ? "red-border" : ""
								}`}
								type="text"
								name="last_name"
								value={registDataTemp?.last_name || ""}
								placeholder={Variable.ENTER[ENV_NAME]}
								onChange={onChangeHandler}
								disabled={isSaving}
							/>
						</div>
					</div>
				</div>
				{hiddenFields?.birth_place_date && (
					<div className="regist-form-container">
						<h5 className="regist-form-header">{Variable.BIRTH_PLACE_DATE[ENV_NAME]}</h5>
						<div className="regist-form-row">
							<div className="form-group">
								<label>{Variable.BIRTH_PLACE[ENV_NAME]}</label>
								<input
									className={`form-control ${
										fieldsError && hiddenFields?.birth_place_date && !registDataTemp?.birth_place
											? "red-border"
											: ""
									}`}
									type="text"
									name="birth_place"
									value={registDataTemp?.birth_place || ""}
									placeholder={Variable.ENTER[ENV_NAME]}
									onChange={onChangeHandler}
								/>
							</div>
							<div className="form-group">
								<label>{Variable.BIRTH_DATE[ENV_NAME]}</label>
								<Datetime
									locale={ENV_NAME === "eng" ? "en" : "id"}
									onChange={(e) => {
										if (moment(e).isValid()) {
											onChangeHandler(
												{
													name: "birth_date",
													value: moment(e).format(SUBMIT_TIME_FORMAT),
												},
												ONCHANGE_TYPE.DATE
											);
										}
									}}
									isValidDate={(current) => moment(current).isSameOrBefore(moment(), "day")}
									value={moment(registDataTemp?.birth_date || "")}
									timeFormat={false}
									dateFormat={TIME_FORMAT}
									inputProps={{
										placeholder: Variable.ENTER[ENV_NAME],
										className: `form-control timepickerlike ${
											fieldsError &&
											(!moment(registDataTemp?.birth_date).isValid() ||
												!registDataTemp?.birth_date)
												? "red-border"
												: ""
										}`,
										name: "birth_date",
										disabled: isSaving,
									}}
									className="position-relative"
								/>
							</div>
						</div>
					</div>
				)}
				<div className="regist-form-container">
					<h5 className="regist-form-header">{Variable.GENDER_LABEL[ENV_NAME]}</h5>
					<div className="d-flex align-items-center flex-wrap">
						{GENDER_OPT?.map((gender) => (
							<div className="da-custom-radio mr-4" key={gender.id}>
								<input
									className={`custom-control-input ${
										fieldsError && !registDataTemp?.gender ? "red-border" : ""
									}`}
									type="radio"
									// Make sure you use different name for different radio button groups
									name="gender-opt"
									id={"gender-" + gender.id}
									value={"gender-" + gender.id}
									checked={registDataTemp?.gender === gender.id || false}
									onChange={(e) =>
										onChangeHandler({ name: "gender", value: gender.id }, ONCHANGE_TYPE.RADIO)
									}
									disabled={isSaving}
								/>
								<label
									className="cursorPointer custom-control-label"
									htmlFor={"gender-" + gender.id}
								></label>
								<label
									className="ml-2 cursorPointer font-weight-medium"
									htmlFor={"gender-" + gender.id}
								>
									{gender.name[ENV_NAME]}
								</label>
							</div>
						))}
					</div>
				</div>
				<div className="regist-form-container">
					<h5 className="regist-form-header">{Variable.RELIGION_LABEL[ENV_NAME]}</h5>
					<div className="d-flex align-items-center flex-wrap">
						{RELIGION_OPT?.map((religion) => (
							<div className="da-custom-radio mr-4" key={religion.id}>
								<input
									className={`custom-control-input ${
										fieldsError && !registDataTemp?.religion ? "red-border" : ""
									}`}
									type="radio"
									// Make sure you use different name for different radio button groups
									name="religion-opt"
									id={"religion-" + religion.id}
									value={"religion-" + religion.id}
									checked={registDataTemp?.religion === religion.id || false}
									onChange={(e) =>
										onChangeHandler({ name: "religion", value: religion.id }, ONCHANGE_TYPE.RADIO)
									}
									disabled={isSaving}
								/>
								<label
									className="cursorPointer custom-control-label"
									htmlFor={"religion-" + religion.id}
								></label>
								<label
									className="ml-2 cursorPointer font-weight-medium"
									htmlFor={"religion-" + religion.id}
								>
									{religion.name}
								</label>
							</div>
						))}
					</div>
				</div>
				{hiddenFields?.home_address && (
					<div className="regist-form-container">
						<h5 className="regist-form-header">{Variable.HOME_ADDRESS[ENV_NAME]}</h5>
						<div className="regist-form-row">
							<div className="regist-form-row flex-column w-100">
								<div className="form-group">
									<label>{Variable.PROVINCE[ENV_NAME]}</label>
									<Select
										name="province"
										className={`select-filter form-control ${
											fieldsError && hiddenFields?.home_address && !registDataTemp?.province
												? "red-border"
												: ""
										}`}
										value={{
											id: registDataTemp?.province || "",
											name: registDataTemp?.province || "",
										}}
										onChangeItem={(e) => {
											onChangeHandler({ name: "province", value: e }, ONCHANGE_TYPE.OPTION);
											setCityOpt([]);
										}}
										items={provinceOpt || []}
										disabled={provinceOpt?.length === 0 || isSaving}
									/>
								</div>
								<div className="form-group">
									<label>{Variable.CITY_REGENCY[ENV_NAME]}</label>
									<Select
										name="city"
										className={`select-filter form-control ${
											fieldsError && hiddenFields?.home_address && !registDataTemp.city
												? "red-border"
												: ""
										}`}
										value={{ id: registDataTemp?.city || "", name: registDataTemp?.city || "" }}
										onChangeItem={(e) =>
											onChangeHandler({ name: "city", value: e }, ONCHANGE_TYPE.OPTION)
										}
										items={cityOpt || []}
										disabled={!registDataTemp?.province || cityOpt?.length === 0 || isSaving}
									/>
								</div>
							</div>
							<div className="form-group d-flex flex-column">
								<label>{Variable.FULL_ADDRESS[ENV_NAME]}</label>
								<textarea
									name="address"
									className={`form-control flex-grow-1 ${
										fieldsError && hiddenFields?.home_address && !registDataTemp.address
											? "red-border"
											: ""
									}`}
									placeholder={Variable.ENTER[ENV_NAME]}
									value={registDataTemp?.address || ""}
									onChange={onChangeHandler}
									disabled={isSaving}
								/>
							</div>
						</div>
					</div>
				)}
				<div className="regist-form-container">
					<h5 className="regist-form-header">{Variable.NISN[ENV_NAME]}</h5>
					<div className="regist-form-row">
						<div className="form-group">
							<label>{Variable.NISN[ENV_NAME]}</label>
							<input
								className={`form-control ${fieldsError && !registDataTemp.nisn ? "red-border" : ""}`}
								type="text"
								name="nisn"
								value={registDataTemp?.nisn || ""}
								placeholder={Variable.ENTER[ENV_NAME]}
								onChange={onChangeHandler}
							/>
						</div>
						<div className="form-group"></div>
					</div>
				</div>
				{customFields?.map((i, key) => (
					<FormCustomField
						key={"custom-field-" + key}
						idx={key}
						id={i?.id}
						title={i?.field_title}
						type={i?.field_type}
						instructions={i?.field_question}
						mandatory={i?.is_mandatory}
						options={i?.field_choices}
						isError={fieldsError}
						answer={customFieldsAnswerTemp?.[+i?.id]}
						onAnswerChange={onCustomFieldAnswerChange}
					/>
				))}
			</div>
			<div className="d-flex align-items-center mt-3">
				<button
					className="btn btn-cancel ml-auto font-weight-semibold py-3 px-4"
					onClick={() => props.history.push(RoutePath.DASHBOARD)}
				>
					{Variable.CANCEL[ENV_NAME]}
				</button>
				<button
					className="btn btn-primary ml-5 font-weight-semibold py-3 px-4"
					onClick={() => validateAndSave()}
					disabled={isSaving}
				>
					{Variable.NEXT_LABEL[ENV_NAME]}
				</button>
			</div>
			<UnsavedChanges when={isEditing || isSaving} message={Variable.UNSAVED_CHANGES[ENV_NAME]} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
	};
};
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(StudentInfoTab));
