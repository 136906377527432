import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Variable from "../../shared/utils/variables";
import * as RoutePath from "../../shared/utils/routeLink";
import { Nav, Tab } from "react-bootstrap";
import StudentInfoTab from "./FormTabs/StudentInfoTab";
import ParentInfoTab from "./FormTabs/ParentInfoTab";
import UploadTab from "./FormTabs/UploadTab";
import "./RegistrationComponent.scss";
import Image from "../../shared/component/UI/Image";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { useContext } from "react";
import { NoticeMessageContext } from "../../App";

const TAB_NAME = {
	STUDENT: "#student",
	PARENT: "#parent",
	DOCUMENT: "#document",
};

const TAB_OPT = [
	{ id: TAB_NAME.STUDENT, name: Variable.STUDENT_INFORMATION },
	{ id: TAB_NAME.PARENT, name: Variable.PARENT_INFORMATION },
	{ id: TAB_NAME.DOCUMENT, name: Variable.DOCUMENTS_UPLOAD },
];

export const CUSTOM_FIELD_TYPE = {
	TEXT: "text",
	NUMBER: "number",
	MULTIPLECHOICE: "multiple_choice",
	CHECKBOXES: "checkboxes",
	DATE: "date_picker",
	FILE: "file",
};

export const RegistContext = createContext();

const RegistrationComponent = ({ ENV_NAME, schoolData, isAuthenticated, ...props }) => {
	const { showNotice } = useContext(NoticeMessageContext);
	const [admissionId, setAdmissionId] = useState(null);
	const [batchId, setBatchId] = useState(null);
	const [applyId, setApplyId] = useState(null);
	const [admissionData, setAdmissionData] = useState(null);
	const [batchData, setBatchData] = useState(null);
	const [selectedTab, setSelectedTab] = useState(null);
	const [registData, setRegistData] = useState(null);
	const [customFieldAnswer, setCustomFieldsAnswer] = useState(null);

	useEffect(() => {
		if (props.location?.pathname?.includes(RoutePath.APPLY_BATCH) && isAuthenticated) {
			let splitUrl = (props.location?.pathname || "").split(RoutePath.APPLY_BATCH + "/")?.[1]?.split("/");
			let admissionIdTemp = parseInt(splitUrl?.[0]);
			let batchIdTemp = parseInt(splitUrl?.[1]);
			let applyIdTemp = parseInt(splitUrl?.[2]);
			if (admissionIdTemp && batchIdTemp && applyIdTemp) {
				setAdmissionId(admissionIdTemp);
				setBatchId(batchIdTemp);
				setApplyId(applyIdTemp);
			} else {
				props.history.push(RoutePath.DASHBOARD);
				showNotice(Variable.CANT_GET_DATA[ENV_NAME], "error");
			}
		}
	}, [props.location?.pathname]); // eslint-disable-line

	// Todo: Get custom field data (might want to do it in the individual tab)
	useEffect(() => {
		if (admissionId) fetchAdmissionData(admissionId);
	}, [admissionId]); // eslint-disable-line

	useEffect(() => {
		if (batchId && admissionId) {
			fetchBatchData(batchId, admissionId);
			getAdmissionStatus(batchId);
		}
	}, [batchId, admissionId]); // eslint-disable-line

	useEffect(() => {
		if (applyId && admissionId) {
			fetchRegistData(applyId, admissionId);
			getCustomFieldsAnswer(applyId);
		}
	}, [applyId, admissionId]); // eslint-disable-line

	useEffect(() => {
		if (props.location?.pathname?.includes(RoutePath.APPLY_BATCH)) {
			setSelectedTab(
				Object.values(TAB_NAME)?.includes(props.location?.hash?.split("?")[0])
					? props.location?.hash?.split("?")[0]
					: TAB_NAME.STUDENT
			);
		}
	}, [props.location?.pathname, props.location?.hash]);

	const getFormData = async () => {
		fetchRegistData(applyId, admissionId);
		getCustomFieldsAnswer(applyId);
	};

	const fetchAdmissionData = async (id) => {
		const res = await makeRequest({
			...generateRequestOptions("getAdmission", {
				urlParams: id + "/",
			}),
		});
		if (res?.code === 200) {
			setAdmissionData(res?.data);
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	const fetchBatchData = async (baId, subId) => {
		// const res = await makeRequest({
		// 	...generateRequestOptions("getIndividualBatchData", {
		// 		urlParams: id + "/",
		// 	}),
		// });
		// if (res?.code === 200) {
		// 	setBatchData(res?.data);
		// } else {
		// 	showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		// }
		const res = await makeRequest({
			...generateRequestOptions("getBatch", {
				queryParams: { school_admission: subId },
			}),
		});
		if (res?.code === 200) {
			setBatchData(res?.data?.find((i) => +i?.id === +baId));
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	const fetchRegistData = async (apId, subId) => {
		setRegistData(null);
		const res = await makeRequest({
			...generateRequestOptions("getRegistData", {
				queryParams: { batch_application: apId },
			}),
		});
		if (res?.code === 200) {
			if (res?.data?.length) {
				setRegistData(res?.data?.[0]);
			} else {
				// There are no data yet, we need to create a new form submission data
				const res_create = await makeRequest({
					...generateRequestOptions("createRegistForm"),
					data: { batch_application: apId, school_admission: subId },
				});
				if (res_create?.code === 200) {
					setRegistData(res_create?.data);
				} else {
					showNotice(res_create?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
				}
			}
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	const getCustomFieldsAnswer = async (apId) => {
		const res = await makeRequest({
			...generateRequestOptions("getCustomFormAnswer", {
				queryParams: { batch_application: apId },
			}),
		});
		if (res?.code === 200) {
			setCustomFieldsAnswer(res?.data);
		} else {
			showNotice(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME], "error");
		}
	};

	const getAdmissionStatus = async (baId) => {
		const res = await makeRequest({
			...generateRequestOptions("applicantListing", {
				queryParams: {
					batch: baId,
				},
			}),
		});
		if (res?.code === 200 && res?.data?.[0]?.submission_status) {
			showNotice(Variable.FORM_ALREADY_SUBMITTED[ENV_NAME], "error");
			props.history.push(RoutePath.DASHBOARD);
		}
	};

	return (
		<div className="widget regist-component">
			<div className="container-fluid d-flex flex-column p-0 h-web-100">
				<div className="container-fluid nopad">
					<div className="row m-0 hundredPheight">
						<div className="col-sm-12 col-md-12 py-2 nopad border-heading d-flex justify-content-between align-items-center">
							<h5 className="header text-label-dark-gray mb-0">
								{`${batchData?.batch_name || ""} - ${Variable.REGISTRATION_FORM[ENV_NAME]}`}
							</h5>
							<div className="d-flex align-items-center">
								<h5 className="header">{schoolData?.name || ""}</h5>
								<Image className="school-top-logo" src={schoolData?.logo} />
							</div>
						</div>
					</div>
				</div>
				<div className="regist-content">
					<RegistContext.Provider
						value={{
							admissionId,
							batchId,
							applyId,
							registData,
							customFieldAnswer,
							admissionData,
							getFormData,
						}}
					>
						<Tab.Container activeKey={selectedTab}>
							<Nav className="nav-tabs-custom">
								{TAB_OPT?.map((i) => (
									<Nav.Item key={i?.id}>
										<div className={selectedTab !== i?.id ? "h-100 cursorPointer" : "h-100"}>
											<Nav.Link
												href={i?.id}
												className="nav-custom"
												eventKey={i?.id}
												disabled={selectedTab === i?.id}
											>
												{i?.name[ENV_NAME]}
											</Nav.Link>
										</div>
									</Nav.Item>
								))}
							</Nav>
							<Tab.Content className="tab-custom-content">
								<div
									role="tabpanel"
									aria-hidden={selectedTab === TAB_NAME.STUDENT}
									className={`fade h-100 tab-pane ${
										selectedTab === TAB_NAME.STUDENT ? "active show" : ""
									}`}
								>
									<StudentInfoTab isOpened={selectedTab === TAB_NAME.STUDENT} />
								</div>
								<div
									role="tabpanel"
									aria-hidden={selectedTab === TAB_NAME.PARENT}
									className={`fade h-100 tab-pane ${
										selectedTab === TAB_NAME.PARENT ? "active show" : ""
									}`}
								>
									<ParentInfoTab isOpened={selectedTab === TAB_NAME.PARENT} />
								</div>
								<div
									role="tabpanel"
									aria-hidden={selectedTab === TAB_NAME.DOCUMENT}
									className={`fade h-100 tab-pane ${
										selectedTab === TAB_NAME.DOCUMENT ? "active show" : ""
									}`}
								>
									<UploadTab isOpened={selectedTab === TAB_NAME.DOCUMENT} />
								</div>
							</Tab.Content>
						</Tab.Container>
					</RegistContext.Provider>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		schoolData: state.auth.schoolData,
		userDetails: state.auth.userDetails,
		ENV_NAME: state.auth.selectedEnvironment,
		isAuthenticated: state.auth.isAuthenticated,
	};
};

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(RegistrationComponent));
