import React, { useContext, useEffect, useState } from "react";
import makeRequest from "../../../shared/utils/request";
import { generateRequestOptions } from "../../../shared/utils/apiEndPoints";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import * as Variable from "../../../shared/utils/variables";
import GreenCheck from "../../../assets/svg/green-check.svg";
import RedCross from "../../../assets/svg/red-cross.svg";
import { HASH_ENUM, SchoolContext } from "../MainPageComponent";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import Image from "../../../shared/component/UI/Image";

const VerifyUserComponent = ({ ENV_NAME, ...props }) => {
	const location = useLocation();
	const [userData, setUserData] = useState({
		user_id: "",
		timestamp: "",
		signature: "",
	});
	const [disableButton, setDisableButton] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isSuccess, setIsSuccess] = useState(null);
	const { schoolData, redirectHandler } = useContext(SchoolContext);

	useEffect(() => {
		if (location?.hash?.includes(HASH_ENUM.VERIFYUSER)) {
			let ids = location?.hash?.split(HASH_ENUM.VERIFYUSER + "?")[1];
			let idsSplit = ids?.split("&");
			let values = {};
			idsSplit.forEach((value, key) => {
				let a = value.split("=");
				values = {
					...values,
					[a[0]]: a[1],
				};
			});
			setUserData(values);
		}
	}, [location]);

	useEffect(() => {
		if (schoolData?.id && userData?.user_id && userData?.timestamp && userData?.signature) {
			verifyUser();
		}
	}, [schoolData, userData]); // eslint-disable-line

	const verifyUser = async () => {
		if (!schoolData?.id) {
			setErrorMessage(Variable.CANT_GET_SCHOOL_REFRESH[ENV_NAME]);
			setTimeout(() => setErrorMessage(null), 5000);
			return;
		}
		if (Object.values(userData)?.filter((i) => !i)?.length > 0) {
			setIsSuccess(false);
			setErrorMessage(Variable.CANT_GET_DATA_REFRESH[ENV_NAME]);
			return;
		}

		setErrorMessage(null);

		let data = userData;

		setDisableButton(true);
		let res = await makeRequest({
			...generateRequestOptions("verifyUser"),
			body: data,
			json: true,
		});
		if (res.code === 400) {
			setIsSuccess(false);
			setErrorMessage(res.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME]);
		} else {
			setIsSuccess(true);
		}
		setDisableButton(false);
	};

	const goToLogin = () => {
		redirectHandler(HASH_ENUM.LOGIN);
	};

	return (
		<div className="rightSideBox d-flex justify-content-center align-items-center positionRelative">
			{isSuccess === null ? (
				<LoadingComponent notFullScreen />
			) : (
				<div className="noMar formContainer">
					<div className="form-group d-flex flex-column align-items-center text-center">
						<Image className="mb-3" src={isSuccess ? GreenCheck : RedCross} alt="notice icon" />
						{isSuccess ? (
							<>
								<span className="text-label-dark-gray font-weight-medium label-message">
									{Variable.VERIFY_SUCCESS_L1[ENV_NAME]}
								</span>
								<span className="text-label-dark-gray font-weight-medium label-message">
									{Variable.PASSWORD_RESET_SUCCESS_L2[ENV_NAME]}
								</span>
							</>
						) : (
							<>
								<span className="text-label-dark-gray font-weight-medium label-message">
									{Variable.VERIFY_FAILED_L1[ENV_NAME]}
								</span>
								<span className="text-label-dark-gray font-weight-medium label-message">
									{errorMessage}
								</span>
							</>
						)}
					</div>
					<div className="col-12 noPad">
						<button
							type="submit"
							onClick={() => goToLogin()}
							disabled={disableButton}
							className="btn btn-primary btn-primary-login"
						>
							{Variable.PROCEED_TO_LOGIN[ENV_NAME]}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		ENV_NAME: state.auth.selectedEnvironment,
	};
};

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(VerifyUserComponent));
