import React, { useState } from "react";
import "../ProfileComponent.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { classHelper } from "../../../shared/utils/stringUtils";
import Accordion from "react-bootstrap/Accordion";

const ProfileContainer = ({ ...props }) => {
    const [isOpen, setIsOpen] = useState(props.openDefault || false);

    // console.log(active);
    return (
        <div className={classHelper("profile-container", props.className)}>
            {/* Collapsible Container -- Accordion*/}
            {props.collapsible && (
              <Accordion 
                defaultActiveKey={`${props.openDefault ? props.eventKey : ""}`}
                className={classHelper(props.accordionClassName)}
              >
                <Accordion.Toggle 
                    as="div" 
                    eventKey={`${props.eventKey}`}
                    className={classHelper("pc-header cursorPointer", props.headerClassName)}
                    style={{ background: props.headerBackground || "" }}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <>
                        {props.header} 
                        <div 
                            className={`arrow ${isOpen ? "open" : ""}`}
                        ></div>
                    </>
                </Accordion.Toggle>
                <Accordion.Collapse 
                    eventKey={`${props.eventKey}`}
                    className={`pc-content ${props.contentClassName}`}
                >
                   
                    <>{props.children}</>
                </Accordion.Collapse>
              </Accordion>  
            )}

            {/* Non Collapsible Container */}
            {!props.collapsible && (
              <>
                <div 
                    className={classHelper("pc-header", props.headerClassName)}
                    style={{ background: props.headerBackground || "" }}
                >
                    {props.header}
                </div>
                <div className={classHelper("pc-content", props.contentClassName)}>
                    {props.children}
                </div>
              </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
	return {
		...state,
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
	};
};
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(ProfileContainer));