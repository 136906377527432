import { React, isValidElement } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Variable from "../../shared/utils/variables";
import NotFoundIcon from "../../assets/icon/not-found-error.svg";
import { classHelper } from "../../shared/utils/stringUtils";
import "./NoContentComponent.scss";
import Image from "../../shared/component/UI/Image";

const NoContentComponent = ({ title, description, selectedEnvironment, onModal, ...props }) => {
	const ENV_NAME = selectedEnvironment ? selectedEnvironment : "bhs";
	if (onModal === "true") {
		return (
			<div className={classHelper("no-content-component", props.className)}>
				<Image src={NotFoundIcon} className="no-content-component__onModal" alt="No Content Found" />
				<p className="no-content-component__title">{title ?? Variable.NO_CONTENT_DEFAULT_LABEL[ENV_NAME]}</p>
			</div>
		);
	} else {
		return (
			<div className={classHelper("no-content-component", props.className)}>
				<Image src={NotFoundIcon} className="no-content-component__icon" alt="No Content Found" />
				{isValidElement(title) ? (
					title
				) : (
					<h5 className="no-content-component__title">
						{title ?? Variable.NO_CONTENT_DEFAULT_LABEL[ENV_NAME]}
					</h5>
				)}
				{isValidElement(description) ? (
					description
				) : (
					<p className="no-content-component__description">
						{description ?? Variable.NO_CONTENT_DEFAULT_DESCRIPTION[ENV_NAME]}
					</p>
				)}
			</div>
		);
	}
};

const mapStateToProps = (state) => ({
	selectedEnvironment: state.auth.selectedEnvironment,
});
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(NoContentComponent));
