import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import Image from "../../shared/component/UI/Image";
import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables";
import PortalSekolahLogo from "../../assets/logo/logo-ps-color.svg";
import LeftSide from "./LeftSide/LeftSide";
import { toast } from "react-toastify";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import LoginComponent from "./Login/LoginComponent";
import ForgotPasswordComponent from "./ForgotPassword/ForgotPasswordComponent";
import SignUpComponent from "./SignUp/SignUpComponent";
import ResetPasswordComponent from "./ResetPassword/ResetPasswordComponent";

import "./MainPageComponent.scss";
import "./floating-labels.scss";
import VerifyUserComponent from "./VerifyUser/VerifyUserComponent";

export const SchoolContext = createContext();

export const HASH_ENUM = {
	LOGIN: "#login",
	SIGNUP: "#signup",
	FORGOTPASSWORD: "#forgot-password",
	RESETPASSWORD: "#reset-password",
	VERIFYRESETPASSWORD: "#verify-reset-password",
	VERIFYUSER: "#verify-user",
};

const getActiveComp = (hash) => {
	if (hash?.includes(HASH_ENUM.VERIFYRESETPASSWORD)) {
		hash = HASH_ENUM.VERIFYRESETPASSWORD;
	}
	if (hash?.includes(HASH_ENUM.VERIFYUSER)) {
		hash = HASH_ENUM.VERIFYUSER;
	}

	switch (hash) {
		case HASH_ENUM.LOGIN:
			return <LoginComponent />;
		case HASH_ENUM.SIGNUP:
			return <SignUpComponent />;
		case HASH_ENUM.FORGOTPASSWORD:
			return <ForgotPasswordComponent />;
		case HASH_ENUM.VERIFYRESETPASSWORD:
			return <ResetPasswordComponent />;
		case HASH_ENUM.VERIFYUSER:
			return <VerifyUserComponent />;
		default:
			return <LoginComponent />;
	}
};

/*
  This page contains the login, signup, forgot password for a specific school
*/
const MainPageComponent = ({ ENV_NAME, ...props }) => {
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [activeComp, setActiveComp] = useState(null);
	const [schoolData, setSchoolData] = useState(null);

	// Get the id of a schol
	// If they don't have any id, redirect to the home page
	useEffect(() => {
		if (props.isAuthenticated) {
			props.history.push(RoutePath.DASHBOARD);
		}

		let url = location?.pathname;

		// So, BE can only gives us a new pathname for verifying user or reset password
		// ex: https://portalsiswa.id/verify-user/user_id=xxx&timestamp=xxx&signature=xxx
		// So this code will convert those pathname into a hash
		// ex: https://portalsiswa.id#verify-user?user_id=xxx&timestamp=xxx&signature=xxx
		if (url?.includes(RoutePath.VERIFYRESETPASSWORD) || url?.includes(RoutePath.VERIFYUSER)) {
			let splitURL = url.split("/");
			let urlSchool = splitURL?.[1];
			if (url?.includes(RoutePath.VERIFYRESETPASSWORD)) {
				props.history.push(
					"/" + urlSchool + HASH_ENUM.VERIFYRESETPASSWORD + "?" + splitURL?.[splitURL?.length - 1]
				);
			} else if (url?.includes(RoutePath.VERIFYUSER)) {
				props.history.push("/" + urlSchool + HASH_ENUM.VERIFYUSER + "?" + splitURL?.[splitURL?.length - 1]);
			}
			return;
		}

		if (url && url?.split("-")?.length > 1 && !schoolData) {
			let splitURL = url?.split("-");
			let ids = isNaN(splitURL?.[splitURL?.length - 1]) ? null : Number(splitURL?.[splitURL?.length - 1]);
			if (!ids) props.history.push(RoutePath.ROOT);
			else getSchoolInfo(ids, splitURL);
		}
	}, [props.isAuthenticated, location, schoolData]); // eslint-disable-line

	// Get the id of a career
	// If they don't have any career, redirect to the home page
	useEffect(() => {
		let comp = getActiveComp(location?.hash);
		if (!comp) props.history.push(RoutePath.ROOT);
		else setActiveComp(comp);
	}, [location]); // eslint-disable-line

	const getSchoolInfo = async (ids, urls = []) => {
		setIsLoading(true);
		const res = await makeRequest(generateRequestOptions("getSchoolData", { urlParams: ids + "/" }));
		if (res?.code === 200) {
			// Check the validity of the link
			let realSchoolName = encodeURIComponent(
				res?.data?.name?.replace(/\s+/g, "-").toLowerCase().substring(0, 20)
			);
			let urlSchoolName = urls?.slice(0, -1)?.join("-")?.replace("/", "");

			if (realSchoolName !== urlSchoolName) {
				props.history.push(RoutePath.ROOT);
				return;
			}

			setSchoolData(res.data);
		} else {
			toast.error(res?.message || Variable.SOMETHING_WENT_WRONG[ENV_NAME]);
		}
		setIsLoading(false);
	};

	const redirectHandler = (route) => {
		props.history.push(location?.pathname + route);
	};

	return (
		<div className="main-page-component">
			<LeftSide />
			<div className="main-page-right-side">
				{isLoading ? (
					<LoadingComponent />
				) : (
					<SchoolContext.Provider value={{ schoolData, redirectHandler }}>
						<div className="d-flex align-items-center flex-column mt-auto">
							<Image className="school-logo" src={schoolData?.logo || ""} />
							<span className="school-name d-block">{schoolData?.name || ""}</span>
						</div>
						{activeComp}
						<div className="d-flex align-items-start mb-auto">
							<span className="bottom-subtitle mr-2">{Variable.POWERED_BY[ENV_NAME]}</span>
							<Image className="main-page-ps-logo" src={PortalSekolahLogo || ""} />
						</div>
					</SchoolContext.Provider>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment,
	isAuthenticated: state.auth.isAuthenticated,
});
const mapStateToDispatch = (dispatch) => {
	return {};
};
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(MainPageComponent));
