import * as actionTypes from "../actions/actionTypes";
// import { REHYDRATE } from 'redux-persist/lib/constants';
const initialState = {
  isAuthenticated: false,
  isUserOnline: true, // change this to false before pushing
  userDetails: null,
  selectedEnvironment: "bhs",
  userType: "",
  schoolData: {},
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOGOUT: {
      return initialState;
    }
    case actionTypes.UPDATE_LANGUAGE: {
      if (action.payload)
        return {
          ...state,
          selectedEnvironment: action.payload,
        };
      return state;
    }
    case actionTypes.UPDATE_USER_STATUS_DATA: {
      return {
        ...state,
        isUserOnline: action.payload,
      };
    }
    case actionTypes.SET_STORE_DATA: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        userDetails: action.payload.userDetails,
      };
    }
    case actionTypes.SET_USER: {
      return {
        ...state,
        isAuthenticated: true,
        userDetails: action.payload,
      };
    }
    case actionTypes.SET_SCHOOL_DATA: {
      return {
        ...state,
        schoolData: action.payload,
      };
    }
    case actionTypes.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
