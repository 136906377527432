import defaultImage from "../../../assets/image/defaultImage.png";
const Image = ({ className, src, ...props }) => {
	return (
		<img
			src={src ? src : defaultImage}
			alt={props.alt ? props.alt : "Portal Sekolah"}
			className={className}
			{...props}
		/>
	);
};
export default Image;
