const qs = require("qs");
const getURL = (protocol = "https") => {
	let url = window.location.href.split("/")[2];
	let splitUrl = url.split(".");
	if (splitUrl[1] === "portalsekolah") {
		return `${protocol}://backend.portalsekolah.co.id`;
	} else if (splitUrl[1] === "portalmurid") {
		return `${protocol}://backend.portalmurid.com`;
	} else {
		return `${protocol}://backend.portalsiswa.id`;
	}
};

export const baseURL = (() => {
	return getURL();
})();

export const socketBaseURL = (() => {
	return getURL("wss");
})();

export const homeUrl = (() => {
	let url = window.location.href.split("/")[2];
	let splitUrl = url.split(".");
	if (splitUrl[1] === "portalsekolah") {
		return `portalsekolah.co.id`;
	} else if (splitUrl[1] === "portalmurid") {
		return `portalmurid.com`;
	} else if (splitUrl[1] === "portalsiswa") {
		return `portalsiswa.id`;
	} else {
		return `localhost`;
	}
})();

export const mediaURL =
	homeUrl === "localhost" || homeUrl === "portalsiswa.id"
		? "https://dian-media-staging-jakarta.s3.ap-southeast-3.amazonaws.com/media"
		: homeUrl === "portalmurid.com"
		? "https://dian-media-uat-jakarta.s3.ap-southeast-3.amazonaws.com/media"
		: "https://dian-media-production-jakarta.s3.ap-southeast-3.amazonaws.com/media";
export const mediaPreloadURL =
	homeUrl === "localhost" || homeUrl === "portalsiswa.id"
		? "https://dian-media-preloaded-jakarta.s3.ap-southeast-3.amazonaws.com"
		: homeUrl === "portalmurid.com"
		? "https://dian-media-preloaded-jakarta.s3.ap-southeast-3.amazonaws.com"
		: "https://dian-media-preloaded-jakarta.s3.ap-southeast-3.amazonaws.com";

const EPStore = {
	getNewAccessToken: { url: "api/token/refresh/", method: "POST" },
	getUserViaToken: { url: "api/auth/loggedinuserDetails/", method: "GET" },
	signUp: { url: "api/auth/register/", method: "POST" },
	logIn: { url: "api/auth/login/", method: "POST" },
	sendResetLink: { url: "api/auth/send-reset-password-link/", method: "POST" },
	resetPassword: { url: "api/auth/reset-password/", method: "POST" },
	logout: { url: "api/auth/logout/", method: "POST" },
	changePassword: { url: "api/auth/change-password/", method: "POST" },
	adminChangePassword: {
		url: "api/auth/change-password-by-admin/",
		method: "POST",
	},
	getUserById: { url: "api/auth/studentRetrieveUpdateDelete/", method: "GET" },
	userDetails: { url: "api/auth/userDetails/", method: "GET" },
	getClasses: { url: "api/school_portal/sectionListing/", method: "GET" },
	listGrades: { url: "api/course/listGrades/", method: "GET" },
	uploadFile: { url: "api/quiz/imageUpload/", method: "POST" },

	// arun's code
	gradeList: { url: "api/course/listGrades/", method: "GET" },
	subjectList: { url: "api/course/listSubjects/", method: "GET" },
	// login
	getSchoolData: {
		url: "api/school_portal/retrieveSchoolSlim/",
		method: "GET",
	},
	signUpApplicant: {
		url: "api/auth/applicantSignUp/",
		method: "POST",
	},
	checkUsernameAvailability: {
		url: "api/auth/checkUserNameAvailability/",
		method: "POST",
	},
	verifyUser: {
		url: "api/auth/verify-registration/",
		method: "POST",
	},
	// Applicant registration
	getAdmission: {
		url: "api/admission/admissionRetrieveUpdateDelete/",
		method: "GET",
	},
	getRegistData: {
		url: "api/admission/registrationFormSubmissionListing/",
		method: "GET",
	},
	saveRegistData: {
		url: "api/admission/registrationFormSubmissionUpdate/",
		method: "PATCH",
	},
	getCustomForm: {
		url: "api/admission/admissionFormCustomFieldsListing/",
		method: "GET",
	},
	getCustomFormAnswer: {
		url: "api/admission/admissionFormCustomFieldsAnswersListing/",
		method: "GET",
	},
	createCustomFormAnswer: {
		url: "api/admission/customFieldsAnswerBulkCreate/",
		method: "POST",
	},
	editCustomFormAnswer: {
		url: "api/admission/customFieldsAnswerBulkUpdate/",
		method: "PATCH",
	},
	createFileCustomFormAnswer: {
		url: "api/admission/customFieldFileUploadAnswerCreate/",
		method: "POST",
	},
	editFileCustomFormAnswer: {
		url: "api/admission/customFieldFileUploadAnswerRetrieveUpdate/",
		method: "PATCH",
	},
	getIndividualBatchData: {
		url: "api/admission/batchRetrieveUpdateDelete/",
		method: "GET",
	},
	createRegistForm: {
		url: "api/admission/createRegistrationFormSubmission/",
		method: "POST",
	},
	locationDetails: {
		url: "api/school_portal/locationDetails/",
		method: "GET",
	},
	updateAdmissionBatchApply: {
		url: "api/admission/admissionBatchApplyUpdate/",
		method: "PATCH",
	},
	// School Admission
	listAdmission: {
		url: "api/admission/listAdmission",
		method: "GET",
	},
	getBatch: {
		url: "api/admission/batchList/",
		method: "GET",
	},
	applyBatch: {
		url: "api/admission/applyAdmissionBatch/",
		method: "POST",
	},
	applicantListing: {
		url: "api/admission/getAdmissionBatchApplicationListing/",
		method: "GET",
	},
};

export const generateRequestOptions = (key, options = {}) => {
	if (!key) {
		return null;
	}

	if (options && options.hasOwnProperty("queryParams")) {
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${EPStore[key].url}${
						options.urlParams || ""
				  }?${qs.stringify(options.queryParams)}`
				: `${baseURL}/${EPStore[key].url}${
						options.urlParams || ""
				  }?${qs.stringify(options.queryParams, options.queryOptions)}`,
		};
	}

	if (options && options.hasOwnProperty("urlParams")) {
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${EPStore[key].url}${options.urlParams}`
				: `${baseURL}/${EPStore[key].url}${options.urlParams}`,
		};
	}

	if (options && options.hasOwnProperty("innerUrlParams")) {
		let url = Object.keys(options.innerUrlParams).map((k) => {
			return EPStore[key].url.replace(`{${k}}`, options.innerUrlParams[k]);
		})[Object.keys(options.innerUrlParams).length - 1];
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${url}`
				: `${baseURL}/${url}`,
		};
	}
	return {
		...EPStore[key],
		url: EPStore[key].hasOwnProperty("baseURL")
			? `${EPStore[key].baseURL}/${EPStore[key].url}`
			: `${baseURL}/${EPStore[key].url}`,
	};
};
export const generateURL = (key) => {
	return `${homeUrl}/${EPStore[key].url}`;
};
